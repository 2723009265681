<template>
  <div class="small-menu">
    <ul class="user-menu">
      <li class="menu-item" v-if="isSomething(initData.employee_id)">
        <a
          id="portal-navbar-user-name-link"
          class="menu-item-link"
          :href="toModernUrl('/ansatte/minside/minSide.xhtml')"
          @click="
            $emit('menuClicked', {
              title: 'My page menu',
              value: toModernUrl('/ansatte/minside/minSide.xhtml')
            })
          "
          ><span class="menu-item-icon"><span class="fa fa-user"></span></span
          ><span class="menu-item-text">{{
            initData.loggedinusername
          }}</span></a
        >
      </li>
      <MenuItemListLanguages @click="changeLang" />
      <li class="menu-item">
        <a class="menu-item-link" href="#" @click.prevent="logout"
          ><span class="menu-item-icon"
            ><span class="fa fa-sign-out-alt"></span></span
          ><span class="menu-item-text">{{
            $t('menu__item__log_out')
          }}</span></a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import MenuItemListLanguages from './MenuItemListLanguages.vue'

import router from '@/router'

// WORKAROUND FOR FEATURE: USE_IFRAME_MONOLITH
import { toModernUrl as _toModernUrl } from '@/composable/iframe.js'
import { isSomething } from '@/utilities/conditionalUtilities'

export default {
  name: 'ProfileMenu',
  props: {
    initData: { required: true, type: Object },
    changeLang: { required: true },
    isAdminAccount: { required: true, type: Boolean }
  },
  components: {
    MenuItemListLanguages
  },
  setup(_, { emit }) {
    const legacySmallOrMobile = () => {
      return window.matchMedia('(max-width: 768px)').matches
    }

    const logout = e => {
      emit('menuClicked', {
        title: 'Logout',
        value: null
      })
      router.push({ name: 'Logout' })
    }

    const toModernUrl = url => _toModernUrl(url)

    return {
      legacySmallOrMobile,
      logout,
      isSomething,
      toModernUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.small-menu {
  > ul {
    padding: 0.5rem;
    margin: 0;
    background: white;
    list-style: none !important;
    li {
      width: 100%;
    }
    li:hover {
      background: #f0f4f2;
    }
    a {
      padding: 6px 12px 6px 15px;
      color: #333;
      text-decoration: none;
      display: flex;
      align-items: center;

      &:focus {
        padding: 6px 12px 6px 15px !important;
        margin: 0 !important;
      }
    }
    img {
      margin-right: 0.429em;
    }
    .menu-item-icon {
      font-size: 1.2em;
      text-align: center;
      display: block;
      width: 30px;
      margin-right: 0.25rem;
    }
  }
}
li.menu-item + li.menu-item {
  margin-top: 8px;
}
</style>
