import store from '@/store'

export const i18nSort = (array, itemCallback, options = undefined) =>
  array.sort((a, b) =>
    itemCallback(a).localeCompare(
      itemCallback(b),
      store.getters['authUser/locale'],
      options
    )
  )

export const i18nCompare = (a, b, options = undefined) => {
  return a.localeCompare(b, store.getters['authUser/locale'], options)
}
