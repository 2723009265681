import { reactive } from 'vue'

import avonovaAPI from '@/config/axios'
import i18n from '@/config/i18n'
import store from '@/store'

import { now, tryParseToDateTime } from '@/utilities/dateUtilities'

import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from '../../services/requestHandler'

export const useTimeRecorderApi = () => {
  const state = reactive({
    session: {},
    duration: '',
    types: [],
    lunchTypes: [],
    projects: [],
    request: {
      loaded: false,
      loading: false,
      saving: false,
      errors: []
    }
  })

  function getTimeRecorderSession() {
    return avonovaAPI
      .get('/hr/api/timerecorder/v2')
      .then(response => {
        if (response.status == 204) {
          state.session = {}
          store.commit('setTimeRecorderInSession', false)
        } else if (response.status == 200) {
          state.session = response.data
          store.commit('setTimeRecorderInSession', true)
        }
      })
      .catch(() => {
        state.session = {}
        store.commit('setTimeRecorderInSession', false)
      })
  }

  function finishTimeRecorderSession() {
    return avonovaAPI
      .post('/hr/api/timerecorder/stop/v2', state.session)
      .then(response => {
        const getTime = timestamp => {
          const components = new Date(timestamp).toTimeString().split(':')
          return [components[0], components[1]].join(':')
        }
        const string = i18n.t('timerecorder__message__saved', {
          start: getTime(response.data.fromtime),
          stop: getTime(response.data.totime)
        })
        store.commit('newNotification', {
          text: string,
          color: 'secondary',
          duration: 4000
        })
        state.session = {}
        state.duration = ''
        store.commit('setTimeRecorderInSession', false)
      })
      .catch(error => {
        if (error?.response?.data?.key) {
          store.commit('newNotification', {
            text: i18n.t(error.response.data.key),
            color: 'error',
            duration: 5000
          })
        }
      })
  }

  function startTimeRecorderSession() {
    return avonovaAPI
      .post('/hr/api/timerecorder/start/v2', state.session)
      .then(response => {
        if (response.status == 200 && response.data) {
          const start = new Date(response.data.fromtime)
            .toTimeString()
            .split(':')
          const string = i18n.t('timerecorder__message__started', {
            x: [start[0], start[1]].join(':')
          })
          store.commit('newNotification', {
            text: string,
            color: 'secondary',
            duration: 4000
          })
          state.session = response.data
          store.commit('setTimeRecorderInSession', true)
        }
      })
      .catch(error => {
        if (error?.response?.data?.key) {
          store.commit('newNotification', {
            text: i18n.t(error.response.data.key),
            color: 'error',
            duration: 5000
          })
        }
      })
  }

  function cancelTimeRecorderSession() {
    return avonovaAPI
      .delete('/hr/api/timerecorder')
      .then(() => {
        state.session = {}
        state.duration = ''
        store.commit('setTimeRecorderInSession', false)
      })
      .catch(() => {
        state.session = {}
        state.duration = ''
        store.commit('setTimeRecorderInSession', false)
      })
  }

  function getTimeRecorderTypes() {
    return avonovaAPI
      .get('/hr/api/timerecorder/types')
      .then(response => {
        if (response.status == 200 && response.data) state.types = response.data
        else state.types = []
      })
      .catch(() => {
        state.types = []
      })
  }

  function getTimeRecorderLunchTypes() {
    return avonovaAPI
      .get('/hr/api/timerecorder/lunchTypes')
      .then(response => {
        if (response.status == 200 && response.data)
          state.lunchTypes = response.data.map(duration => ({
            title: `${duration} min.`,
            duration
          }))
        else state.lunchTypes = []
      })
      .catch(() => {
        state.lunchTypes = []
      })
  }

  function getAvailableProjectsForDate() {
    const date = state.session.fromtime
      ? tryParseToDateTime(state.session.fromtime)
      : now()
    return avonovaAPI
      .get('/common/api/project/availableProjectsByDate/' + date.toISODate())
      .then(response => {
        if (response.status == 200 && response.data)
          state.projects = response.data
        else state.projects = []
      })
      .catch(() => {
        state.projects = []
      })
  }

  const getAvailableProjectsFromDate = async date => {
    const url = `/common/api/project/availableprojectsfordate`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      date
    })
    if (result.success) state.projects = result.data
  }

  function getDuration() {
    const toISO = string => new Date(string).toISOString().replace('.000Z', '')

    const params = {
      from: toISO(state.session.fromtime),
      to: toISO(state.session.totime),
      lunch_minutes: state.session.lunch_minutes
    }

    return avonovaAPI
      .post('/hr/api/timerecorder/duration/v2', params)
      .then(response => {
        if (response.status == 200 && response.data)
          state.duration = response.data
      })
  }

  return {
    state,
    getTimeRecorderSession,
    finishTimeRecorderSession,
    startTimeRecorderSession,
    cancelTimeRecorderSession,
    getTimeRecorderTypes,
    getAvailableProjectsForDate,
    getAvailableProjectsFromDate,
    getDuration,
    getTimeRecorderLunchTypes
  }
}
