import { getRouteHrefByName, isLegacy } from '../routeHelper'

const cardFile = [
  {
    permissionName: 'STOFFKARTOTEK',
    parent: 'CARDCONTAINER',
    menuItemText: 'menu__item__dangerous_substances',
    url: 'https://chemicals.avonova.com',
    isLegacy: false
  },
  {
    permissionName: 'ARBEIDSUTSTYR',
    parent: 'CARDCONTAINER',
    menuItemText: 'menu__item__work_equipment',
    url: '/arbeidsutstyr/arbeidsutstyr.xhtml',
    isLegacy: true
  },
  {
    permissionName: 'AVTALER',
    parent: 'CARDCONTAINER',
    menuItemText: 'menu__item__agreements',
    url: '/card-file/agreements',
    isLegacy: false
  },
  {
    permissionName: 'LOVVERK',
    parent: 'CARDCONTAINER',
    menuItemText: 'menu__item__laws_regulations',
    url: '/card-file/laws/',
    isLegacy: false
  },
  {
    permissionName: 'DOKUMENTER',
    parent: 'CARDCONTAINER',
    menuItemText: 'menu__item__documents',
    url: '/card-file/files/',
    isLegacy: false
  },
  {
    permissionName: 'KONTAKTER',
    parent: 'CARDCONTAINER',
    menuItemText: 'menu__item__contacts',
    url: '/card-file/contacts/',
    isLegacy: false
  }
]

export default cardFile
