export function useEmployerAbsenceBreadcrumbs() {
  const absenceBreadcrumbs = {
    parent: [
      {
        text: 'Absence',
        string: 'meta__title__employer_absence',
        href: '/employer/absence',
        disabled: false
      }
    ],
    overview: {
      text: 'Overview',
      string: 'meta__title__employer_absence_overview'
    },

    calendar: {
      text: 'Calendar',
      string: 'meta__title__employer_absence_calendar'
    },

    followup: {
      text: 'Followup',
      string: 'meta__title__employer_absence_followup'
    },

    report: {
      text: 'Report',
      string: 'meta__title__employer_absence_report'
    }
  }

  const vacationBreadcrumbs = {
    parent: [
      {
        text: 'Vacation',
        string: 'meta__title_vacation',
        disabled: false,
        href: '/employer/vacation'
      }
    ],
    overview: {
      text: 'Overview',
      string: 'meta__title_vacation_overview'
    },
    calender: {
      text: 'Calendar',
      string: 'meta__title_vacation_calendar'
    },
    transfer: {
      text: 'Transfer',
      string: 'meta__title_vacation_transfer'
    }
  }
  return {
    absenceBreadcrumbs,
    vacationBreadcrumbs
  }
}
