<template>
  <GeneralAccessPage
    :title="$t('access_page_template__no_access_title')"
    :description="$t('access_page_template__no_access_description_text')"
    icon="n-icon-lock"
  />
</template>
<script>
import GeneralAccessPage from './GeneralAccessPage.vue'

export default {
  name: 'NoAccessPage',
  components: { GeneralAccessPage }
}
</script>
