import env from '@/environment'

import { getToken } from '@/services/authentication/authenticationUtilities'

import { httpMethods, requestHandler } from './requestHandler'

export const useCustomerProductPackageService = () => {
  const BASE_URL = env.VUE_APP_ADP_CUSTOMER_PRODUCT_PACKAGE_API_BASE_URL

  /**
   * Fetches customer product packages from the server.
   *
   * @async
   * @function getCustomerProductPackages
   * @returns {Promise<Array>} A promise that resolves to an object of customer product packages with the following structure:
   * {
   *  id: string,
   *  products: Array<string>
   * }
   * @description This function fetches customer product packages.
   */
  const getCustomerProductPackages = async () => {
    const result = await requestHandler(
      httpMethods.GET,
      `${BASE_URL}/customer-product-package`,
      null,
      {
        headers: {
          'Adp-Customer-Product-Package-Api-Subscription-Key':
            env.VUE_APP_ADP_CUSTOMER_PRODUCT_PACKAGE_API_SUBSCRIPTION_KEY,
          'Authorization': await getToken('Bearer')
        }
      }
    )
    if (!result.success) return null
    return result.data
  }

  return {
    getCustomerProductPackages
  }
}
