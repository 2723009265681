const authenticationRoutes = [
  {
    path: '/app/login',
    name: 'Login',
    component: () =>
      import(
        /* webpackChunkName: "Login" */ `@/views/authentication/Login.vue`
      ),
    meta: { title: 'meta__title__log_in' },
    productionAvailable: true
  },
  {
    path: '/app/logout',
    name: 'Logout',
    component: () =>
      import(
        /* webpackChunkName: "Logout" */ `@/views/authentication/Logout.vue`
      ),
    meta: { title: 'meta__title__log_out' },
    productionAvailable: true
  }
]

export default authenticationRoutes
