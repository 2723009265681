import { hasItems, isNone, isSomething } from './conditionalUtilities'
import { ensureNumber } from './numberUtilities'

export const removeItemAtIndex = (array, id) => {
  if (!ensureNumber(id)) throw Error(`parameter id:${id} is not a number`)
  const index = array.findIndex(item => item.id === id)
  return index != -1 ? array.splice(index, 1) : ''
}

export const toObject = (array, keySelector) =>
  array.reduce((acc, current) => {
    const key = keySelector(current)
    if (isNone(key))
      throw new Error('Keyselector in toObject did not return a valid key')
    acc[key] = current
    return acc
  }, {})

export const replaceItem = (array, predicate, replacee) => {
  const index = array.findIndex(predicate)
  array.splice(index, 1, replacee)

  return index
}

export const insertAt = (array, index, item) => array.splice(index, 0, item)

export const groupBy = (list, predicate) => {
  const map = {}

  list.forEach(item => {
    const key = predicate(item)
    const exists = map[key]
    if (!exists) map[key] = [item]
    else map[key].push(item)
  })

  return map
}

export const orderBy = (items, predicate, direction = 'asc') => {
  if (isNone(predicate)) throw Error('Predicate missing')

  const result = items.sort((a, b) => {
    const first = predicate(a)
    const second = predicate(b)

    if (first < second) return -1
    if (first > second) return 1
    return 0
  })

  return direction === 'asc' ? result : result.reverse()
}

export const addUniquesToArray = (array, items, key = 'id') => {
  items.forEach(item => {
    const index = array.findIndex(e => e[key] === item[key])
    if (index === -1) array.push(item)
  })
}

export const range = (start, count, direction = 'asc') =>
  new Array(count)
    .fill(start)
    .map(() => (direction === 'asc' ? start++ : start--))

export const getElementExistingInBothArrays = (array1, array2) =>
  array1
    .map(a1 => {
      return array2.includes(a1) ? a1 : undefined
    })
    .filter(item => item !== undefined)

export const distinct = (array, predicate) => {
  if (!array) return array

  if (!predicate) return Array.from(new Set(array))

  const map = new Map()

  array.forEach(element => {
    const key = predicate(element)
    if (!map.has(key)) map.set(key, element)
  })

  return Array.from(map.values())
}

export const getLastItem = array =>
  isSomething(array) && hasItems(array) ? array[array.length - 1] : undefined

export const filterByCollection = (items, filter, predicate) =>
  isSomething(filter) && hasItems(filter)
    ? items.filter(
        item => filter.findIndex(element => predicate(item) === element) > -1
      )
    : []

export const notInArray = (array, item, key) => {
  return array.findIndex(arrItem => arrItem[key] === item[key]) === -1
}

export const getArrayFrequencies = array => {
  const counts = {}

  for (const num of array) {
    counts[num] = counts[num] ? counts[num] + 1 : 1
  }
  return counts
}

export const mapAndFilter = (array, itemCallback) => {
  return array.reduce((result, current) => {
    const item = itemCallback(current)
    if (item) result.push(item)
    return result
  }, [])
}

export const addSortIndexIfNotExist = array => {
  let nextSortIndex = 0
  return array
    .map(item => ({
      ...item,
      sortIndex: item.sortIndex != null ? item.sortIndex : nextSortIndex++
    }))
    .sort(
      (a, b) =>
        (a.sortIndex == null) - (b.sortIndex == null) ||
        a.sortIndex - b.sortIndex
    )
}

export const removeNoneFromArray = collection => {
  const arr = []
  for (const element of collection) if (isSomething(element)) arr.push(element)
  return arr
}
