import { COMPANY_PATHS } from '@/constants/navigation.js'

const companyRoutes = [
  {
    path: COMPANY_PATHS.COMPANY_INFO,
    alias: ['/company/companyinfo'],
    name: 'CompanyInfo',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "CompanyInfoHero" */ `@/views/company/companyInfo/components/CompanyInfoHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "CompanyInfo" */ `@/views/company/companyInfo/CompanyInfo.vue`
        )
    },
    meta: {
      title: 'meta__title__company_info',
      contentful: {
        category: 'PTRYWviqqOePp2mXVa86B',
        pageHelp: 'R7oV0MFgjt6o7oew65ua9'
      },
      breadcrumbs: [
        {
          text: 'Enhetsinformasjon',
          string: 'company__unit_info',
          disabled: true,
          href: COMPANY_PATHS.COMPANY_INFO
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${COMPANY_PATHS.PREMISES}/:id?`,
    name: 'Premises',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Premises" */ `@/views/company/premises/PremisesHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Premises" */ `@/views/company/premises/PremisesNewIdentity.vue`
        )
    },
    props: {
      hero: {
        header: 'premises__title__list',
        heroMainText: 'premises__hero_text'
      }
    },
    meta: {
      title: 'meta__title__premises',
      contentful: {
        category: '0WOh1a9hAdPOE5Gu3e2Jw',
        pageHelp: '4ZsXyPFf6EBqWFTchPQTlT'
      },
      breadcrumbs: [
        {
          text: 'Premises',
          string: 'premises__title__list'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: COMPANY_PATHS.DEPARTMENTS,
    name: 'Avdelinger',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "DepartmentHero" */
          `@/views/company/departments/components/DepartmentsHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Departments" */
          `@/views/company/departments/Departments.vue`
        )
    },
    meta: {
      title: 'meta__title__departments',
      contentful: {
        category: '4hGmJO6Z9nDLKiXoYSUUVs',
        pageHelp: '4tQtqWFbwJkM9OklTPUDxh'
      },
      breadcrumbs: [
        {
          text: 'Avdelinger',
          string: 'departments__title',
          disabled: false,
          href: '/app/company/departments'
        }
      ]
    },
    productionAvailable: true
  },
  {
    name: 'OrganizationalCharts',
    path: COMPANY_PATHS.ORGANIZATIONAL_CHARTS,
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "DepartmentHero" */
          `@/views/company/organizationalCharts/components/OrganisationChartsHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Departments" */
          `@/views/company/organizationalCharts/OrganizationalCharts.vue`
        )
    },
    meta: {
      title: 'meta__title__org_charts',
      contentful: {
        category: '262hz7ZSoMv5JWjkNWHzNC',
        pageHelp: '1h3EW14LlkpbyYKDt3Ipnu'
      },
      breadcrumbs: [
        {
          text: 'Organizational charts',
          string: 'org_charts__title'
        }
      ]
    },
    productionAvailable: true
  }
]

export default companyRoutes
