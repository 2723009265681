export const ACCESS_RIGHTS = {
  EDIT_VERNERUNDE: 'EDITVERNERUNDE',
  MY_ABSENCE: 'FRAVAER',
  MY_VACATION: 'FERIE',
  MY_COMPETENCE: 'MY_COMPETENCE',
  MY_HOURS: 'MINETIMER',
  MY_INCIDENTS: 'MINE_HENDELSER',
  EMPLOYER_ABSENCE: 'EDITFRAVAER',
  EMPLOYER_FOLLOWUP_ABSENCE: 'OPPFOLGINGSYKEMELDTE',
  EMPLOYER_VACATION: 'EDITFERIE',
  EMPLOYER_COMPETENCE: 'EDITKOMPETANSEOVERSIKT',
  EMPLOYER_EMPLOYEES: 'ANSATTE',
  LOKALER: 'LOKALER'
}
