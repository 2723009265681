const handookOnlyUsersData = [
  {
    permissionName: 'BRUKERE',
    parent: 'HANDBOOKONLYCONTAINER',
    menuItemText: 'menu__item__read_manuals',
    url: '/personalhandbok/handbooksread.xhtml',
    isLegacy: true
  },

  {
    permissionName: 'BRUKERE',
    parent: 'HANDBOOKONLYCONTAINER',
    menuItemText: 'menu__item__edit_manuals',
    url: '/personalhandbok/personalhandbokadmin.xhtml',
    isLegacy: true
  },
  {
    permissionName: 'BRUKERE',
    parent: 'HANDBOOKONLYCONTAINER',
    menuItemText: 'menu__item__manuals_settings',
    url: '/personalhandbok/personalhandbokadmin_innstillinger.xhtml',
    isLegacy: true
  },
  {
    permissionName: 'BRUKERE',
    parent: 'HANDBOOKONLYCONTAINER',
    menuItemText: 'menu__item__documents',
    url: '/card-file/files/',
    isLegacy: false
  },
  {
    permissionName: 'BRUKERE',
    parent: 'HANDBOOKONLYCONTAINER',
    menuItemText: 'menu__item__laws_regulations',
    url: '/card-file/laws/',
    isLegacy: false
  },
  {
    permissionName: 'BRUKERE',
    parent: 'HANDBOOKONLYCONTAINER',
    menuItemText: 'menu__item__users',
    url: '/personalhandbok/personalhandbookusers.xhtml',
    isLegacy: true
  }
]

export default handookOnlyUsersData
