export const localStorageKeys = {
  'myResponseInfo': false,
  'departmentsIntroBanner': true,
  'riskCriteriaRiskCalculator': true,
  'introBannerAgreements': true,
  'actionPlanIntroBanner': true,
  'actionPlanHeroLinkShow': false,
  'lawsIntroBanner': true,
  'isIntroBannerVisible': true,
  'filesShowBanner': true
}
