import { reactive } from 'vue'

import avonovaAPI from '@/config/axios'

import { isNone } from '@/utilities/conditionalUtilities'
import { isSuccessResponse } from '@/utilities/httpUtilities'

import { hasItems } from '../utilities/conditionalUtilities'
import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'

const state = reactive({
  activeAccesses: [],
  accessTypes: [],
  directLogins: [],
  availableForRequest: [],
  sentRequests: []
})

const connectedSupportUserState = reactive({
  request: {
    loaded: false,
    loading: false,
    failed: false,
    errors: []
  },
  supportUser: undefined
})

export const useCustomerSupportService = () => {
  const getActiveAccesses = async () => {
    const url = '/common/api/supportAccessConsultant/connectedAccounts'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success && hasItems(result.data)) {
      state.activeAccesses = result.data.map(access => {
        return {
          access_id: parseToEmptyStringIfNone(access.access_id),
          account_name: parseToEmptyStringIfNone(access.account_name),
          demo_account: access.demo_account ? 'Demo' : '',
          account_id: parseToEmptyStringIfNone(access.account_id),
          access_type: parseToEmptyStringIfNone(access.access_type),
          created_by: parseToEmptyStringIfNone(access.issuedBy),
          created_date: access.created_date,
          date_of_expiry: access.valid_thru_date,
          excel_export_possible: access.excel_export_possible
        }
      })
    }
  }

  const deleteActiveAccesses = async deleteIds => {
    const url = '/common/api/supportAccessConsultant/disconnectAccounts'
    const result = await requestHandler(httpMethods.POST, url, deleteIds)
    if (result.success) {
      deleteIds.ids.forEach(id => {
        const index = state.activeAccesses.findIndex(
          item => item.access_id === id
        )
        return index > -1 ? state.activeAccesses.splice(index, 1) : ''
      })
    }

    return result
  }

  const getProxyAccessUrl = async userId => {
    const url = `/common/api/supportAccessConsultant/getProxyUrl/${userId}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    return result
  }

  const getDirectLoginAccess = async () => {
    const url =
      '/common/api/supportAccessConsultant/availableAccountsForDirectLogin'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success && hasItems(result.data)) {
      state.directLogins = result.data.map(eachDirectLogin => {
        return {
          customer_id: parseToEmptyStringIfNone(eachDirectLogin.account_id),
          account_name: parseToEmptyStringIfNone(eachDirectLogin.account_name),
          org_nr: parseToEmptyStringIfNone(eachDirectLogin.organization_number),
          main_account_name: parseToEmptyStringIfNone(
            eachDirectLogin.main_account_name
          ),
          corporate_account: eachDirectLogin.corporate_account,
          onboarding_complete: eachDirectLogin.onboarding_complete
        }
      })
    }
  }

  const createDirectLoginAccess = async account => {
    const url = `/common/api/supportAccessConsultant/createDirectLogin`
    return await requestHandler(httpMethods.POST, url, account)
  }

  const getJ4EmployeeReport = async accountId => {
    const url = `/common/api/supportAccessConsultant/getEmployeeJ4Export/${accountId}`
    const headers = { 'Content-Type': 'blob' }
    const config = {
      method: 'GET',
      url: url,
      responseType: 'arraybuffer',
      headers
    }
    try {
      const result = await avonovaAPI(config)
      return { success: isSuccessResponse(result), ...result }
    } catch (error) {
      return {
        success: false,
        statusCode: error.response.status,
        data: error.response.data
      }
    }
  }

  const getAvailableAccountForRequest = async () => {
    const url =
      '/common/api/supportAccessConsultant/availableAccountsForRequest'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.availableForRequest = result.data
    return result
  }

  const sendAccessRequest = async requests => {
    const url = `/common/api/supportAccessConsultant/sendRequest`
    const result = await requestHandler(httpMethods.POST, url, requests)
    if (result.success) {
      state.availableForRequest = state.availableForRequest.filter(
        account => !requests.account_ids.includes(account.account_id)
      )
      result.data.forEach(request =>
        state.sentRequests.unshift({ newItem: true, ...request })
      )
    }
    return result
  }

  const getSentRequest = async () => {
    const url = '/common/api/supportAccessConsultant/requestList'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.sentRequests = result.data
    return result
  }

  const removeRequest = async requestIds => {
    const url = '/common/api/supportAccessConsultant/removeRequest'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      requestIds
    )
    if (result.success)
      state.sentRequests = state.sentRequests.filter(
        request => !requestIds.ids.includes(request.request_id)
      )
    return result
  }

  const getConnectedSupportUser = async () => {
    const url = '/common/api/supportAccessConsultant/connectedSupportUser'
    const result = await requestHandlerWithState(
      connectedSupportUserState,
      httpMethods.GET,
      url
    )
    if (result.success && result.data !== '')
      connectedSupportUserState.supportUser = result.data
    return result
  }

  const connectSupportUser = async supportUser => {
    const url = '/common/api/supportAccessConsultant/connectSupportUser'
    const result = await requestHandler(httpMethods.POST, url, supportUser)
    if (result.success && result.data !== '')
      connectedSupportUserState.supportUser = result.data
    return result
  }

  const disconnectSupportUser = async supportUser => {
    const url = '/common/api/supportAccessConsultant/disconnectSupportUser'
    const result = await requestHandler(httpMethods.POST, url, supportUser)
    if (result.success && result.data)
      connectedSupportUserState.supportUser = undefined
    return result
  }

  const parseToEmptyStringIfNone = text => {
    return isNone(text) ? '' : text + ''
  }

  return {
    state,
    getActiveAccesses,
    deleteActiveAccesses,
    getProxyAccessUrl,
    getDirectLoginAccess,
    createDirectLoginAccess,
    getJ4EmployeeReport,
    getAvailableAccountForRequest,
    sendAccessRequest,
    getSentRequest,
    removeRequest,
    getConnectedSupportUser,
    connectSupportUser,
    disconnectSupportUser,
    connectedSupportUserState
  }
}
