import {
  defaultLanguage,
  languages,
  languagesWithoutDefault,
  renderOptions
} from './contentfulConfig'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
/**
 * Update i18n local messages with relevant values from the given Contentful entries array.
 *
 * @param contentfulEntries Contentful entries array containing all translations
 * @param i18n Internationalization instance
 */

const contentTypePredicate = entry =>
  (entry.sys.contentType.sys.id === 'string' ||
    entry.sys.contentType.sys.id === 'text') &&
  entry.fields &&
  entry.fields.key

export const findMissingTranslations = contentfulEntries => {
  let missingTranslations = {}

  contentfulEntries.entries
    .filter(entry => {
      return contentTypePredicate(entry)
    })
    .forEach(entry => {
      const nodeType = entry.fields.text[defaultLanguage].nodeType
      const key = entry.fields.key[defaultLanguage]

      // Process other languages' content
      languagesWithoutDefault.forEach(lang => {
        let content = entry.fields.text[lang]
        if (!content) {
          // Key does not have translation.
          if (missingTranslations[lang] === undefined)
            missingTranslations[lang] = []

          if (missingTranslations[lang][key] === undefined)
            missingTranslations[lang].push({
              key,
              spaceId: entry.sys.space.sys.id,
              entryId: entry.sys.id
            })
        }
      })
    })

  return missingTranslations
}

export const updateMessages = contentfulEntries => {
  let localeMessages = []
  languages.forEach(lang => {
    localeMessages[lang] = {}
  })

  contentfulEntries.entries
    .filter(entry => {
      return (
        (entry.sys.contentType.sys.id === 'string' ||
        entry.sys.contentType.sys.id === 'text') ||
        entry.sys.contentType.sys.id === 'longLabel' &&
        entry.fields &&
        entry.fields.key
      )
    })
    .forEach(entry => {
      const nodeType = entry.fields.text[defaultLanguage].nodeType
      const key = entry.fields.key[defaultLanguage]

      // Process default language content
      let defaultContent = entry.fields.text[defaultLanguage]
      if (nodeType === 'document') {
        defaultContent = documentToHtmlString(defaultContent, renderOptions)
      }
      localeMessages[defaultLanguage][key] = defaultContent

      // Process other languages' content
      languagesWithoutDefault.forEach(lang => {
        let content = entry.fields.text[lang]
        if (content) {
          if (nodeType === 'document') {
            content = documentToHtmlString(content, renderOptions)
          }
        } else {
          // Use default language content if this language does not have content
          content = defaultContent
        }
        localeMessages[lang][key] = content
      })
    })

  return localeMessages
}

export const updateI18nLocaleMessages = (contentfulEntries, i18n) => {
  const localeMessages = updateMessages(contentfulEntries)

  // Finally, we have the complete structure to update i18n with
  for (const [locale, messages] of Object.entries(localeMessages)) {
    i18n.setLocaleMessage(locale, messages)
  }
}
