<template>
  <footer id="footer">
    <div class="footer-content">
      <div class="links-container">
        <div class="left-links">
          <div
            v-if="
              $te('footer__hyperLink_krise_telefon', $i18n.locale) &&
              !hideCrisisPhoneLink
            "
            class="d-flex flex-row link"
          >
            <Nucleo class="icon" icon="n-icon-phone" />

            <a
              id="footer-link-crisis-phone"
              :href="$sanitize($t('footer__hyperLink_krise_telefon'))"
              >{{ $t('footer__linkButton_text_krise_telefon') }}</a
            >
          </div>

          <div class="d-flex flex-row link">
            <Nucleo class="icon" icon="n-icon-phone" />
            <a
              id="footer-link-call"
              :href="$sanitize($t('footer__hyperLink_kontakt_oss'))"
              >{{ $t('footer__linkButton_text_kontakt_oss') }}</a
            >
          </div>
          <div class="d-flex flex-row link">
            <Nucleo class="icon" icon="n-icon-letter" />
            <a
              id="footer-link-email"
              :href="$sanitize($t('footer__hyperLink_contact_us_email'))"
              >{{ $t('footer__linkButton_text_email_kundesenter') }}</a
            >
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <p>{{ $t('footer__copyright_text') }}</p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed } from 'vue'

import { Nucleo } from '@hms-kontoret/avonova-storybook'

import store from '@/store'

import { ACCOUNT_COUNTRY } from '@/constants/i18n'

const hideCrisisPhoneLink = computed(
  () => store.getters['authUser/accountCountry'] === ACCOUNT_COUNTRY.FINLAND
)
</script>

<style scoped lang="scss">
@import '@/styles/colors.scss';

footer {
  width: 100%;
  padding: 2rem 1rem;
  background-color: white;
  position: sticky;

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    max-width: 1320px;
  }

  .links-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .left-links {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        margin-right: 0.1rem;
        color: $green1000;
        height: 16px !important;
      }

      a {
        margin-right: 3rem;
        color: #272727;
        text-decoration: none;
        font-size: 1rem;
      }
    }
  }

  .footer-copyright {
    p {
      margin-left: auto;
      font-size: 12px !important;
    }
  }
}

@media screen and (max-width: 960px) {
  footer {
    .footer-content {
      flex-direction: column;
      padding: 0;
      align-items: start !important;
    }

    .links-container {
      .left-links {
        justify-content: flex-start;
        text-align: left;
        flex-direction: column;
        align-items: start;

        .link {
          margin-bottom: 1.2rem;
        }
      }
    }
  }
}
</style>
