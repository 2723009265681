<template>
  <v-overlay :z-index="1000" :value="value">
    <div><AvonovaLoaderCircle :size="200" /></div>
  </v-overlay>
</template>

<script>
import { AvonovaLoaderCircle } from '@hms-kontoret/avonova-storybook'

export default {
  name: 'LoaderCircle',
  components: {
    AvonovaLoaderCircle
  },
  props: {
    value: { type: Boolean }
  }
}
</script>

<style></style>
