import { getLocaleCode, localizeDate } from '@/composable/common/dates.js'

function decomposeDateString(dateString) {
  const locale = getLocaleCode()
  const date = { year: null, month: null, day: null }
  if (locale === 'no') {
    date.year = dateString.split('.')[2]
    date.month = dateString.split('.')[1]
    date.day = dateString.split('.')[0]
  } else if (locale === 'se-SV') {
    date.year = dateString.split('-')[0]
    date.month = dateString.split('-')[1]
    date.day = dateString.split('-')[2]
  } else if (locale === 'en-GB') {
    date.year = dateString.split('/')[2]
    date.month = dateString.split('/')[1]
    date.day = dateString.split('/')[0]
  }
  return date
}

function isValidLocaleString(string) {
  if (string.split(' ').length !== 2) return false
  const [dateString, timeString] = string.split(' ')
  // Date validation
  const date = decomposeDateString(dateString)
  if (!date.year || date.year.length !== 4) return false
  else if (!date.month || date.month.length !== 2) return false
  else if (!date.day || date.day.length !== 2) return false
  // Time validation
  const time = timeString.split('.')
  if (time.length < 2) return false
  else if (time[0].length !== 2 || time[1].length !== 2) return false
  // NaN-check
  const dateObject = new Date(date.year, date.month, date.day, time[0], time[1])
  return dateObject && !isNaN(dateObject.getTime())
}

export const convertDateAndTimeFormats = () => {
  function localeToISOString(localeString) {
    if (isValidLocaleString(localeString) === false) return null
    const [dateString, timeString] = localeString.split(' ')
    const date = decomposeDateString(dateString)
    const [hour, minute] = timeString.split('.')
    return `${date.year}-${date.month}-${date.day}T${hour}:${minute}:00`
  }

  function isoStringToLocale(isoString) {
    if (!isoString?.length > 0) return ''
    const dateString = localizeDate(isoString)
    const time = isoString.split('T')[1].split(':')
    return `${dateString} ${time[0]}.${time[1]}`
  }

  return {
    isoStringToLocale,
    localeToISOString
  }
}
