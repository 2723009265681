import { authService } from '@/services/'
import { useAmberAPI } from '@/utilities/iframe'

export const getToken = async (tokenPrefix = 'Token') => {
  const amberAPI = useAmberAPI()

  if (amberAPI) {
    const { idToken } = await amberAPI.v1.authContext.getToken()
    return `${tokenPrefix} ${idToken}`
  } else return `${tokenPrefix} ${authService.getToken()}`
}
