const successCodes = [200, 201, 204]

export const isSuccessResponse = response =>
  successCodes.includes(response.status)

export const requestStarted = () => ({
  loaded: false,
  loading: true,
  failed: false,
  errors: []
})

export const requestFinished = () => ({
  loaded: true,
  loading: false,
  failed: false,
  errors: []
})

export const requestFailed = errors => ({
  loaded: true,
  loading: false,
  failed: true,
  errors: Array.isArray(errors) ? errors : [errors]
})
