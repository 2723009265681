export const isSomething = entity => entity !== undefined && entity !== null

export const isNone = entity => entity === undefined || entity === null

export const isEmpty = entity => isSomething(entity) && entity.length === 0

export const hasItems = array => Array.isArray(array) && array.length > 0

export const eitherOr = (either, or) => either ?? or

export const stringEmpty = (either, or) => (either.length === 0 ? or : either)
