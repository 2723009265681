import avonovaAPI from '@/config/axios'
import router from '@/router'

import { useContentfulFunctions } from '@/composable/common/contentful'
import { useAccountService } from '@/services/account/account-service.js'
import { useCustomerProductPackageService } from '@/services/customer-product-package'
import { useFilesService } from '@/services/files-service'

const actions = {
  getAuthorizations() {
    return avonovaAPI.get('/api/authorization')
  },
  async getInitData({ commit, dispatch }) {
    const { getAccountLogo } = useFilesService()
    const { getFeatureFlags } = useAccountService()
    const { getCustomerProductPackages } = useCustomerProductPackageService()

    try {
      const [res, flags] = await Promise.all([
        avonovaAPI.get('/common/api/accounts/init'),
        getFeatureFlags()
      ])

      commit('setInitData', res.data)
      commit('featureFlags/SET_FEATURE_FLAGS', flags, { root: true })
      dispatch('authUser/updateAuthUser', res.data, { root: true })

      try {
        const customerProductPackages = await getCustomerProductPackages()
        dispatch('authUser/updateProductPackage', customerProductPackages, {
          root: true
        })
      } catch (error) {
        console.error('Error fetching customer product packages:', error)
      }

      try {
        const logo = await getAccountLogo()
        if (logo) commit('setCompanyLogo', URL.createObjectURL(logo))
      } catch (error) {
        console.error('Error fetching account logo:', error)
      }
    } catch (error) {
      console.error('Error initializing data:', error)
    }
  },
  async setContentfulInit(_, i18n) {
    await useContentfulFunctions().initDataFromCache(i18n)
  },
  getHandbookInfo({ commit }) {
    avonovaAPI.get('/directives/api/handbook/readhandbookinfo').then(res => {
      commit('setHandbookInfo', res.data)
    })
  },
  executeJwtAuthenticationService(_context, userData) {
    return avonovaAPI.post('/common/api/authenticate', userData)
  },
  redirectToPathName(_context, path) {
    if (path && parseInt(path.id) > 0) {
      router.push({ name: path.url, params: { id: path.id } })
    } else {
      router.push({ name: path.url })
    }
  },
  getCompanyInfo({ commit }) {
    return avonovaAPI.get('/company/api/company').then(response => {
      commit('storeCompanyInfo', response.data)
    })
  },
  logError(_context, error) {
    return avonovaAPI.post('/monolith/api/errorlog', error)
  },
  newNotification({ commit }, notification) {
    commit('newNotification', notification)
  },
  popNotification({ state }) {
    return state.notifications.pop()
  },
  setAppLoaded({ commit }, value) {
    commit('setAppLoaded', value)
  }
}
export default actions
