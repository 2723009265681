
export default [
  "index",
  "/rettigheter/rettigheter_frame",
  "/hendelsesregister/vernerunde/editVernerundestatusbar",
  "/hendelsesregister/vernerunde/editVernerundePlan",
  "/hendelsesregister/vernerunde/editVernerundeReport",
  "/hendelsesregister/vernerunde/editVernerundeWalk",
  "/hendelsesregister/vernerunde/editVernerundePlanCheckpointDialog",
  "/hendelsesregister/vernerunde/vernerunder",
  "/hendelsesregister/vernerunde/editVernerundeOverviewCheckpointDialog",
  "/hendelsesregister/vernerunde/editVernerunde",
  "/hendelsesregister/rapporter/oversikt",
  "/hendelsesregister/rapporter/hf_oversikt",
  "/hendelsesregister/rapporter/hendelser_utvikling",
  "/hendelsesregister/rapporter/aarsplan_oversikt",
  "/hendelsesregister/rapporter/hendelser_oversikt",
  "/hendelsesregister/rapporter/hendelser_oversikt_textreport",
  "/hendelsesregister/hendelse/events-datatable",
  "/hendelsesregister/hendelse/editMinHendelse",
  "/hendelsesregister/hendelse/events",
  "/hendelsesregister/hendelse/editHendelse-details",
  "/hendelsesregister/hendelse/editHendelse",
  "/hendelsesregister/hendelse/mineHendelser",
  "/hendelsesregister/tiltak/editTiltak",
  "/hendelsesregister/tiltak/handlingsplaner_frame",
  "/hendelsesregister/tiltak/handlingsplaner_frame_simple",
  "/hendelsesregister/tiltak/editTiltakMultiple",
  "/hendelsesregister/tiltak/yearplan",
  "/hendelsesregister/tilsynsrapport/editTilsynsrapport",
  "/hendelsesregister/tilsynsrapport/tilsynsrapporter",
  "/hendelsesregister/revisjon/editRevision_Perform",
  "/hendelsesregister/revisjon/editRevisionReportCheckpointDialog",
  "/hendelsesregister/revisjon/revisions",
  "/hendelsesregister/revisjon/editRevision_Plancheckpoints",
  "/hendelsesregister/revisjon/editRevision_Planning",
  "/hendelsesregister/revisjon/editRevision",
  "/hendelsesregister/revisjon/editRevision_Report",
  "/hendelsesregister/forslag/editForslag",
  "/hendelsesregister/forslag/forslag",
  "/hendelsesregister/forslag/forslag_overview",
  "/hendelsesregister/forslag/suggestion",
  "/konsern/konsernrapporter_overview",
  "/konsern/konsernrapporter_events",
  "/konsern/konsernrapporter",
  "/konsern/konsernkontoer",
  "/fileupload/ckeditorbrowser",
  "/ansatte/editAnsatt",
  "/ansatte/ansatt_skjemaer",
  "/ansatte/minside/minSide_mysettings",
  "/ansatte/minside/minSide",
  "/ansatte/minside/minSide_myforms",
  "/ansatte/minside/minSide_detaljer",
  "/ansatte/minside/minSide_logindetails",
  "/ansatte/minside/minSide_myfiles",
  "/ansatte/fravaer/absence_followup",
  "/ansatte/fravaer/absence_registration",
  "/ansatte/fravaer/absence_usersettings",
  "/ansatte/fravaer/absence_request_process",
  "/ansatte/fravaer/absence_overview",
  "/ansatte/fravaer/absence_accountsettings",
  "/ansatte/fravaer/absence_report",
  "/ansatte/fravaer/fravaer",
  "/ansatte/fravaer/absence_reject_dialog",
  "/ansatte/fravaer/absence_overview_filters",
  "/ansatte/fravaer/absence_report_filter",
  "/ansatte/employeeOverlaypanel",
  "/ansatte/planning/planning_overview_watchtypes",
  "/ansatte/planning/planning_colorcodes",
  "/ansatte/planning/planning_overview",
  "/ansatte/planning/planning_dailyoverview",
  "/ansatte/planning/planning_filter",
  "/ansatte/importexport/employeeimport",
  "/ansatte/importexport/employeepreview",
  "/ansatte/importexport/employeexport",
  "/ansatte/ansatt_journal",
  "/ansatte/skjemaer/oppsigelseprovetid",
  "/ansatte/skjemaer/ansatt_skjema_template",
  "/ansatte/skjemaer/sluttattest",
  "/ansatte/skjemaer/taushetserklaering",
  "/ansatte/skjemaer/utlevertverneutstyr",
  "/ansatte/skjemaer/fragments/ansatt_info_simple",
  "/ansatte/skjemaer/fragments/ansatt_info_pnr",
  "/ansatte/skjemaer/fragments/avskjed_oppsigelse_info",
  "/ansatte/skjemaer/fragments/save_and_print",
  "/ansatte/skjemaer/fragments/company_info",
  "/ansatte/skjemaer/fragments/oppsigelse_info",
  "/ansatte/skjemaer/fragments/ansatt_info",
  "/ansatte/skjemaer/fragments/tariff_info",
  "/ansatte/skjemaer/oppsigelsearbeidstakerforhold",
  "/ansatte/skjemaer/oppsigelsevirksomhetforhold",
  "/ansatte/skjemaer/varselompermitering",
  "/ansatte/skjemaer/advarsel",
  "/ansatte/skjemaer/arbeidsavtale",
  "/ansatte/skjemaer/droftelsesmote",
  "/ansatte/skjemaer/avskjed",
  "/ansatte/skjemaer/medarbeidersamtale",
  "/ansatte/skjemaer/oppfolgingsykemeldte",
  "/ansatte/skjemaer/ansatt_skjema_tilknyttedefiler_fieldset",
  "/ansatte/ansatte",
  "/ansatte/ferie/vacation_overview",
  "/ansatte/ferie/vacation_administration_settings",
  "/ansatte/ferie/vacation_usersettings",
  "/ansatte/ferie/vacation_reqregistration",
  "/ansatte/ferie/approv_vacationrequest_not_enough_days_dialog",
  "/ansatte/ferie/vacation_accountsettings",
  "/ansatte/ferie/ferie",
  "/ansatte/ferie/vacation_registration",
  "/ansatte/ferie/vacation_overview_filters",
  "/ansatte/ferie/reject_vacationrequest_dialog",
  "/ansatte/employeeQuarantineOverlaypanel",
  "/ansatte/editAnsattNorge",
  "/ansatte/ansatt_dashboard",
  "/timeregistrering/timerapport_prosjekt_filter",
  "/timeregistrering/timerapport_peransatt_filter",
  "/timeregistrering/timeregistrering_timefoering",
  "/timeregistrering/timeregistrering",
  "/timeregistrering/timerapport",
  "/timeregistrering/timerapport_ansatte",
  "/timeregistrering/stemplingsur_registrering",
  "/timeregistrering/ansatt_timerapport",
  "/timeregistrering/timerapport_prosjekt",
  "/timeregistrering/stemplingsur_mobil_registrering",
  "/timeregistrering/timerapport_perprosjektansatt",
  "/timeregistrering/timeregistrering_overview",
  "/timeregistrering/timeregistrering_ansatte",
  "/timeregistrering/ansatt_timerapport_filter",
  "/timeregistrering/timeregistrering_timetyper",
  "/timeregistrering/timefoering_edit",
  "/timeregistrering/timerapport_ansatte_filter",
  "/timeregistrering/timefoering",
  "/timeregistrering/timerapport_perprosjektansatt_filter",
  "/timeregistrering/timefoering_splitt",
  "/timeregistrering/timeregistering_overview_filters",
  "/timeregistrering/timerapport_peransatt",
  "/prosjekt/prosjektadmin",
  "/prosjekt/editProsjekt",
  "/updatePassword",
  "/dokumenter/editElement",
  "/dokumenter/elementer",
  "/dokumenter/ansatt_dokumenter_frame",
  "/dokumenter/minside_dokumenter_frame",
  "/dokumenter/lastopp",
  "/dokumenter/dokumenter_frame",
  "/dokumenter/readrequest/readconfirm_frame",
  "/dokumenter/readrequest/readrequest_frame",
  "/admintools/supportusers",
  "/kontrollpanel/editRettigheter",
  "/kontrollpanel/brukeroversikt_alertroles",
  "/kontrollpanel/brukeroversikt",
  "/kontrollpanel/readuseraccessrights",
  "/kontrollpanel/brukeroversikt_groups",
  "/kontrollpanel/brukeroversikt_users",
  "/kontrollpanel/editBruker",
  "/lovverk/editLovverk",
  "/lovverk/hmsColumn",
  "/lovverk/lovverk",
  "/kompetanseoversikt/competence_overview",
  "/kompetanseoversikt/competence_employee_settings",
  "/kompetanseoversikt/kompetanseoversikt",
  "/kompetanseoversikt/competence_overview_filters",
  "/kompetanseoversikt/editKompetanseoversikt",
  "/kompetanseoversikt/competence_registration",
  "/nyheter/editNyhet",
  "/nyheter/nyheter",
  "/nyheter/breakingNews",
  "/nyheter/lesNyhet",
  "/oppsett/oppsett",
  "/kontoinnstillinger/kontoinnstillinger_formsettings",
  "/kontoinnstillinger/kontoinnstillinger_hoursregistration",
  "/kontoinnstillinger/kontoinnstillinger",
  "/kontoinnstillinger/kontoinnstillinger_general",
  "/kontoinnstillinger/kontoinnstillinger_revision",
  "/kontoinnstillinger/kontoinnstillinger_eventtypes",
  "/kontoinnstillinger/kontoinnstillinger_logo",
  "/kontoinnstillinger/kontoinnstillinger_category_locations",
  "/risikosenter/ros/risikosenter",
  "/risikosenter/ros/akseptabelrisikorapport",
  "/risikosenter/ros/editRiskAssessment_Details",
  "/risikosenter/ros/editRiskAssessment_Evaluation",
  "/risikosenter/ros/riskAnalysisReport",
  "/risikosenter/ros/editRiskAssessment_Brainstorming",
  "/risikosenter/ros/editRiskAssessment",
  "/risikosenter/ros/riskAssessmentReportsOverview",
  "/risikosenter/ros/akseptabelrisikoopup",
  "/risikosenter/ros/editRiskAssessment_Report",
  "/risikosenter/ros/riskAssessmentsReport",
  "/risikosenter/ros/rapporter",
  "/risikosenter/ros/riskAssessments",
  "/risikosenter/ros/riskAssessmentsOverview",
  "/risikosenter/jsa/my_jobsafetyanalysis_edit",
  "/risikosenter/jsa/jobsafetyanalysis_edit",
  "/risikosenter/jsa/jobsafetyanalysis_edit_details",
  "/risikosenter/jsa/jobsafetyanalysis_edit_wizard",
  "/risikosenter/jsa/jobsafetyanalysis_overview_table",
  "/risikosenter/jsa/my_jobsafetyanalysis_overview",
  "/risikosenter/jsa/jobsafetyanalysis_overview",
  "/risikosenter/jsa/jobsafetyanalysis_edit_activities",
  "/risikosenter/jsa/jobsafetyanalysis_edit_report",
  "/resources/simplenesscomposites/section",
  "/resources/simplenesscomposites/pill",
  "/resources/simplenesscomposites/simpleSection",
  "/resources/simplenesscomposites/calendar",
  "/resources/simplenesscomposites/searchAutocomplete",
  "/resources/simplenesscomposites/inputTextArea",
  "/resources/simplenesscomposites/navigationCard",
  "/resources/simplenesscomposites/staticMessage",
  "/resources/simplenesscomposites/tooltip",
  "/resources/simplenesscomposites/searchHighlight",
  "/resources/simplenesscomposites/hero",
  "/resources/simplenesscomposites/carousel",
  "/resources/simplenesscomposites/numberCircle",
  "/resources/simplenesscomposites/mediaObjectCircularProgressBar",
  "/resources/simplenesscomposites/dataTable",
  "/resources/simplenesscomposites/mediaObjectIcon",
  "/resources/errorPages/NotFound",
  "/resources/errorPages/EntityRemovedException",
  "/resources/errorPages/ViewExpiredException",
  "/resources/errorPages/CustomException",
  "/resources/errorPages/Throwable",
  "/resources/staminacomposites/riskAssessmentIndexSmall",
  "/resources/staminacomposites/riskAssessmentIndex",
  "/resources/staminacomposites/riskvalue",
  "/resources/staminacomposites/riskindexcontainer",
  "/resources/page-settingsEventTypes/settingsEventTypes",
  "/styrendedokumenter/editStyrendeDokument",
  "/styrendedokumenter/styrendeDokumenter_groupedbycategory",
  "/styrendedokumenter/styrendeDokumenter",
  "/styrendedokumenter/lesStyrendeDokument",
  "/styrendedokumenter/styrendeDokumenter_templates",
  "/styrendedokumenter/styrendeDokumenter_overview",
  "/styrendedokumenter/editStyrendeDokumentKategorier",
  "/styrendedokumenter/styrendedokumentsearchfield",
  "/hjelp/hjelp",
  "/hjelp/hjelpHorisontal",
  "/language/translation",
  "/arbeidsutstyr/editArbeidsutstyr",
  "/arbeidsutstyr/arbeidsutstyr",
  "/dokumentmaler/dokumentmaler",
  "/dokumentmaler/readDatabehandleravtale",
  "/dokumentmaler/readSla",
  "/varslinger/editVarslingsrolle",
  "/varslinger/varslinger",
  "/print/printInstructionsList",
  "/print/printTiltakslistedetaljert",
  "/print/printTimerapport_prosjektPage",
  "/print/printStoffkartotek",
  "/print/printRiskAssessment",
  "/print/printStyrendeDokument",
  "/print/printForslag",
  "/print/printAnsatt_timerapport",
  "/print/printKontakt",
  "/print/printEgendefinertAnsattliste",
  "/print/printAnsattKompetanseoversiktPage",
  "/print/printAnsattKompetanseoversikt",
  "/print/printHendelse",
  "/print/printAnsatteHandbok",
  "/print/printAnsatte",
  "/print/printForslagliste",
  "/print/printKonsernrapportTiltak",
  "/print/printStyrendeDokumenter",
  "/print/printFerieRapportPage",
  "/print/printTiltaksliste",
  "/print/printFravaerRapportPage",
  "/print/printVernerunder",
  "/print/printRiskAssessmentIndex",
  "/print/printTimerapport_ansatte",
  "/print/printColumnFilterAndSortValues",
  "/print/printLokalePage",
  "/print/printArbeidsplanPage",
  "/print/printVernerundeUtfylling",
  "/print/printVernerunde",
  "/print/printForslagPage",
  "/print/printHendelseListePage",
  "/print/printStyrendeDokumentPageHB",
  "/print/printVernerunderutfylling",
  "/print/printFerieRapport",
  "/print/printTiltak",
  "/print/printArbeidsutstyrsliste",
  "/print/printTimerapport_peransatt",
  "/print/printProsjektListe",
  "/print/printAnsvarmyndighet",
  "/print/printRevisjonPage",
  "/print/printAnsatt_timerapport_Page",
  "/print/printPaarorendeListe",
  "/print/printKompetanseoversikt",
  "/print/printAnsvarmyndighetPage",
  "/print/printProsjektListePage",
  "/print/printHendelsePage",
  "/print/printArbeidsutstyrListePage",
  "/print/printRiskAssessmentsReport",
  "/print/printHendelseHFrapport",
  "/print/printAbsence_followup",
  "/print/printJournalPage",
  "/print/printMote",
  "/print/printRiskAssessmentPage",
  "/print/printKontaktPage",
  "/print/printPaarorendeListePage",
  "/print/printLovliste",
  "/print/printStoffkartotekliste",
  "/print/printProsjektPage",
  "/print/printHandbok",
  "/print/printHandlingsplanPage",
  "/print/printAnsvarmyndighetliste",
  "/print/printStoffkartotekListerPage",
  "/print/printAnsatt",
  "/print/printHendelser",
  "/print/dokumentmaler/egenerklaering",
  "/print/dokumentmaler/databehandler",
  "/print/dokumentmaler/alternativtilverneombud",
  "/print/printTiltakslisteFront",
  "/print/printTilsynsrapportPage",
  "/print/printFravaerTomtSkjema",
  "/print/printAvtaler",
  "/print/printUploadedOrgkartPage",
  "/print/printVernerundePage",
  "/print/printTimerapport_ansattePage",
  "/print/printStoffkartotekPage",
  "/print/printVernerundePageUtfylling",
  "/print/printStoffkartoteklister",
  "/print/printTimerapport_prosjekt",
  "/print/printRevisjonliste",
  "/print/printVacationAdministrationSettings",
  "/print/printProsjekt",
  "/print/expiredPrint",
  "/print/printMoter",
  "/print/printKontakter",
  "/print/printHandlingsplaner",
  "/print/printKonsernrapportHendelse",
  "/print/printFerieliste",
  "/print/printTimerapport_perprosjektansatt",
  "/print/printPersonalhandbok",
  "/print/printMalsettings",
  "/print/printKompetanseoversiktPage",
  "/print/printFravaerProsentRapportPage",
  "/print/printPlanningMonthOverview",
  "/print/printAnsvarmyndighetlisteHandbok",
  "/print/printLokale",
  "/print/printForetaksinformasjon",
  "/print/printSignatur",
  "/print/printAnsatteListe",
  "/print/printHendelsestyperapport",
  "/print/printMotePage",
  "/print/printTiltakListePage",
  "/print/printJobSafetyAnalysisPage",
  "/print/printFravaerProsentRapport",
  "/print/printMalsettingHandbok",
  "/print/printHendelseliste",
  "/print/printTimeoversikt_ansattePage",
  "/print/printFravaerRapport",
  "/print/printStyrendeDokumentPage",
  "/print/editSignatur",
  "/print/printFerie_liste",
  "/print/printPlanningYearOverview",
  "/print/printJobSafetyAnalysisList",
  "/print/printArbeidsutstyrPage",
  "/print/printTilsynsrapport",
  "/print/printTomhendelse",
  "/print/printJobSafetyAnalysis",
  "/print/printTilsynsrapporter",
  "/print/printTimerapport_perprosjektansattPage",
  "/print/printAnsattPage",
  "/print/printAbsence_followupPage",
  "/print/printTiltakslisteRapport",
  "/print/printMalsetting",
  "/print/printHandlingsplan",
  "/print/employeeforms/printAvskjed",
  "/print/employeeforms/printDroftelsesmotePage",
  "/print/employeeforms/printTaushetserklaeringPage",
  "/print/employeeforms/fragments/ansatt_info_pnr",
  "/print/employeeforms/fragments/avskjed_oppsigelse_info",
  "/print/employeeforms/fragments/company_info",
  "/print/employeeforms/fragments/oppsigelse_info",
  "/print/employeeforms/fragments/ansatt_info",
  "/print/employeeforms/fragments/tariff_info",
  "/print/employeeforms/printUtlevertverneutstyrPage",
  "/print/employeeforms/printOppsigelsearbeidstakerforholdPage",
  "/print/employeeforms/printArbeidsavtale",
  "/print/employeeforms/printVarselompermitering",
  "/print/employeeforms/printArbeidsavtaler",
  "/print/employeeforms/printUtlevertverneutstyr",
  "/print/employeeforms/printOppsigelseprovetid",
  "/print/employeeforms/printAdvarsel",
  "/print/employeeforms/printOppsigelsevirksomhetforholdPage",
  "/print/employeeforms/printOppfolgingsykemeldte",
  "/print/employeeforms/printTaushetserklaering",
  "/print/employeeforms/printSluttattest",
  "/print/employeeforms/printOppfolgingsykemeldtePage",
  "/print/employeeforms/printOppsigelsearbeidstakerforhold",
  "/print/employeeforms/printAvskjedPage",
  "/print/employeeforms/printSluttattestPage",
  "/print/employeeforms/printDroftelsesmote",
  "/print/employeeforms/printVarselompermiteringPage",
  "/print/employeeforms/printOppsigelseprovetidPage",
  "/print/employeeforms/printArbeidsavtalePage",
  "/print/employeeforms/printOppsigelsevirksomhetforhold",
  "/print/employeeforms/printMedarbeidersamtalePage",
  "/print/employeeforms/printAdvarselPage",
  "/print/employeeforms/printMedarbeidersamtale",
  "/print/printAvtale",
  "/print/printForetaksinformasjonHandbok",
  "/print/printArbeidsutstyr",
  "/print/printPlanningYearOverview_colorcodes",
  "/print/printRiskCriteriaPage",
  "/print/printTimerapport_peransattPage",
  "/print/printPersonalhandbokManual",
  "/print/printAnsatteListePage",
  "/print/printPersonalhandbokChapter",
  "/print/printLokaler",
  "/print/printKompetanseoversiktRapport",
  "/print/printTiltakPage",
  "/print/printTiltakslisteRapportTekst",
  "/print/printPaarorendeListeHandbok",
  "/print/printRevisjon",
  "/print/printRiskCriteria",
  "/print/printArbeidsutstyrListe",
  "/print/printLokalerHandbok",
  "/print/printAvtalePage",
  "/print/printTimeoversikt_ansatte",
  "/print/printKontakterHandbok",
  "/print/printPersonalhandbokManualPage",
  "/nede_for_vedlikehold",
  "/handbok/ReadHandbokPrint",
  "/handbok/editHandbok",
  "/handbok/handbok",
  "/logout",
  "/login",
  "/ansvarmyndighet/editAnsvarmyndighet",
  "/ansvarmyndighet/ansvarmyndighet",
  "/proxy_login_prohibited",
  "/employee_forms/employee_form_registration_execute",
  "/employee_forms/employee_form_registration_OPPSIGELSE_VIRKSOMHETFORHOLD",
  "/employee_forms/employee_forms_overview_filters",
  "/employee_forms/employee_form_registration_",
  "/employee_forms/employee_forms_overview",
  "/employee_forms/employee_form_registration_ARBEIDSAVTALE",
  "/employee_forms/employee_form_registration_VARSEL_OM_PERMITERING",
  "/employee_forms/employee_form_registration",
  "/employee_forms/employee_form_registration_OPPSIGELSE_PROVETID",
  "/employee_forms/employee_form_registration_TAUSHETSERKLAERING",
  "/employee_forms/employee_form_registration_start",
  "/employee_forms/employee_form_registration_setup",
  "/employee_forms/employee_form_registration_ADVARSEL",
  "/employee_forms/createemployeeFormpanel",
  "/employee_forms/employee_forms",
  "/employee_forms/employee_form_registration_UTLEVERT_VERNEUTSTYR",
  "/employee_forms/employee_form_registration_OPPSIGELSE_ARBEIDSTAKERFORHOLD",
  "/employee_forms/employee_form_registration_SLUTTATTEST",
  "/employee_forms/employee_form_registration_AVSKJED",
  "/employee_forms/employee_form_registration_DROFTELSESMOTE",
  "/index",
  "/login_im",
  "/logo/logo",
  "/malsetting/competencewidgetdetails",
  "/malsetting/goals_responsibilitieswidgetdetails",
  "/malsetting/safetyinspectionwidgetdetails",
  "/malsetting/employee_surveywidgetdetails",
  "/malsetting/editMalsetting",
  "/malsetting/competencewidget",
  "/malsetting/vacationplanningconfig",
  "/malsetting/goals_responsibilitieswidget",
  "/malsetting/safetyinspectionwidget",
  "/malsetting/unwantedeventsnotinuse",
  "/malsetting/vacationplanningnotinuse",
  "/malsetting/unwantedeventswidgetdetails",
  "/malsetting/riskconfig",
  "/malsetting/safetyinspectionnotinuse",
  "/malsetting/suggestionswidgetdetails",
  "/malsetting/actionplanwidgetdetails",
  "/malsetting/absence_follow_upnotinuse",
  "/malsetting/turnoverwidget",
  "/malsetting/employee_surveywidget",
  "/malsetting/suggestionsconfig",
  "/malsetting/actionplannotinuse",
  "/malsetting/absence_follow_upwidget",
  "/malsetting/competencenotinuse",
  "/malsetting/absenceconfig",
  "/malsetting/actionplanconfig",
  "/malsetting/unwantedeventswidget",
  "/malsetting/absencenotinuse",
  "/malsetting/turnovernotinuse",
  "/malsetting/vacationplanningwidgetdetails",
  "/malsetting/suggestionsnotinuse",
  "/malsetting/turnoverwidgetdetails",
  "/malsetting/riskwidget",
  "/malsetting/competenceconfig",
  "/malsetting/safetyinspectionconfig",
  "/malsetting/vacationplanningwidget",
  "/malsetting/goals_responsibilitiesconfig",
  "/malsetting/absencewidgetdetails",
  "/malsetting/suggestionswidget",
  "/malsetting/absence_follow_upconfig",
  "/malsetting/actionplanwidget",
  "/malsetting/unwantedeventsconfig",
  "/malsetting/absencewidget",
  "/malsetting/risknotinuse",
  "/malsetting/riskwidgetdetails",
  "/malsetting/absence_follow_upwidgetdetails",
  "/malsetting/turnoverconfig",
  "/malsetting/goals_responsibilitiesnotinuse",
  "/malsetting/employee_surveyconfig",
  "/malsetting/malsettings",
  "/akseptkriterier/akseptkriterier",
  "/moter/moter",
  "/moter/editMote",
  "/personalhandbok/comparemanualspopup",
  "/personalhandbok/comparehandbookspopup",
  "/personalhandbok/manualread",
  "/personalhandbok/personalhandbokadmin_manual",
  "/personalhandbok/personalhandbokadmin_handbok",
  "/personalhandbok/manualssearch",
  "/personalhandbok/editManual",
  "/personalhandbok/handbookread",
  "/personalhandbok/editPersonalhandbok",
  "/personalhandbok/viewManualRevision",
  "/personalhandbok/personalhandbokadmin",
  "/personalhandbok/personalhandbookedituser",
  "/personalhandbok/handbooksread",
  "/personalhandbok/personalhandbookusers",
  "/personalhandbok/addmanualtochapterpopup",
  "/personalhandbok/manualusedinhandbokpopup",
  "/personalhandbok/personalhandbokadmin_innstillinger",
  "/personalhandbok/manualsearchfield",
  "/personalhandbok/viewmanualpopup",
  "/accountclosed",
  "/startside/aggregateddashboardwelcomepage",
  "/startside/aggregateddashboardpage",
  "/startside/mylinks",
  "/startside/mynews",
  "/startside/startside",
  "/startside/aggregateddashboard",
  "/startside/myhandbooks",
  "/startside/dashboards/dashboard_management_documents",
  "/startside/dashboards/dashboard_company_information",
  "/startside/dashboards/dashboard_personnel",
  "/startside/dashboards/dashboard_account_management",
  "/startside/dashboards/dashboard_eventregistry",
  "/startside/dashboards/dashboard_manuals",
  "/startside/dashboards/dashboard_cardfileindex",
  "/startside/dashboards/dashboard_manualsonly",
  "/startside/dashboards/dashboard_settings",
  "/startside/dashboards/dashboard_riskcenter",
  "/startside/mytasks",
  "/startside/tasksdashboard",
  "/startside/tasksdashboardpage",
  "/startside/editStartsideElement",
  "/employee_interview/employee_interview_overview_filters",
  "/employee_interview/employee_interview_overview",
  "/employee_interview/employee_interview_registration_execute",
  "/employee_interview/employee_interview",
  "/employee_interview/employee_interview_registration",
  "/employee_interview/employee_interview_registration_employees",
  "/employee_interview/employee_interview_registration_topics",
  "/onboarding/onboarding",
  "/onboarding/onboarding_editemployee",
  "/onboarding/onboarding_completed",
  "/onboarding/onboardingansatte",
  "/updateUserData",
  "/arbeidsplan/arbeidsplaner",
  "/arbeidsplan/editArbeidsplanVakttype",
  "/arbeidsplan/helligdager",
  "/arbeidsplan/editHelligdag",
  "/arbeidsplan/oversikt",
  "/arbeidsplan/arbeidsplan",
  "/arbeidsplan/arbeidsplanredigering",
  "/arbeidsplan/vakttyper"
]
