export const DEFAULT_LANGUAGE = 'nb-NO'

export const SUPPORTED_LANGUAGES = [
  'nb-NO',
  'nb-EN',
  'sv-SE',
  'sv-EN',
  'nb-PL',
  'fi-FI',
  'fi-SE'
]
