import { reactive } from 'vue'

import store from '@/store'

import { useAccountService } from '@/services/account/account-service'
import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from '@/services/requestHandler'
import { removeItemAtIndex } from '@/utilities/arrayUtilities'
import { addSortIndexIfNotExist, orderBy } from '@/utilities/arrayUtilities.js'

import {
  mapCreateSafetyInspectionTemplatesBody,
  mapSafetyInspection,
  mapSafetyInspectionCategory,
  mapSafetyInspectionCheckpoint,
  mapSafetyInspectionList,
  mapSafetyInspectionTemplate,
  mapSafetyInspectionTemplateOverview,
  mapSaveSafetyInspectionBody,
  mapSaveSafetyInspectionCategoriesBody,
  mapSavesafetyInspectionCheckpointBody,
  mapUpdateSafetyInspectionTemplatesBody
} from './mapper.js'

const { getAccessRights } = useAccountService()

const state = reactive({
  safetyInspectionsList: [],
  fullSafetyInspectionsList: [],
  safetyinspection: null,
  safetyInspectionLocations: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  },
  accessRights: {}
})

const baseUrl = '/hse/api/safetyinspections'

const updateAndSortSafetyInspection = inspection => {
  const updatedInspection = { ...inspection }
  updatedInspection.category = addSortIndexIfNotExist(
    updatedInspection.category
  )
  updatedInspection.category.forEach(category => {
    category.checkpoints = addSortIndexIfNotExist(category.checkpoints)
  })
  orderBy(updatedInspection.category, cat => cat.sortIndex)
  updatedInspection.category.forEach(category => {
    orderBy(category.checkpoints, check => check.sortIndex)
  })
  return updatedInspection
}

export const useSafetyInspectionService = () => {
  const setAccessRights = async () => {
    if (Object.keys(state.accessRights).length !== 0) return
    state.accessRights = await getAccessRights('EDITVERNERUNDE')
  }

  const getSafetyInspections = async () => {
    const url = baseUrl
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success) {
      state.safetyInspectionsList = mapSafetyInspectionList(result.data)
    }
  }

  const getSafetyInspectionsById = async id => {
    const url = `${baseUrl}/${id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.safetyinspection = mapSafetyInspection(result.data)
      return state.safetyinspection
    }
  }

  const saveSafetyInspection = async payload => {
    const url = `${baseUrl}/savesafetyinspection`
    const body = mapSaveSafetyInspectionBody(payload)

    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      body
    )
    if (result.success)
      return (state.safetyinspection = mapSafetyInspection(result.data))
    return null
  }

  const getSafetyInspectionLocations = async () => {
    const url = `${baseUrl}/locations`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.safetyInspectionLocations = result.data
  }

  const saveSafetyInspectionParticipantContact = async DTO => {
    const url = `${baseUrl}/savesafetyinspectioncontactparticipant`
    const result = await requestHandler(httpMethods.POST, url, DTO)
    if (result.success) return result
  }

  const saveSafetyInspectionParticipantEmployee = async DTO => {
    const url = `${baseUrl}/savesafetyinspectionemployeeparticipant`
    const result = await requestHandler(httpMethods.POST, url, DTO)
    if (result.data.success) return result
  }

  const deleteSafetyInspectionById = async id => {
    const url = `${baseUrl}/${id}`
    const result = await requestHandler(httpMethods.DELETE, url)
    if (result.success) removeItemAtIndex(state.safetyInspectionsList, id)
    return result
  }

  const savesafetyInspectionCheckpoint = async payload => {
    const url = `${baseUrl}/checkpoints`
    const body = mapSavesafetyInspectionCheckpointBody(payload)
    const result = await requestHandler(httpMethods.POST, url, body)
    if (result.success) return mapSafetyInspectionCheckpoint(result.data)
    else return null
  }

  const saveSafetyInspectionCategory = async payload => {
    const url = `${baseUrl}/categories`
    const result = await requestHandler(
      httpMethods.POST,
      url,
      addSortIndexIfNotExist(payload)
    )
    return result
  }

  const saveSafetyInspectionCategories = async categories => {
    const url = `${baseUrl}/savesafetyinspection`
    const body = {
      ...store.getters['safetyInspections/safetyInspection'],
      categories: addSortIndexIfNotExist(categories)
    }
    const mappedDataForBackend = mapSaveSafetyInspectionCategoriesBody(body)
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      mappedDataForBackend
    )
    if (result.success)
      result.data = mapSafetyInspectionCategory(result.data.category)
    return result
  }

  const getSafetyInspectionTemplatesOverview = async () => {
    const url = `${baseUrl}/templates/overview`
    const result = await requestHandler(httpMethods.GET, url)
    if (result.success)
      return result.data.map(mapSafetyInspectionTemplateOverview)
    else return []
  }

  const getSafetyInspectionTemplates = async () => {
    const url = `${baseUrl}/templates`
    const result = await requestHandler(httpMethods.GET, url)
    if (result.success) return result.data.map(mapSafetyInspectionTemplate)
    return []
  }

  const getSafetyInspectionTemplatesById = async id => {
    const url = `${baseUrl}/templates/${id}`
    const result = await requestHandler(httpMethods.GET, url)
    if (result.success) return mapSafetyInspectionTemplate(result.data)
    return null
  }

  const createSafetyInspectionTemplates = async payload => {
    const url = `${baseUrl}/templates`
    const body = mapCreateSafetyInspectionTemplatesBody(payload)
    const result = await requestHandler(httpMethods.POST, url, body)
    if (result.success) return mapSafetyInspectionTemplate(result.data)
    return null
  }

  const updateSafetyInspectionTemplates = async (id, payload) => {
    const url = `${baseUrl}/templates/${id}`
    const body = mapUpdateSafetyInspectionTemplatesBody(id, payload)
    const result = await requestHandler(httpMethods.PUT, url, body)
    if (result.success) return mapSafetyInspectionTemplate(result.data)
    return null
  }

  const deleteSafetyInspectionTemplateById = async id => {
    const url = `${baseUrl}/templates/${id}`
    const result = await requestHandler(httpMethods.DELETE, url)
    return result.success
  }

  return {
    state: state,
    setAccessRights,
    getSafetyInspections,
    getSafetyInspectionsById,
    saveSafetyInspection,
    getSafetyInspectionLocations,
    saveSafetyInspectionParticipantContact,
    saveSafetyInspectionParticipantEmployee,
    deleteSafetyInspectionById,
    savesafetyInspectionCheckpoint,
    saveSafetyInspectionCategory,
    getSafetyInspectionTemplates,
    getSafetyInspectionTemplatesById,
    getSafetyInspectionTemplatesOverview,
    createSafetyInspectionTemplates,
    updateSafetyInspectionTemplates,
    deleteSafetyInspectionTemplateById,
    saveSafetyInspectionCategories
  }
}
