export const OWNER_TYPE = {
  VERNERUNDESJEKKPUNKT: 'VERNERUNDESJEKKPUNKT',
  EDITVERNERUNDE: 'EDITVERNERUNDE',
  LOKALE: 'LOKALE',
  HENDELSE: 'HENDELSE',
  EDITKOMPETANSEOVERSIKT_LIST: 'EDITKOMPETANSEOVERSIKT_LIST'
}

export const ACCESS_RIGHTS = {
  READ_WRITE_ACCESS: 'READ_WRITE_ACCESS'
}

export const CONTENT_TYPES = {
  APPLICATION_JSON: 'application/json'
}
