import Vue from 'vue'

import '@hms-kontoret/avonova-digital-icon-font/css/icons.css'
import nucleoIcons from '@hms-kontoret/avonova-digital-icon-font/unicodesMap.json'

import { ClickOutside } from 'vuetify/es5/directives'
import Vuetify from 'vuetify/lib'

import i18n from '@/config/i18n'

import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify, {
  directives: {
    ClickOutside
  }
})
let language = 'no'
if (localStorage.getItem('language')) {
  if (localStorage.getItem('language').includes('-NO')) {
    language = 'no'
  } else if (localStorage.getItem('language').includes('-SE')) {
    language = 'sv'
  } else if (localStorage.getItem('language').includes('-EN')) {
    language = 'en'
  }
} else {
  if (location.hostname !== 'localhost') {
    const site = location.hostname.split('.')
    let siteExtension = site[site.length - 1]
    if (siteExtension === 'se') siteExtension = 'sv'
    language = siteExtension
  }
}
const generateVuetifyFonts = icons => {
  if (!icons || Object.keys(icons).length == 0 || typeof icons != 'object')
    return { 'not': 'found' }
  let values = {}
  for (const [key] of Object.entries(icons)) {
    Object.assign(values, {
      [key]: `${key} nucleo-icon`
    })
  }
  return values
}
export default new Vuetify({
  treeShake: true,
  lang: {
    locales: ['no', 'en', 'se'],
    current: language,
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#028655',
        secondary: '#B4E0D1',
        lightgreen: '#C8E6C9',
        darkgreen: '#04332A',
        accent: '#FFBE83',
        error: '#BB1D1D',
        gray: '#F0F4F3',
        lightgray: '#E0E0E0',
        darkgray: '#828282',
        darkergray: '#444444',
        black: '#111111',
        white: '#FFFFFF',
        red: '#FF0000',
        orange: '#B65F0F',
        avonovaRed: '#BB1D1D',
        avonovaOrange: '#FFBE83',
        green100: '#F4FFFF',
        green200: '#E0FFF9',
        green300: '#AFF0E5',
        green400: '#7EEEDB',
        green500: '#4EE8CC',
        green600: '#27DDBB',
        green700: '#09CCA6',
        green800: '#00AD8C',
        green900: '#00866D',
        green1000: '#005646',
        green1100: '#01332A',
        green1200: '#00211B',
        red100: '#FFECE4',
        red200: '#FFDDD4',
        red300: '#FFBAAE',
        red400: '#FF6C62',
        red500: '#FC4B47',
        red600: '#A72323',
        orange100: '#FFF6EB',
        orange200: '#FFDCAF',
        orange300: '#F9A035',
        orange400: '#FE8800',
        purple100: '#EDDDFF',
        purple200: '#CB9CFF',
        purple300: '#9242EB',
        purple400: '#500A9E',
        neutral100: '#FAF7F6',
        neutral200: '#F5E7E1',
        neutral300: '#DAAA93',
        neutral400: '#B07F68',
        neutral500: '#7D594A',
        neutral600: '#593D34',
        grey100: '#FAFAFA',
        grey200: '#F5F5F5',
        grey300: '#DADADA',
        grey400: '#B0B0B0',
        grey500: '#7D7D7D',
        grey600: '#595959'
      },
      newIdentity: {
        primary: '#01332A',
        secondary: '#27DDBB',
        accent: '#FFDCAF',
        error: '#FC4B47',
        info: '#EDDDFF',
        success: '#005646',
        warning: '#FFBAAE',
        black: '#272727',
        white: '#FFFFFF',
        green100: '#F4FFFF',
        green200: '#E0FFF9',
        green300: '#AFF0E5',
        green400: '#7EEEDB',
        green500: '#4EE8CC',
        green600: '#27DDBB',
        green700: '#09CCA6',
        green800: '#00AD8C',
        green900: '#00866D',
        green1000: '#005646',
        green1100: '#01332A',
        green1200: '#00211B',
        red100: '#FFECE4',
        red200: '#FFDDD4',
        red300: '#FFBAAE',
        red400: '#FF6C62',
        red500: '#FC4B47',
        red600: '#A72323',
        orange100: '#FFF6EB',
        orange200: '#FFDCAF',
        orange300: '#F9A035',
        orange400: '#FE8800',
        purple100: '#EDDDFF',
        purple200: '#CB9CFF',
        purple300: '#9242EB',
        purple400: '#500A9E',
        neutral100: '#FAF7F6',
        neutral200: '#F5E7E1',
        neutral300: '#DAAA93',
        neutral400: '#B07F68',
        neutral500: '#7D594A',
        neutral600: '#593D34',
        grey100: '#FAFAFA',
        grey200: '#F5F5F5',
        grey300: '#DADADA',
        grey400: '#B0B0B0',
        grey500: '#7D7D7D',
        grey600: '#595959'
      }
    }
  },
  icons: {
    iconfont: 'mdi',
    values: { ...generateVuetifyFonts(nucleoIcons) }
  }
})
