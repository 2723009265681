import mediaTypeMap from '@/composable/common/MediaTypeMap.json'

const getFileExtension = function (string) {
  return string?.split('.').pop().toLowerCase() ?? ''
}
const getMediaType = function (filename) {
  const fileExtension = '.'.concat(getFileExtension(filename))
  const mimeType = mediaTypeMap[fileExtension]
  return mimeType || null
}

const isImageValidForPdfMake = filename => {
  const validFileTypes = ['png', 'jpg', 'jpeg']
  return validFileTypes.includes(getFileExtension(filename))
}

const getFileTypeIcon = function (file) {
  const fallback = 'mdi-file-outline'
  const mediaType = getMediaType(file.original_filename)
  if (!mediaType) return fallback
  const specific = {
    'application/pdf': 'mdi-file-pdf-box-outline',
    'application/msword': 'mdi-file-word-outline',
    'application/vnd.ms-excel': 'mdi-file-excel-outline',
    'application/vnd.ms-powerpoint': 'mdi-file-powerpoint-outline',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'mdi-file-word-outline',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'mdi-file-excel-outline',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'mdi-file-powerpoint-outline',
    'application/zip': 'mdi-folder-zip-outline',
    'image/gif': 'mdi-gif'
  }
  const general = {
    'application': 'mdi-file-outline',
    'audio': 'mdi-volume-high',
    'image': 'mdi-image-outline',
    'text': 'mdi-file-document-outline'
  }
  return specific[mediaType] || general[mediaType.split('/')[0]] || fallback
}
const getFileIcon = fileExtension => {
  switch (fileExtension) {
    case 'gif':
      return 'mdi-gif'

    case 'jpg':
    case 'png':
      return 'mdi-image-outline'

    case 'pdf':
      return 'mdi-file-pdf-box-outline'

    case 'xsl':
    case 'xslx':
      return 'mdi-file-excel-outline'

    case 'ppt':
    case 'pptx':
      return 'mdi-file-powerpoint-outline'

    case 'doc':
    case 'docx':
      return 'mdi-file-word-outline'

    case 'zip':
      return 'mdi-folder-zip-outline'

    default:
      return 'mdi-file-outline'
  }
}

export {
  getFileExtension,
  getMediaType,
  getFileTypeIcon,
  getFileIcon,
  isImageValidForPdfMake
}
