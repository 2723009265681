import i18n from '@/config/i18n'

import { eventBus, events } from '@/shared'

export const useSnackbar = (text, type = '') => {
  let color = undefined
  switch (type.toLowerCase()) {
    case 'error':
      color = 'red600'
      break
    case 'information':
      color = 'orange200'
      break
    default:
      color = 'green600'
  }

  eventBus.$emit(events.updateSnackbar, {
    visible: true,
    text: i18n.t(text),
    color: color
  })
}
