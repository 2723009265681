import env from '@/environment'
import * as msal from '@azure/msal-browser'
import axios from 'axios'

import router from '@/router'

import { useLogger } from '@/shared/logger'
import { isSomething } from '@/utilities/conditionalUtilities'
import { deleteCookie, getCookie, setCookie } from '@/utilities/cookieUtilities'

import { REFRESH_TOKEN_TIMEOUT } from '@/constants/sessionFlow'

import CustomNavigationClient from './customNavigationClient'

const MsalService = class AuthService {
  constructor() {
    this.state = {
      tokenClaims: null,
      token: null,
      isProxySession: false
    }

    this.msalConfig = {
      auth: {
        clientId: env.VUE_APP_AZURE_B2C_CLIENT_ID,
        authority: env.VUE_APP_AZURE_B2C_AUTHORITY,
        redirectUri: env.VUE_APP_REDIRECT_URL,
        postLogoutRedirectUri: env.VUE_APP_REDIRECT_URL,
        knownAuthorities: [env.VUE_APP_AZURE_B2C_KNOWNAUTHORITY],
        navigateToLoginRequestUrl: false // Navigate back to the page loginRedirect was called, after login redirects to redirectUri
      },
      cache: {
        cacheLocation: msal.BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false
      },
      system: {
        allowRedirectInIframe: false
      }
    }

    this.app = new msal.PublicClientApplication(this.msalConfig)

    // State is used for redirecting the user to the page they were on before login
    this.loginRequest = {
      scopes: ['openid'],
      state: window.location.pathname + window.location.search
    }

    this.getUserLanguage()
    this.addExtraQueryParameters()

    // Set monolith keep alive listener if the application is not running locally
    if (env.VUE_APP_ENVIRONMENT !== 'local') this.setMonolithKeepAliveListener()

    // Set B2C refresh token listener if the application is not running in proxy
    if (!window.location.host.includes('proxy')) this.setRefreshTokenListener()

    this.logger = useLogger('msal service')

    // This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigationClient = new CustomNavigationClient(router)
    this.app.setNavigationClient(navigationClient)
  }

  getUserLanguage() {
    const urlParams = new URLSearchParams(window.location.search)
    const lang = urlParams.get('lang')
    if (lang !== null) setCookie('lang', lang)
  }

  addExtraQueryParameters() {
    const urlParams = new URLSearchParams(window.location.search)
    if (getCookie('lang') !== null) {
      if (urlParams.has('username')) {
        this.loginRequest['extraQueryParameters'] = {
          lang: getCookie('lang'),
          username: urlParams.get('username')
        }
      } else {
        this.loginRequest['extraQueryParameters'] = {
          lang: getCookie('lang')
        }
      }
    }
  }

  setMonolithKeepAliveListener() {
    let userInteraction
    document.body.addEventListener('mousedown', e => {
      userInteraction = true
    })
    setInterval(
      function () {
        if (userInteraction && this.isAuthenticated()) {
          this.performMonolithOperation('keep-alive').catch(e => e)
        }
        userInteraction = false
      }.bind(this),
      REFRESH_TOKEN_TIMEOUT
    )
  }

  setRefreshTokenListener() {
    // Need to bind this otherwise the context will be lost
    // cannot be explicitly passed to setInterval as it will run immediatly, provoking interactionInProgress error
    setInterval(
      function () {
        return this.acquireB2CToken().catch(e => this.login())
      }.bind(this),
      REFRESH_TOKEN_TIMEOUT
    )
  }

  getLoggedInAccount() {
    return this.app.getAllAccounts()[0]
  }

  isValidUser() {
    // A valid B2C user has all of the following claims, or a proxy session is active
    const { tokenClaims, isProxySession } = this.state
    return (
      isProxySession ||
      (tokenClaims &&
        isSomething(tokenClaims.extension_uid) &&
        isSomething(tokenClaims.extension_kid) &&
        isSomething(tokenClaims.extension_orgnr))
    )
  }

  // Acquire token from cache silently if available (or refresh token available) or fetch from B2C via interaction
  async acquireB2CToken() {
    const accessTokenRequest = {
      scopes: ['openid', env.VUE_APP_AZURE_B2C_CLIENT_ID],
      account: this.getLoggedInAccount(),
      cacheLookupPolicy: msal.CacheLookupPolicy.Default,
      forceRefresh: false
    }
    await this.app
      .acquireTokenSilent(accessTokenRequest)
      .then(response => {
        this.setAuthenticateResponse(response)
      })
      .catch(async error => {
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          this.logger.debug('Could not acquire token silently. Trying redirect')
          await this.app.acquireTokenRedirect(accessTokenRequest)
        } else throw new Error('Could not acquire token silently.')
      })
  }

  async acquireTokenOrLoginRedirect() {
    // check if the app is running in the proxy, if so then set the proxy session to true and return
    if (window.location.host.includes('proxy')) {
      // check if token is present in the cookies
      const token = getCookie('token')
      if (token) this.setProxySession(token)
      else window.close()
    } else {
      // Calls to msal app to check if the session is active, if not redirect to login page
      // if the session is active then it will try to acquire a token silently
      const account = this.getLoggedInAccount()
      if (!account)
        // if there is no account then the user is not logged in and should be redirected to the login page
        await this.app.loginRedirect(this.loginRequest)
      else await this.acquireB2CToken()
    }
  }

  async login() {
    return this.app.loginRedirect(this.loginRequest)
  }

  async logout() {
    // Perform logout operation in monolith via api
    await this.performMonolithOperation('logout')
    deleteCookie('token')
    // close the window if it is proxy
    if (window.location.host.includes('proxy')) {
      window.close()
    } else {
      // logout from B2C
      const account = this.getLoggedInAccount()
      await this.app.logoutRedirect({
        account: account,
        idTokenHint: this.getToken()
      })
    }
  }

  goToMonolithLogin() {
    const { VUE_APP_ENVIRONMENT } = env
    if (VUE_APP_ENVIRONMENT === 'local') return '/'

    return `/login_im.xhtml?token=${this.getToken()}`
  }

  async performMonolithOperation(operationType) {
    const { VUE_APP_ENVIRONMENT, VUE_APP_PROXY_URL } = env
    if (VUE_APP_ENVIRONMENT === 'local') return
    const paths = {
      'login': `/login_im.xhtml?token=${this.getToken()}`,
      'logout': '/logout_im.xhtml',
      'keep-alive': '/keep-alive.xhtml'
    }
    let path = paths[operationType]
    if (this.state.isProxySession)
      path = `${VUE_APP_PROXY_URL}${path.split(['/']).pop()}`
    if (operationType === 'logout') deleteCookie('JSESSIONID')
    return axios({
      url: path,
      method: 'GET',
      headers: {
        'Content-Type': 'application/xhtml+xml',
        'Access-Control-Allow-Origin': '*'
      }
    })
  }
  setClaimsToCookie(response) {
    setCookie('lang', response.idTokenClaims.extension_language)
    // Save the request state (last path before being redirected to login page) in the cookies if it is not equal to the default login redirect path
    const ignoredPaths = ['/index', '/app/login', '/app/logout']
    if (
      window.location.origin + response.state === env.VUE_APP_REDIRECT_URL ||
      ignoredPaths.includes(response.state)
    )
      return

    setCookie('redirect', response.state)
  }

  setAuthenticateResponse(response) {
    this.state.token = response.idToken
    this.state.tokenClaims = response.idTokenClaims
    localStorage.setItem('userOrgNr', response.idTokenClaims.extension_orgnr)
  }

  setProxySession(token) {
    this.state.isProxySession = true
    this.state.token = token
  }

  getToken() {
    return this.state.token
  }

  async isAuthenticated() {
    // Register Callbacks for Redirect flow
    // eslint-disable-next-line no-console
    console.time('isAuthenticated')
    return this.app
      .handleRedirectPromise()
      .then(async response => {
        if (response !== null) {
          // if the response is not null then the app is returning from a login redirect
          //set the response claims in the state and localstorage
          this.setAuthenticateResponse(response)
          // set the required claims in the cookies
          this.setClaimsToCookie(response)
        } else {
          // the app is reloading, acquire token if valid session or login redirect
          await this.acquireTokenOrLoginRedirect()
        }
        if (!this.isValidUser())
          throw new Error(
            'Logged in user is not a valid Avonova Digital user. Logging out...'
          )
        return true
      })
      .catch(async error => {
        console.error(error)
        return false
      })
      .finally(() => {
        console.timeEnd('isAuthenticated') // This also returns the elapsed time in milliseconds
      })
  }
}

export const authService = new MsalService()
