import { INCIDENT_REGISTRY_PATHS } from '@/constants/navigation'

export const PARENT_BREADCRUMBS = [
  {
    text: 'SafetyInspections',
    string: 'menu__item__safety_inspections',
    disabled: false,
    href: INCIDENT_REGISTRY_PATHS.SAFETY_INSPECTIONS
  }
]
