import { computed, reactive, toRefs } from 'vue'

import avonovaAPI from '@/config/axios'
import store from '@/store'

import {
  isSuccessResponse,
  requestFailed,
  requestFinished,
  requestStarted
} from '@/utilities/httpUtilities'
import { i18nSort } from '@/utilities/i18nUtilities'
import { ensureLowerCase } from '@/utilities/stringUtilities'

import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'

const state = reactive({
  employee: {},
  employees: [],
  employeeNameAndIds: [],
  activeEmployeeNameAndIds: [],
  activeEmployeeWithUser: [],
  employeesDepartmentIds: [],
  lastMovedEmployeeIds: [],
  employeesContacts: store?.state.employeesContacts || [],
  employeesByLocationAccess: [],
  currentEmployeeId: undefined,
  employeePositions: [],
  employeePrintInfos: [],
  employeeOverview: [],
  employeeNamesWithDepartments: [],
  activeEmployeesByLocationAndAccess: [],
  deletedEmployees: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

// add to global utils
const refineElementsWithIdsFromIndex = array => {
  if (!Array.isArray(array)) throw new Error('Argument must be an array')
  return array.map((elements, index) => ({
    ...elements,
    id: index
  }))
}

const getEmployeesAndContactsByLocationAccessWithExistingIn = async payload => {
  const url = `hr/api/employee/getEmployeesAndContactsByLocationAccessWithExistingIn`
  const result = await requestHandler(httpMethods.POST, url, payload)
  if (result.success) return result
}

const getEmployeesPrintInfos = async () => {
  const url = `hr/api/employee/getEmployeePrintInfo`
  const result = await requestHandlerWithState(state, httpMethods.GET, url)
  if (result.success) state.employeePrintInfos = result.data
}

const allContactsAndEmployeesWithId = computed(() => {
  let responsibleContacts = []
  responsibleContacts = state.employeesContacts
    .map((elements, index) => ({
      ...elements,
      id: index
    }))
    .filter(
      contact => contact.type === 'EMPLOYEE' || contact.type === 'CONTACT'
    )
  return responsibleContacts
})

export const useEmployeeService = () => {
  const fetchEmployeesByDepartmentIdAsync = async departmentId => {
    state.request = requestStarted()
    const response = await avonovaAPI.get(
      `hr/api/employee/employeesByDepartmentid/${departmentId}`
    )
    state.request = requestFinished()

    if (isSuccessResponse(response)) {
      for (const employee of response.data) {
        const found = state.employees.findIndex(e => e.id === employee.id)
        if (found === -1) {
          state.employees.push(employee)
        } else {
          continue
        }
      }

      const kvs = response.data.map(employee => ({
        employeeId: employee.id,
        departmentId: departmentId
      }))
      for (const kv of kvs) {
        const index = state.employeesDepartmentIds.findIndex(
          item => item.employeeId === kv.employeeId
        )
        if (index > -1) {
          state.employeesDepartmentIds.splice(index, 1)
        }
      }

      state.employeesDepartmentIds.push(...kvs)
    } else {
      state.request = requestFailed()
    }
  }

  const getDeletedEmployees = async () => {
    const url = `/hr/api/employee/getDeletedEmployees`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.deletedEmployees = result.data
  }

  const getEmployeeByIdV2 = async employeeId => {
    const url = `hr/api/employee/getEmployeeV2/${employeeId}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.employee = result.data

    return result
  }

  const moveEmployeeToDepartmentId = (
    employeeId,
    fromDepartmentId,
    toDepartmentId
  ) => {
    const kv = state.employeesDepartmentIds.find(
      item =>
        item.employeeId === employeeId && item.departmentId === fromDepartmentId
    )
    kv.departmentId = toDepartmentId

    state.lastMovedEmployeeIds.splice(0, state.lastMovedEmployeeIds.length)
    state.lastMovedEmployeeIds.push(employeeId)
  }

  const moveMultipleEmployeesToDepartmentId = (
    employeeIds,
    fromDepartmentId,
    toDepartmentId
  ) => {
    const kvs = state.employeesDepartmentIds.filter(
      item =>
        employeeIds.includes(item.employeeId) &&
        item.departmentId === fromDepartmentId
    )
    kvs.forEach(kv => (kv.departmentId = toDepartmentId))

    state.lastMovedEmployeeIds.splice(0, state.lastMovedEmployeeIds.length)
    state.lastMovedEmployeeIds.push(...employeeIds)
  }

  const findEmployeesInDepartmentsByName = async name => {
    const ensuredName = ensureLowerCase(name)

    state.request = requestStarted()
    const response = await avonovaAPI.get(
      `hr/api/employee/employeeByDepartmentSearch/${ensuredName}`
    )
    state.request = requestFinished()

    return response.data.map(item => item.department_id)
  }

  const getAllEmployeeNames = async () => {
    const url = `hr/api/employee/getAllEmployeesNames`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.employeeNameAndIds = result.data
  }

  const getActiveEmployeeNames = async () => {
    const url = `hr/api/employee/getActiveEmployeesNames`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.activeEmployeeNameAndIds = result.data
  }

  const getActiveEmployeeWithUser = async () => {
    const url = `hr/api/employee/getActiveEmployeesWithUser`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.activeEmployeeWithUser = result.data
  }

  const getAllEmployeesAndContacts = async () => {
    const url = `hr/api/employee/getAllEmployeesAndContacts`
    const result = await requestHandler(httpMethods.GET, url)
    if (result.success) state.allEmployeesAndContacts = result.data
  }

  const getCurrentEmployeeId = async userId => {
    const url = `/common/api/users/getliteuser/${userId}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.currentEmployeeId = result.data.employee_id
  }

  const getEmployeesAndContactsByLocationAccessOld =
    async LocationAccessDto => {
      const url = `hr/api/employee/getEmployeesAndContactsByLocationAccess`
      const result = await requestHandlerWithState(
        state,
        httpMethods.POST,
        url,
        LocationAccessDto
      )
      if (result.success) {
        state.employeesContacts = result.data
        store.commit('storeApiResponse', {
          item: [...state.employeesContacts],
          key: 'employeesContacts'
        })
      }
    }

  const getEmployeesAndContactsByLocationAccess = async LocationAccessDto => {
    const url = `hr/api/employee/getEmployeesAndContactsByLocationAccessWithExistingIn`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      LocationAccessDto
    )
    if (result.success) {
      state.employeesContacts = result.data
      store.commit('storeApiResponse', {
        item: [...state.employeesContacts],
        key: 'employeesContacts'
      })
    }
  }

  const getEmployeeById = async employeeId => {
    const url = `/hr/api/employee/getemployee/${employeeId}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.employee = result.data
  }

  const getEmployeesByLocationAccess = async locationAccessDto => {
    const url = `hr/api/employee/getemployeesbylocationaccess`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      locationAccessDto
    )
    if (result.success) state.employeesByLocationAccess = result.data
  }

  const getAllEmployeePositions = async () => {
    const url = '/hr/api/employee/getpositions'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.employeePositions = result.data
  }

  const getEmployeeOverview = async () => {
    const url = '/hr/api/employee/getEmployeeOverview'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.employeeOverview = result.data
  }

  const getAllEmployeeNamesAndDepartmentIds = async () => {
    const url = `/hr/api/employee/getAllEmployeesNamesAndDepartmentId`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success)
      state.employeeNamesWithDepartments = i18nSort(
        result.data,
        item => item.name
      )

    return result
  }

  const getEmployeeSettingsById = async employeeId => {
    const url = `/hr/api/employee/getEmployeeSettings/${employeeId}`
    const result = await requestHandler(httpMethods.GET, url)
    if (result.success)
      return {
        sickChildrenDays: result.data.sick_children_days,
        selfCertifiedAbsenceDays: result.data.self_certified_absence_days,
        numberOfSelfCertifiedAbsence:
          result.data.number_of_self_certified_absence,
        selfCertifiedAbsenceQuarantine:
          result.data.self_certified_absence_quarantine,
        sickChildrenQuarantine: result.data.sick_children_quarantine
      }
  }

  const updateEmployeeSettings = async (settings, employeeId) => {
    const url = `/hr/api/employee/updateEmployeeSettings/`
    return await requestHandler(httpMethods.POST, url, {
      employee_id: employeeId,
      sick_children_days: settings.sickChildrenDays,
      self_certified_absence_days: settings.selfCertifiedAbsenceDays,
      number_of_self_certified_absence: settings.numberOfSelfCertifiedAbsence,
      self_certified_absence_quarantine:
        settings.selfCertifiedAbsenceQuarantine,
      sick_children_quarantine: settings.sickChildrenQuarantine
    })
  }
  const getActiveEmployeesByLocationAndAccess = async ({
    location,
    access
  }) => {
    const url = `/hr/api/employee/getActiveEmployeesByLocationAccess/${location}/${access}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.activeEmployeesByLocationAndAccess = result.data
  }

  return {
    state: toRefs(state),
    fetchEmployeesByDepartmentIdAsync,
    moveEmployeeToDepartmentId,
    moveMultipleEmployeesToDepartmentId,
    findEmployeesInDepartmentsByName,
    getCurrentEmployeeId,
    getAllEmployeeNames,
    getEmployeesAndContactsByLocationAccessOld,
    getEmployeeById,
    getEmployeesByLocationAccess,
    allContactsAndEmployeesWithId,
    getEmployeesAndContactsByLocationAccess,
    getAllEmployeesAndContacts,
    getEmployeesAndContactsByLocationAccessWithExistingIn,
    refineElementsWithIdsFromIndex,
    getAllEmployeePositions,
    getEmployeeOverview,
    getAllEmployeeNamesAndDepartmentIds,
    getEmployeeSettingsById,
    updateEmployeeSettings,
    getActiveEmployeeNames,
    getActiveEmployeeWithUser,
    getEmployeesPrintInfos,
    getEmployeeByIdV2,
    getActiveEmployeesByLocationAndAccess,
    getDeletedEmployees
  }
}
