const serviceRoutes = [
  {
    path: '/services',
    name: 'ServicesIndex',
    // component: () => import(/* webpackChunkName: "ServicesIndex" */ `@/views/services/ServicesIndex.vue`),
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ServicesIndexHero" */ `@/views/serviceOverview/ServicesIndexHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "ServicesIndex" */ `@/views/serviceOverview/ServiceOverviewIndexPage.vue`
        )
    },
    meta: {
      title: 'meta__title__services',
      contentful: {
        category: '1qVJyd7eXFzTHYuAbW2kFq',
        pageHelp: '334feMfYM7X1qLC2x2cmjC'
      },
      breadcrumbs: []
    },
    productionAvailable: true
  }
]

export default serviceRoutes
