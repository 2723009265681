import { EMPLOYEE_PATHS } from '@/constants/navigation'

export function useEmployeeHoursBreadcrumbs() {
  const myHoursBreadcrumbs = {
    parent: [
      {
        text: 'My hours',
        string: 'meta__title__hours',
        href: EMPLOYEE_PATHS.HOURS,
        disabled: false
      }
    ],

    registration: {
      text: 'Registration',
      string: 'meta__title_employee_hour_registration'
    },

    hourAccount: {
      text: 'Hour Account',
      string: 'meta__title_employee_hour_hour_account'
    },

    history: {
      text: 'History',
      string: 'meta__title_employee_hour_history'
    }
  }

  return {
    myHoursBreadcrumbs
  }
}

export const useEmployeeBreadcrumbs = () => {
  const myVacationBreadcrumbs = {
    parent: [
      {
        text: 'My vacation',
        string: 'my-vacation__title',
        href: EMPLOYEE_PATHS.VACATION,
        disabled: false
      }
    ],
    myOverview: {
      text: 'My overview',
      string: 'my-vacation__overview_title'
    },
    myColleagues: {
      text: 'My colleagues',
      string: 'my-vacation__colleagues_title'
    }
  }

  return {
    myVacationBreadcrumbs
  }
}
