import { computed } from 'vue'

import { DateTime as LuxonDateTime, Settings } from 'luxon'

import i18n from '@/config/i18n'
import { mapi18nToISO } from '@/config/i18n/localization'

export const DateTime = computed(() => {
  //returns a new instance when locale changes
  Settings.defaultZone = 'Europe/Oslo'
  Settings.defaultLocale = mapi18nToISO(i18n.locale)
  return LuxonDateTime
})
