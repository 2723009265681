import { ACCOUNT_COUNTRY } from '@/constants/i18n'

export default {
  name: 'absence',
  namespaced: true,
  state: {
    absencePercentages: {
      [ACCOUNT_COUNTRY.NORWAY]: [20, 40, 50, 60, 80, 100],
      [ACCOUNT_COUNTRY.SWEDEN]: [12.5, 25, 50, 75, 100],
      [ACCOUNT_COUNTRY.FINLAND]: [20, 40, 50, 60, 80, 100],
      DEFAULT: [20, 40, 50, 60, 80, 100]
    }
  },
  getters: {
    percentages: (state, getters, rootState, rootGetters) => () => {
      const countryPercentages =
        state.absencePercentages[rootGetters['authUser/accountCountry']] ??
        state.absencePercentages.DEFAULT

      return countryPercentages.map(percentage => {
        return {
          value: percentage,
          text: `${percentage}%`
        }
      })
    }
  }
}
