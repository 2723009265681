import env from '@/environment'

export const useAmberAPI = () => {
  return window['amberAPI'] ?? window.parent['amberAPI']
}

export const dispatchV1RouteChanged = newPath => {
  const routeChangedEvent = new CustomEvent('v1.routeChanged', {
    detail: { newPath }
  })
  document.dispatchEvent(routeChangedEvent)
  parent.document.dispatchEvent(routeChangedEvent)
}

export const registerIframeMessageListener = (router, store) => {
  const messageHandler = event => {
    const { action, payload } = event.data

    switch (action) {
      case 'navigateTo':
        router.push(payload)
        break
      case 'updateLocale':
        store.dispatch('authUser/updateLanguage', payload)
        break
      default:
    }
  }

  window.addEventListener('message', messageHandler)
  window.parent.addEventListener('message', messageHandler)

  // Return a function to remove the event listeners
  // Example usage: const removeListeners = registerIframeMessageListener(router, store)
  // removeListeners()
  return () => {
    window.removeEventListener('message', messageHandler)
    window.parent.removeEventListener('message', messageHandler)
  }
}
