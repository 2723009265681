export const LOGOUT_ROUTE_NAME = 'Logout'
export const LOGIN_ROUTE_NAME = 'Login'

export const MONOLITH_PATH = {
  ONBOARDING: '/onboarding'
}

export const EMPLOYEE_PATHS = {
  EMPLOYEE: '/employee',
  get ABSENCE() {
    return `${this.EMPLOYEE}/absence`
  },
  get COMPETENCE() {
    return `${this.EMPLOYEE}/competence`
  },
  get VACATION() {
    return `${this.EMPLOYEE}/vacation`
  },
  get INCIDENTS() {
    return `${this.EMPLOYEE}/incidents`
  },
  get HOURS() {
    return `${this.EMPLOYEE}/hours`
  },
  get RESPONSE() {
    return `${this.EMPLOYEE}/response`
  }
}

export const EMPLOYER_PATHS = {
  EMPLOYER: '/employer',
  get ABSENCE() {
    return `${this.EMPLOYER}/absence`
  },
  get COMPETENCE() {
    return `${this.EMPLOYER}/competence`
  },
  get VACATION() {
    return `${this.EMPLOYER}/vacation`
  },
  get EMPLOYEE_OVERVIEW() {
    return `${this.EMPLOYER}/employee-overview`
  },
  get RESPONSE() {
    return `${this.EMPLOYER}/response`
  }
}

export const INCIDENT_REGISTRY_PATHS = {
  DASHBOARD: '/startside/dashboards/dashboard_eventregistry.xhtml',
  INCIDENT_REGISTRY: '/incident-registry',
  get SAFETY_INSPECTIONS() {
    return `${this.INCIDENT_REGISTRY}/safety-inspections`
  },
  get ACTION_PLAN() {
    return `${this.INCIDENT_REGISTRY}/action-plan`
  },
  get INCIDENTS() {
    return `${this.INCIDENT_REGISTRY}/incidents`
  },
  get INSPECTIONS() {
    return `${this.INCIDENT_REGISTRY}/inspections`
  }
}

export const ADMIN_TOOLS_PATHS = {
  ADMIN_TOOLS: '/admin-tools',
  get OVERVIEW() {
    return `${this.ADMIN_TOOLS}/overview`
  },
  get SUPPORT_ACCESS() {
    return `${this.ADMIN_TOOLS}/support-access`
  },
  get SUPPORT_USERS() {
    return `${this.ADMIN_TOOLS}/support-users`
  }
}

export const CARD_FILE_PATHS = {
  CARD_FILE: '/card-file',
  get CONTACTS() {
    return `${this.CARD_FILE}/contacts`
  },
  get LAWS() {
    return `${this.CARD_FILE}/laws`
  },
  get AGREEMENTS() {
    return `${this.CARD_FILE}/agreements`
  },
  get FILES() {
    return `${this.CARD_FILE}/files`
  }
}

export const COMPANY_PATHS = {
  COMPANY: '/company',
  get DEPARTMENTS() {
    return `${this.COMPANY}/departments`
  },
  get ORGANIZATIONAL_CHARTS() {
    return `${this.COMPANY}/organizational-charts`
  },
  get COMPANY_INFO() {
    return `${this.COMPANY}/company-info`
  },
  get PREMISES() {
    return `${this.COMPANY}/premises`
  }
}

export const CONTROL_PANEL_PATHS = {
  CONTROL_PANEL: '/control-panel',
  get CUSTOMER_SUPPORT() {
    return `${this.CONTROL_PANEL}/customer-support`
  },
  get AVONOVA_ACCESS() {
    return `${this.CONTROL_PANEL}/avonova-access`
  },
  get ANNUAL_REPORT() {
    return `${this.CONTROL_PANEL}/annual-report`
  }
}

export const RISK_CENTER_PAHTS = {
  RISK_CENTER: '/risk-center',
  get RISK_CRITERIA() {
    return `${this.RISK_CENTER}/risk-criteria`
  },
  get RISK_ASSESSMENT() {
    return `${this.RISK_CENTER}/risk-assessments`
  },
  get CREATE_RISK_CRITERIA() {
    return `${this.RISK_CENTER}/create-risk-criteria`
  }
}
