import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './config/i18n'
import axios from 'axios'
import avonovaAPI from './config/axios'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import ReactiveStorage from 'vue-reactive-localstorage'
import HighchartsVue from 'highcharts-vue'
import Highcharts from './plugins/highcharts.js'
import './styles/app.scss'
import { authService } from './services'
import VueGtm from '@gtm-support/vue2-gtm'
import { localStorageKeys } from './shared/localStorageKeys'
import Sanitizer from './plugins/sanitizer'
import configureSentry from './plugins/sentry'
import env from './environment'

if (env.VUE_APP_ENVIRONMENT !== 'local') configureSentry(router)

Vue.prototype.$AuthService = authService

Vue.use(Sanitizer)
Vue.use(VueMeta)
Vue.use(HighchartsVue, { highcharts: Highcharts, tagName: 'highcharts' })

Vue.use(VueGtm, {
  id: 'GTM-NTKPSR',
  vueRouter: router
})

Vue.use(ReactiveStorage, {
  ...localStorageKeys
})
Vue.config.productionTip = false
Vue.config.performance = true
Vue.prototype.$axios = avonovaAPI
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  watch: {
    '$route': function (routeObj) {
      if (routeObj.path.includes('maintenance')) {
        if (routeObj.params.method !== undefined) {
          this.$store.commit('updateMaintenanceMethod', routeObj.params.method)
        }
      }
    },
    deep: true,
    immediate: true
  }
}).$mount('#app')
