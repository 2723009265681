import { CARD_FILE_PATHS } from '@/constants/navigation.js'

const cardFileRoutes = [
  {
    path: CARD_FILE_PATHS.CONTACTS,
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ContactsHero" */ `@/views/card-file/contacts/components/ContactsHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Contacts" */ `@/views/card-file/contacts/Contacts.vue`
        )
    },
    meta: {
      title: 'meta__title__contacts',
      contentful: {
        category: '4RkOpVzoOrfqulysXKrxJX',
        pageHelp: '2ROJvn6hv8uzCMG3etC9y1'
      },
      breadcrumbs: [
        {
          text: 'Kontakter',
          string: 'contacts__title',
          disabled: true,
          href: '/card-file/contacts'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: CARD_FILE_PATHS.LAWS,
    name: 'Laws',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Laws" */ `@/views/card-file/lawsRegulations/heroes/LawsHeroMain.vue`
        ),
      default: () =>
        /* webpackChunkName: "Laws" */ import(
          `@/views/card-file/lawsRegulations/LawsNewIdentity.vue`
        )
    },
    meta: {
      title: 'meta__title__laws',
      breadcrumbs: [
        {
          text: 'Laws',
          string: 'laws__title_2'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${CARD_FILE_PATHS.LAWS}/new`,
    name: 'NewLaw',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Laws" */ `@/views/card-file/lawsRegulations/heroes/LawsHeroNewLaw.vue`
        ),
      default: () =>
        /* webpackChunkName: "Laws" */ import(
          `@/views/card-file/lawsRegulations/NewLaw.vue`
        )
    },
    meta: {
      title: 'meta__title__laws',
      breadcrumbs: [
        {
          text: 'Laws',
          string: 'laws__title_2'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${CARD_FILE_PATHS.LAWS}/show-law/:id`,
    alias: ['/card-file/laws/showlaw/:id'],
    name: 'ShowLaw',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Laws" */ `@/views/card-file/lawsRegulations/heroes/LawsHeroShowLaw.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Laws" */ `@/views/card-file/lawsRegulations/ShowLaw.vue`
        )
    },
    meta: {
      title: 'meta__title__laws',
      breadcrumbs: [
        {
          text: 'Laws',
          string: 'laws__title_2',
          href: '/card-file/laws'
        },
        {
          text: 'Laws',
          string: 'laws__law'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${CARD_FILE_PATHS.LAWS}/add`,
    name: 'AddLaw',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Laws" */ `@/views/card-file/lawsRegulations/heroes/LawsHeroAddLaw.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Laws" */ `@/views/card-file/lawsRegulations/AddLaw.vue`
        )
    },
    meta: {
      title: 'meta__title__laws',
      breadcrumbs: [
        {
          text: 'Laws',
          string: 'laws__title_2'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: CARD_FILE_PATHS.AGREEMENTS,
    name: 'Agreements',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "AgreementHero" */
          `@/views/card-file/agreements/components/agreementsHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Agreements" */
          `@/views/card-file/agreements/agreements.vue`
        )
    },
    meta: {
      title: 'meta__title__agreements',
      contentful: {
        category: '4RkOpVzoOrfqulysXKrxJX',
        pageHelp: '2ROJvn6hv8uzCMG3etC9y1'
      },
      breadcrumbs: [
        {
          text: 'agreements',
          string: 'agreements__title',
          disabled: true,
          href: '/card-file/agreements'
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${CARD_FILE_PATHS.AGREEMENTS}/edit/:id`,
    name: 'Agreements-edit',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "AgreementHero" */
          `@/views/card-file/agreements/components/edit-agreement/agreementsEditModeHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Agreements" */
          `@/views/card-file/agreements/components/edit-agreement/agreementsEditMode.vue`
        )
    },
    meta: {
      title: 'meta__title__agreements',
      contentful: {
        category: '4RkOpVzoOrfqulysXKrxJX',
        pageHelp: '2ROJvn6hv8uzCMG3etC9y1'
      },
      breadcrumbs: [
        {
          text: 'agreements',
          string: 'agreements__title',
          disabled: false,
          href: '/card-file/agreements'
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: `${CARD_FILE_PATHS.AGREEMENTS}/new`,
    name: 'Agreements-new',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "AgreementHero" */
          `@/views/card-file/agreements/components/new-agreement/agreementsNewAgreementHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Agreements" */
          `@/views/card-file/agreements/components/new-agreement/agreementsNewAgreement.vue`
        )
    },
    meta: {
      title: 'meta__title__agreements',
      contentful: {
        category: '4RkOpVzoOrfqulysXKrxJX',
        pageHelp: '2ROJvn6hv8uzCMG3etC9y1'
      },
      breadcrumbs: [
        {
          text: 'agreements',
          string: 'agreements__title',
          disabled: false,
          href: '/card-file/agreements'
        },
        {
          text: 'agreements new agreement',
          string: 'agreements__new_agreement_title',
          disabled: true,
          href: '/card-file/agreements/new'
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: CARD_FILE_PATHS.FILES,
    params: true,
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "FilesHero" */
          `@/views/card-file/Files/components/FilesHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Files" */
          `@/views/card-file/Files/Files.vue`
        )
    },
    meta: {
      title: 'files_page__title',
      breadcrumbs: [
        {
          text: 'Filer',
          string: 'files_page__title'
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'Files',
        component: () =>
          import(
            /* webpackChunkName: "Files" */ `@/views/card-file/Files/Files.vue`
          ),
        meta: {
          title: 'files_page__title',
          contentful: {
            category: '4RkOpVzoOrfqulysXKrxJX',
            pageHelp: '2ROJvn6hv8uzCMG3etC9y1'
          }
        }
      }
    ],
    productionAvailable: true
  }
]

export default cardFileRoutes
