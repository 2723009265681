import { isSomething } from './conditionalUtilities'

export const ensureNumber = value =>
  isNaN(Number(value)) ? undefined : Number(value)

export const toPercentage = (current, total) => {
  const ensuredCurrent = ensureNumber(current)
  const ensuredTotal = ensureNumber(total)

  return isSomething(ensuredCurrent) && isSomething(ensuredTotal)
    ? (ensuredCurrent / ensuredTotal) * 100
    : undefined
}

export const toFixed = (current, decimals) => {
  const ensurendCurrent = ensureNumber(current)
  const ensuredDecimals = ensureNumber(decimals)

  return isSomething(ensurendCurrent) && isSomething(ensuredDecimals)
    ? ensurendCurrent.toFixed(ensuredDecimals)
    : undefined
}

export const isNumber = value => typeof value === 'number'
