import { default as transitions } from '@/styles/transition.module.scss'

export const newItemTransitionInMs = parseInt(
  transitions.transitionTime.replace(/\D/g, '')
)

export const newItemGracePeriod = 2000

export const snackbarTransitionInMs =
  newItemTransitionInMs + newItemGracePeriod - 200
