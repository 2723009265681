import { decode } from 'html-entities'

import { isEmpty, isNone, isSomething } from './conditionalUtilities'

export const ensureLowerCase = value =>
  isSomething(value) ? value.toLowerCase() : ''

export const stripHtml = (value, separator = '‰') => {
  if (!value) return
  const singleSeparator = new RegExp(separator, 'g')
  const doubleSeparator = new RegExp(`${separator}${separator}`, 'g')

  value = value
    .replace(/<[^>]*>?/gm, separator)
    .replace(doubleSeparator, ' ')
    .replace(singleSeparator, '')

  return decode(value)
}

export const stripDownString = (value, stripQuantity) => {
  let numberToStrip
  if (stripQuantity) {
    numberToStrip = stripQuantity
  } else {
    numberToStrip = 25
  }
  if (isSomething(value) && value.length > numberToStrip) {
    let strippedString
    strippedString = value.slice(0, numberToStrip) + '... '
    return strippedString
  } else {
    return value
  }
}

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

export const stripName = name => {
  let firstName
  let restOfName = ''
  let trimmedName
  if (name.trim().split(' ').length > 1) {
    trimmedName = name.trim().split(' ')
    trimmedName.forEach((name, i) => {
      if (i === 0) {
        firstName = name
      } else {
        restOfName += ' ' + name.slice(0, 1) + '.'
      }
    })
  } else {
    return name
  }

  return `${firstName}${restOfName}`
}

export const stripHtmlEntities = string => decode(string)

export const lazyDecode = value => {
  if (value === null || value === undefined) return value
  let decoded = value
  try {
    decoded = decodeURIComponent(value)
  } catch (error) {
    // Log event to Sentry to get notification about potential naming issues.
  }

  return decoded
}

export const fillEmptyText = text => {
  return isNone(text) || isEmpty(text) ? '-' : text
}

export const removeNewLine = text => {
  return text?.replace(/\n/g, '')
}
