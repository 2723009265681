import { useGtm } from '@gtm-support/vue2-gtm'

// Custom events defined here
// https://docs.google.com/document/d/1b0p0w9eZNmyT0BElkOQN7LmHZX8krmfYZgHKPtI6u4U
export const GTM_EVENT_TYPE = {
  INIT_DATA: 'init_data',
  CLICK: 'click',
  TASK_PROCESS: 'task_process',
  TASK_COMPLETE: 'task_complete',
  SESSION_TIMEOUT: 'session_timeout',
  SEARCH_BAR_INTERACTED: 'search_bar_interacted'
}

// DEPRECATED use gtmTriggerEventV2 instead
export const gtmTriggerEvent = (event, menuCategory, eventDetails, value) => {
  const gtm = useGtm()
  gtm.trackEvent({
    event: event,
    menuCategory: menuCategory,
    eventDetails: eventDetails,
    value: value
  })
}

export const gtmTriggerEventV2 = (
  eventTriggerId,
  event = GTM_EVENT_TYPE.CLICK
) => {
  const gtm = useGtm()
  gtm.trackEvent({
    event: event,
    eventTriggerId: eventTriggerId
  })
}
