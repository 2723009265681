var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column ui-menu"},[_c('div',_vm._g({class:{
      'menu-header-mobile': _vm.legacySmallOrMobile,
      'font-weight-bold': true,
      'menu-header': _vm.legacyBiggerThanMobile
    }},_vm.legacySmallOrMobile ? { click: _vm.expandCollapseMenu } : {}),[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.legacySmallOrMobile),expression:"legacySmallOrMobile"}]},[_vm._v("mdi-chevron-down")]),_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")],1),_c('ul',_vm._l((_vm.data),function(item,index){return _c('li',{key:index,class:{
        'menu-item-mobile': _vm.legacySmallOrMobile
      }},[_c('div',{on:{"click":function($event){return _vm.closeMenu()}}},[_c('FeatureFlag',{attrs:{"flag":_vm.FLAG.USE_IFRAME_MONOLITH},scopedSlots:_vm._u([{key:"fallback",fn:function(){return [(!item.isLegacy)?_c('router-link',{attrs:{"to":{ path: item.url },"title":_vm.$t(item.menuItemText),"id":item.permissionName.toLowerCase()}},[_vm._v(_vm._s(_vm.$t(item.menuItemText))+" ")]):_c('a',{attrs:{"tabindex":"-1","role":"menuitem","href":item.url,"id":item.permissionName.toLowerCase(),"title":_vm.$t(item.menuItemText)}},[_vm._v(" "+_vm._s(_vm.$t(item.menuItemText)))])]},proxy:true}],null,true)},[[(_vm.isExternalLink(item.url))?_c('a',{attrs:{"title":_vm.$t(item.menuItemText),"id":item.permissionName.toLowerCase()},on:{"click":function($event){return _vm.toExternalLink(item.url)}}},[_vm._v(" "+_vm._s(_vm.$t(item.menuItemText))+" ")]):(!item.isLegacy)?_c('router-link',{attrs:{"to":{ path: item.url },"title":_vm.$t(item.menuItemText),"id":item.permissionName.toLowerCase()}},[_vm._v(" "+_vm._s(_vm.$t(item.menuItemText))+" ")]):_c('a',{attrs:{"title":_vm.$t(item.menuItemText),"id":item.permissionName.toLowerCase()},on:{"click":function($event){return _vm.toMonolith(item.url)}}},[_vm._v(" "+_vm._s(_vm.$t(item.menuItemText))+" ")])]],2)],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }