export const DEFAULT_LOCALE = 'nb-NO'
export const ACCOUNT_COUNTRY = {
  NORWAY: 'NO',
  SWEDEN: 'SE',
  FINLAND: 'FI'
}
export const SUPPORTED_LEGACY_LOCALE = {
  NO: 'no',
  SV: 'sv',
  EN: 'en',
  PL: 'pl',
  FI: 'fi'
}
