const mutations = {
  dashboardTooltip(state) {
    state.opened.dashboardTooltip = !state.opened.dashboardTooltip
  },
  toggleMainMenu(state) {
    state.toggleMainMenu = !state.toggleMainMenu
    state.isOverlay = !state.isOverlay
  },
  setTimeRecorderInSession(state, value) {
    state.timeRecorderInSession = value
  },
  setInitData(state, payload) {
    // Mutate menuaccess with home button since it's not delivered by backend
    const getHomeIndex = payload.menuaccess.findIndex(
      home => home === 'MYPAGESCONTAINER'
    )
    payload.menuaccess.splice(getHomeIndex + 1, 0, 'HOME')
    state.initData = payload
    state.initDataFetched = true
  },
  setCompanyLogo(state, companyLogo) {
    state.companyLogo = companyLogo
  },
  setHandbookInfo(state, handbookInfo) {
    state.handbookInfo = handbookInfo
  },
  storeApiResponse(state, { item, key }) {
    //TODO: Do we still use the old *Api.js files?
    state[key] = item
    state.loaded[key] = true
  },
  storeCompanyInfo(state, companyInfo) {
    state.companyInfo.push(companyInfo)
  },
  setHelpFieldId(state, id) {
    state.help.activeTab = 0
    state.help.fieldEntryId = id
  },
  newNotification(state, notification) {
    state.notifications.push(notification)
  },
  storeTemplateAccountType(state, isTemplateAccount) {
    state.accountType.template = isTemplateAccount
  },
  storeCorporateAccountType(state, isCorporateAccount) {
    state.accountType.template = isCorporateAccount
  },
  storeMultiUserAccountType(state, isMultiUserAccount) {
    state.accountType.template = isMultiUserAccount
  },
  updateMaintenanceMethod(state, newMethod) {
    state.maintenanceMethod = newMethod
  },
  setAppLoaded(state, status) {
    state.loaded.app = status
  }
}
export default mutations
