export const companyDataDto = {
  id: undefined,
  lastChanged: undefined,
  createdAt: undefined,
  organizationNumber: 0,
  kamEmail: '',
  status: 'NEW',
  year: 0,

  document: {
    uiState: {
      hasAssistedCustomer: true,
      isPlanMade: false
    },
    year: '',
    companyName: '',
    createdBy: {
      userAccountId: undefined,
      name: undefined,
      username: undefined
    },
    modifiedBy: {
      userAccountId: undefined,
      name: undefined,
      username: undefined
    },
    leader: [],
    contactPerson: [],
    safetyRepresentative: [],
    keyAccountManager: [{ name: '', email: '' }],
    introduction: '',
    activitiesIntroduction: {
      helpType: undefined,
      summaryText: ''
    },
    activitiesTable: {
      activities: [],
      extraActivities: [],
      selectedExtraActivities: []
    },
    recommendedActivities: {
      selectedActivityExamples: [],
      introduction: '',
      longExamples: [],
      shortExamples: [],
      bulletPoints: []
    },
    conclusion: {
      conclusionSummaryText: '',
      closingText: ''
    }
  }
}
