import sanitizeHtml from 'sanitize-html'

const Sanitizer = {
  install(Vue, options) {
    const defaultOptions = options

    Vue.prototype.$sanitize = (dirty, opts = null) =>
      sanitizeHtml(dirty, opts || defaultOptions)
  },

  defaults: sanitizeHtml.defaults
}

export default Sanitizer
