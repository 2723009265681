import Vue from 'vue'

import Vuex from 'vuex'

import absence from './absence'
import actions from './actions'
import authUser from './authUser'
import featureFlags from './featureFlags'
import getters from './getters'
import mutations from './mutations'
import safetyInspections from './safetyInspections'
import state from './state'

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: { featureFlags, absence, authUser, safetyInspections }
})
export default store
export const useStore = () => store
