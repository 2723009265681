import { reactive, set, toRefs } from 'vue'

import {
  notInArray,
  removeItemAtIndex,
  replaceItem,
  toObject
} from '@/utilities/arrayUtilities'
import { hasItems } from '@/utilities/conditionalUtilities'
import { addNewItem } from '@/utilities/table'

import { useFilesService } from './files-service'
import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'

const state = reactive({
  employerCompetencies: {},
  competence: {},
  competenceTypes: [],
  performedYears: [],
  plannedYears: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

export const useEmployerCompetenceService = () => {
  const getCompetenceFileCount = async (ownerType, ids) => {
    if (!hasItems(ids)) return

    const { getFileCountsByOwnerIds } = useFilesService()
    const fileCounts = await getFileCountsByOwnerIds(ids, ownerType)
    return toObject(fileCounts, item => item.id)
  }

  const getEmployerCompetencies = async () => {
    const url = `/hr/api/competence/`

    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    const confirmedIds = result.data.filter(d => d.confirmed).map(d => d.id)
    const pendingIds = result.data.filter(d => !d.confirmed).map(d => d.id)
    const confirmedFileCount = await getCompetenceFileCount(
      'INDEPENDENT_COMPETENCE',
      confirmedIds
    )

    const pendingFileCount = await getCompetenceFileCount(
      'MY_COMPETENCE',
      pendingIds
    )

    result.data.forEach(competence => {
      const competenceWithFileCount = {
        ...competence,
        fileCount: competence.confirmed
          ? confirmedFileCount[competence.id].count
          : pendingFileCount[competence.id].count
      }

      const competenceArray = state.employerCompetencies[competence.employeeId]

      if (Array.isArray(competenceArray)) {
        if (notInArray(competenceArray, competence, 'id')) {
          competenceArray.push(competenceWithFileCount)
        }
      } else {
        set(state.employerCompetencies, competence.employeeId, [
          competenceWithFileCount
        ])
      }
    })
  }

  const getCompetencePerformedYears = async () => {
    const url = `/hr/api/competence/filter/competencePerformedYears`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success) state.performedYears = result.data
  }

  const getCompetencePlannedYears = async () => {
    const url = `/hr/api/competence/filter/competencePlannedYears`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)

    if (result.success) state.plannedYears = result.data
  }

  const createCompetence = async (data, fileCount) => {
    const url = `/hr/api/competence/addCompetences`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )

    if (result.success) {
      if (Array.isArray(result.data)) {
        const resultWithFileCounts = result.data.map(e => {
          return { ...e, fileCount }
        })
        resultWithFileCounts.forEach(competence => {
          addNewItem(
            competence,
            state.employerCompetencies[competence.employeeId]
          )
        })
      }
    }

    return result
  }

  const updateCompetence = async (data, fileCount) => {
    const url = 'hr/api/competence/updateCompetence'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )
    let replacedIndex = null

    if (result.success) {
      replacedIndex = replaceItem(
        state.employerCompetencies[data.employee_id],
        competence => competence.id === result.data.id,
        { ...result.data, newItem: true, fileCount }
      )
    }

    return { ...result, index: replacedIndex }
  }

  const updateMyCompetence = async (data, fileCount) => {
    const url = 'hr/api/competence/updateMyCompetence'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )
    let replacedIndex = null

    if (result.success) {
      replacedIndex = replaceItem(
        state.employerCompetencies[data.employee_id],
        competence => competence.id === result.data.id,
        { ...result.data, newItem: true, fileCount }
      )
    }

    return { ...result, index: replacedIndex }
  }

  const getCompetenceById = async id => {
    const url = `hr/api/competence/competenceById/${id}`
    const result = await requestHandler(httpMethods.GET, url)
    state.competence = result.data
    return result.data
  }

  const getCompetenceToBeConfirmedById = async id => {
    const url = `hr/api/competence/competenceToBeConfirmed/${id}`
    const result = await requestHandler(httpMethods.GET, url)
    state.competence = result.data
    return result.data
  }

  const deleteCompetenceById = async (id, employeeId) => {
    const url = `/hr/api/competence/${id}`
    const result = await requestHandlerWithState(state, httpMethods.DELETE, url)
    if (result.success)
      removeItemAtIndex(state.employerCompetencies[employeeId], id)

    return result
  }

  const confirmCompetenceById = async item => {
    const id = item.id
    const url = `hr/api/competence/approveCompetenciesToBeConfirmed`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      ids: [id]
    })

    if (result.success) {
      const recievedCompetence = result.data[0]
      replaceItem(
        state.employerCompetencies[item.employeeId],
        competence => competence.id === id,
        { ...recievedCompetence, newItem: true, fileCount: item.fileCount }
      )
    }

    return result
  }

  const declineCompetenceById = async (id, employeeId) => {
    const url = `hr/api/competence/declineCompetenceToBeConfirmed/${id}`
    const result = await requestHandlerWithState(state, httpMethods.DELETE, url)

    if (result.success)
      removeItemAtIndex(state.employerCompetencies[employeeId], id)

    return result
  }

  return {
    state: toRefs(state),
    getEmployerCompetencies,
    createCompetence,
    getCompetenceFileCount,
    updateCompetence,
    updateMyCompetence,
    getCompetenceById,
    deleteCompetenceById,
    confirmCompetenceById,
    declineCompetenceById,
    getCompetencePerformedYears,
    getCompetencePlannedYears,
    getCompetenceToBeConfirmedById
  }
}
