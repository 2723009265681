export const responsiveFunctions = () => {
  function hasWidth(vuetify) {
    return vuetify.breakpoint.width
  }

  function isMobile(vuetify) {
    return vuetify.breakpoint.xs
  }

  function isTablet(vuetify) {
    return vuetify.breakpoint.sm
  }

  function legacySmallOrMobile() {
    return window.matchMedia('(max-width: 768px)').matches
  }

  function legacyBiggerThanMobile() {
    return window.matchMedia('(min-width: 768px)').matches
  }

  return {
    isMobile,
    isTablet,
    hasWidth,
    legacySmallOrMobile,
    legacyBiggerThanMobile
  }
}
