import { Duration, Interval } from 'luxon'

import i18n from '@/config/i18n'

import { DateTime } from '@/composable/luxon/luxon'
import { capitalize } from '@/utilities/stringUtilities.js'

import { addUniquesToArray, distinct, range } from './arrayUtilities'
import { isNone, isSomething } from './conditionalUtilities'

export const ensureDate = value => {
  if (typeof value === 'number') {
    return new Date(value)
  }

  if (typeof value === 'string') {
    return new Date(value)
  }

  if (Object.prototype.toString.call(value) === '[object Date]') {
    return new Date(value)
  }
}

//Deprecated, should instead use dateFormat going forward
export const localizeDate = value => {
  const date = DateTime.value.fromSQL(value) // value is a SQL dateTime string in norwegian timezone
  return dateFormat(date)
}

export const now = () => DateTime.value.now()

export const tryParseToDateTime = dateTimeOrStr => {
  if (dateTimeOrStr == null || dateTimeOrStr.length === 0) return null
  // Return immediately if input is already a DateTime object and is valid
  if (DateTime.value.isDateTime(dateTimeOrStr))
    return dateTimeOrStr.isValid ? dateTimeOrStr : null

  // Function to try parsing with a specific format
  const tryParseWithFormat = (input, format) => {
    const datetime = DateTime.value?.fromFormat(input, format)
    if (datetime?.isValid) return datetime
    return null
  }

  // Because of legacy code, we need to try parsing with multiple formats
  const formats = [
    'dd.MM.yyyy',
    'dd/MM/yyyy',
    'dd-MM-yyyy',
    'yyyy.MM.dd',
    'yyyy/MM/dd',
    'yyyy-MM-dd'
  ]

  // Try parsing with each format
  for (const format of formats) {
    const datetime = tryParseWithFormat(dateTimeOrStr, format)
    if (datetime) return datetime
  }

  // Try ISO format as a fallback
  const isoDate = DateTime.value.fromISO(dateTimeOrStr)
  return isoDate?.isValid ? isoDate : null
}

export const dateFormat = (
  dateTimeOrStr,
  format = DateTime.value.DATE_SHORT
) => {
  let customFormat = format
  // We want to force the day and month to be 2 digits for short format
  // for all the locales
  if (
    format === DateTime.value.DATE_SHORT ||
    format === DateTime.value.DATETIME_SHORT
  )
    customFormat = { ...format, day: '2-digit', month: '2-digit' }
  const date = tryParseToDateTime(dateTimeOrStr)
  return date ? date.toLocaleString(customFormat).replace(',', '') : null
}

//Deprecated, should instead use dateFormat going forward
export const formatDate = (value, withTime) => {
  const date = DateTime.value.fromSQL(value) // value is a SQL dateTime string in norwegian timezone
  return dateFormat(date, withTime ? DateTime.value.DATETIME_SHORT : undefined)
}

//Deprecated, should instead use dateFormat going forward
function formatDateEN(date) {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

const isValidDate = value => {
  return tryParseToDateTime(value) != null
}

export const todaysDayName = () => DateTime.value.now().toFormat('cccc')

export const getYearFromISODate = date => DateTime.value.fromISO(date).year

export const todaysDayNameAndDate = () =>
  `${todaysDayName()}, ${dateFormat(
    getTodaysDate(),
    DateTime.value.DATE_SHORT
  )}`

export const lastTwo = value => value.slice(-2)

export const getNameOfWeekDayFromIsoDate = date => {
  return DateTime.value.fromISO(date).weekdayLong
}

export const getDay = value => {
  const weekdays = [
    'common__sunday',
    'common__monday',
    'common__tuesday',
    'common__wednesday',
    'common__thursday',
    'common__friday',
    'common__saturday'
  ]
  return weekdays[new Date(value).getDay()]
}

export const getDayAbbreviation = value => {
  const weekdaysAbbreviation = [
    'common__sunday_abbreviation',
    'common__monday_abbreviation',
    'common__tuesday_abbreviation',
    'common__wednesday_abbreviation',
    'common__thursday_abbreviation',
    'common__friday_abbreviation',
    'common__saturday_abbreviation'
  ]
  return weekdaysAbbreviation[new Date(value).getDay()]
}

export const getDayShorterAbbreviation = value => {
  const weekdaysShorterAbbreviation = [
    'common__sunday_shorter_abbreviation',
    'common__monday_shorter_abbreviation',
    'common__tuesday_shorter_abbreviation',
    'common__wednesday_shorter_abbreviation',
    'common__thursday_shorter_abbreviation',
    'common__friday_shorter_abbreviation',
    'common__saturday_shorter_abbreviation'
  ]
  return weekdaysShorterAbbreviation[new Date(value).getDay()]
}

export const getContentfulKeyMonth = value => {
  // value is  a number between 0-11
  const months = [
    'common__january',
    'common__february',
    'common__march',
    'common__april',
    'common__may',
    'common__june',
    'common__july',
    'common__august',
    'common__september',
    'common__october',
    'common__november',
    'common__december'
  ]
  return months[value]
}
export const getContentfulKeyMonthAbbreviation = value => {
  // value is  a number between 0-11
  const months = [
    'common__january_abbreviation',
    'common__february_abbreviation',
    'common__march_abbreviation',
    'common__april_abbreviation',
    'common__may_abbreviation',
    'common__june_abbreviation',
    'common__july_abbreviation',
    'common__august_abbreviation',
    'common__september_abbreviation',
    'common__october_abbreviation',
    'common__november_abbreviation',
    'common__december_abbreviation'
  ]
  return months[value]
}

export const generateArrayOfYears = (startAt, count, ascending = false) =>
  ascending ? range(startAt, count, 'asc') : range(startAt, count, 'desc')

export { isValidDate, formatDateEN }

export const getWeekNumber = value =>
  DateTime.value.fromJSDate(ensureDate(value)).weekNumber

export const getDaysInSpecificWeek = (year, weekNumber) => {
  const date = DateTime.value.fromObject({
    weekYear: year,
    weekNumber: weekNumber
  })

  const dateFromStr = date.startOf('week')

  const dateToStr = date.endOf('week')

  return {
    from: dateFromStr.toISODate(),
    to: dateToStr.toISODate()
  }
}

export const months = [
  { value: 0, text: 'common__january', name: 'january' },
  { value: 1, text: 'common__february', name: 'february' },
  { value: 2, text: 'common__march', name: 'march' },
  { value: 3, text: 'common__april', name: 'april' },
  { value: 4, text: 'common__may', name: 'may' },
  { value: 5, text: 'common__june', name: 'june' },
  { value: 6, text: 'common__july', name: 'july' },
  { value: 7, text: 'common__august', name: 'august' },
  { value: 8, text: 'common__september', name: 'september' },
  { value: 9, text: 'common__october', name: 'october' },
  { value: 10, text: 'common__november', name: 'november' },
  { value: 11, text: 'common__december', name: 'december' }
]

export const getWeeksInYear = year => {
  return DateTime.value.local(year).weeksInWeekYear
}

export const getFirstWeekOfYear = year => {
  return DateTime.value.local(year, 1, 1).weekNumber
}

export const getLastWeekOfYear = year => {
  return DateTime.value.local(year, 12, 31).weekNumber
}

export const stripYearAndMonth = date => {
  let onlyDate = date.slice(8, 10)
  if (onlyDate[0] === '0') {
    return onlyDate.slice(1, 2)
  } else {
    return onlyDate
  }
}

export const getTodaysDate = () => DateTime.value.now().toISODate()

export const getISODate = date => DateTime.value.fromISO(date).toISODate()

export const toLocalDateTime = (isoDate, isoTimeShort) => {
  if (isSomething(isoDate) && isSomething(isoTimeShort)) {
    // follows the Java.LocalDateTime used in backend
    // isoDate -> 2021-12-12 && isoTimeShort -> 08:00
    return DateTime.value
      .fromObject({
        year: isoDate.split('-')[0],
        month: isoDate.split('-')[1],
        day: isoDate.split('-')[2],
        hour: isoTimeShort.split(':')[0],
        minute: isoTimeShort.split(':')[1]
      })
      .toFormat("yyyy-MM-dd'T'HH:mm:ss")
  }
}

export const toStartOfYear = currentYear => {
  return DateTime.value.local(currentYear).startOf('year').toISODate()
}

export const timeFromIsoDate = date => {
  return DateTime.value.fromISO(date).toFormat('HH:mm')
}

export const toEndOfYear = currentYear => {
  return DateTime.value.local(currentYear).endOf('year').toISODate()
}

export const toShortDayWithDate = date => {
  let dateString = DateTime.value.fromISO(date).toLocaleString({
    weekday: 'short',
    day: '2-digit',
    month: '2-digit'
  })
  if (dateString[dateString.length - 1] === '.')
    dateString = dateString.slice(0, -1)

  return capitalize(dateString)
}

export const toShortDay = date => {
  const weekDay = DateTime.value
    .fromISO(date)
    // https://moment.github.io/luxon/#/formatting?id=table-of-tokens
    .toFormat('ccc d.')

  return weekDay.toLowerCase()
}

export const toShortMonth = date => {
  return DateTime.value.fromISO(date).toLocaleString({
    month: 'short'
  })
}

export const toHoursMinutes = ISOTime => {
  return DateTime.value.fromISO(ISOTime).toLocaleString({
    hour: '2-digit',
    minute: '2-digit'
  })
}

export const getLastDateOfThisYear = () =>
  localizeDate(`${DateTime.value.local().year}-12-31`)

export const getLastDateOfThisYearEn = () =>
  `${DateTime.value.local().year}-12-31`

export const fromISOToLocaleString = date => {
  if (isSomething(date)) return DateTime.value.fromISO(date).toLocaleString()
}

const getHoursObject = (hours, minutes) => {
  if (isSomething(hours) && isSomething(minutes))
    return {
      hours: hours,
      minutes: minutes
    }
  if (isSomething(hours)) return { hours: hours }
  if (isSomething(minutes)) return { minutes: minutes }
}

export const hoursToDuration = hoursNumber => {
  const isNegativeNumber = Math.sign(hoursNumber) === -1
  const number = Math.abs(hoursNumber)
  const [hours, decimals] = number.toString().split('.')

  let realDecimal = Math.round(
    decimals?.length !== 0 ? 60 * parseFloat('0.' + decimals) : null
  )
  const duration = Duration.fromObject(
    getHoursObject(parseInt(hours), parseInt(realDecimal))
  )
  if (isNegativeNumber)
    return `-${duration.toISOTime({ suppressSeconds: true })}`
  return duration.toISOTime({ suppressSeconds: true })
}

export const durationToHoursAndMinutes = duration => {
  if (isNone(duration)) return ''
  if (!duration.includes(':')) return duration
  const isNegative = duration.includes('-')
  const noneNegativeDuration = isNegative ? duration.split('-')[1] : duration

  // duration: 01:20 -> 1 h 20 m
  const minuteAbbreviation = i18n.t('common__time__minutes_m').toLowerCase()
  const hourAbbreviaition = i18n.t('common__time__hours_h').toLowerCase()

  const [hour, minute] = noneNegativeDuration.split(':')

  //Formats minute and hour to remove first place zeros 01 -> 1
  const formattedHour = hour.split('')[0] === '0' ? hour.split('')[1] : hour
  const formattedMinute =
    minute.split('')[0] === '0' ? minute.split('')[1] : minute

  //nulls the string if the split number is 00
  const hourString =
    hour !== '00' ? `${formattedHour} ${hourAbbreviaition}` : null
  const minuteString =
    minute !== '00' ? `${formattedMinute} ${minuteAbbreviation}` : null

  let string = ''
  if (isNone(hourString) && isNone(minuteString))
    return `0 ${hourAbbreviaition}`
  if (hourString && minuteString)
    string = `${formattedHour} ${hourAbbreviaition} ${formattedMinute} ${minuteAbbreviation}`
  else if (minuteString) string = minuteString
  else if (hourString) string = hourString
  if (isNegative) string = '-' + string
  return string
}

export const datePlaceholder = locale => {
  const placeholder = {
    'nb-NO': 'DD.MM.ÅÅÅÅ',
    'en-GB': 'DD/MM/YYYY',
    'sv-SE': 'YYYY-MM-DD',
    'pl-PL': 'DD.MM.RRRR',
    'fi-FI': 'PP.KK.VVVV'
  }
  return placeholder[locale] || 'DD/MM/YYYY'
}

export const howManyMonthsFromDate = date => {
  if (isNone(date)) return
  const dateDiff = DateTime.value.fromISO(date).diffNow('months')
  return dateDiff.values.months
}

export const howManyDaysFromDate = date => {
  if (isNone(date)) return
  const dateDiff = DateTime.value.fromISO(date).diffNow('days')
  return dateDiff.values.days
}

export const addMonthsToDate = (date, monthsToAdd) => {
  const newDuration = DateTime.value
    .fromISO(date)
    .plus({ months: monthsToAdd })
    .toISODate()

  return newDuration
}

export const subtractMonthsToDate = (date, monthsToSubtract) => {
  const newDuration = DateTime.value
    .fromISO(date)
    .minus({ months: monthsToSubtract })
    .toISODate()
  return newDuration
}

export const getCurrentYear = () => {
  return DateTime.value.now().get('year')
}

export const getCurrentMonthIndex = () => {
  //January === 1
  return DateTime.value.now().get('month')
}

export const getCurrentWeek = () => {
  return DateTime.value.now().weekNumber
}

export const localizeDatesInObject = object => {
  if (isNone(object)) return
  const transformedObject = {}
  Object.keys(object).forEach(property => {
    if (DateTime.value.fromISO(object[property]).invalid === null) {
      transformedObject[property] = localizeDate(object[property])
    } else {
      transformedObject[property] = object[property]
    }
  })

  return transformedObject
}

export const generateISODatesFromRange = (fromDate, toDate) => {
  const start = DateTime.value.fromISO(fromDate).startOf('day')
  const end = DateTime.value.fromISO(toDate).endOf('day')
  return Interval.fromDateTimes(start, end)
    .splitBy({ day: 1 })
    .map(d => d.start.toISODate())
}

export const getListOfMonthsWithWeeksFromRange = (fromDate, toDate) => {
  // Generates a list of months with weeks and days & each day contains an index
  // 2023-01-01 & 2023-01-02 ->
  // [{monthNumber: 1: listOfWeeksInMonth: [{weekNumber: 52, listOfDatesInWeeks: [{date: 2000, weekNumber: 52, index: 0}]},{weekNumber: 1, listOfDatesInWeek:[{date: 2023-01-02, weekNumber: 1, index: 1}]}] }]
  if (isNone(fromDate) || isNone(toDate)) return []
  const start = DateTime.value.fromISO(fromDate).startOf('day')
  const end = DateTime.value.fromISO(toDate).endOf('day')
  const range = Interval.fromDateTimes(start, end)
  const rangeSplitByDays = range.splitBy({ day: 1 })
  rangeSplitByDays.forEach((day, index) => {
    day.index = index
  })

  const rangeOfMonthsWithWeeksContainingDays = []

  const getDaysConnectedToMonth = (rangeSplitByDays, month) => {
    return rangeSplitByDays
      .filter(day => day.start.month === month)
      .map(day => {
        return {
          date: day.start.toISODate(),
          weekNumber: day.start.weekNumber,
          index: day.index
        }
      })
  }

  const getListOfDaysInWeek = (days, weekNumber) => {
    return days
      .filter(day => day.weekNumber === weekNumber)
      .map(day => {
        return { date: day.date, weekNumber, index: day.index }
      })
  }

  const getListOfWeeksInMonth = days => {
    return days.map(day => {
      return {
        weekNumber: day.weekNumber,
        listOfDatesInWeek: getListOfDaysInWeek(days, day.weekNumber)
      }
    })
  }

  addUniquesToArray(
    rangeOfMonthsWithWeeksContainingDays,
    rangeSplitByDays.map(month => {
      const daysFromMonth = getDaysConnectedToMonth(
        rangeSplitByDays,
        month.start.month
      )
      const monthNumber = month.start.month

      return {
        monthNumber,
        listOfWeeksInMonth: distinct(
          getListOfWeeksInMonth(daysFromMonth),
          week => week.weekNumber
        )
      }
    }),
    'monthNumber'
  )

  return rangeOfMonthsWithWeeksContainingDays
}

export const getListOfWeeksInCurrentYear = year => {
  const start = DateTime.value.fromObject({ year }).startOf('year')
  const end = DateTime.value.fromObject({ year }).endOf('year')

  return Interval.fromDateTimes(start, end)
    .splitBy({ week: 1 })
    .map((e, i) => {
      const startOfWeek = e.start.startOf('week')
      const endOfWeek = e.start.endOf('week')
      return {
        week: e.start.weekNumber,
        months: [startOfWeek.month, endOfWeek.month],
        listOfDatesInWeek: Interval.fromDateTimes(startOfWeek, endOfWeek)
          .splitBy({ day: 1 })
          .map(e => e.start.toISODate())
      }
    })
}

export const getFirstAndLastDateOfMonth = (year, month) => {
  const start = DateTime.value
    .fromObject({ year, month })
    .startOf('month')
    .toISODate()
  const end = DateTime.value
    .fromObject({ year, month })
    .endOf('month')
    .toISODate()

  return [start, end]
}

export const getFirstAndLastDateOfYear = year => {
  const start = DateTime.value.fromObject({ year }).startOf('year').toISODate()
  const end = DateTime.value.fromObject({ year }).endOf('year').toISODate()

  return [start, end]
}

export const getFirstHalfOfYear = year => {
  const start = DateTime.value.fromObject({ year }).startOf('year').toISODate()
  const end = DateTime.value
    .fromObject({ year, month: 6 })
    .endOf('month')
    .toISODate()

  return [start, end]
}

export const getSecondHalfOfYear = year => {
  const start = DateTime.value
    .fromObject({ year, month: 7 })
    .startOf('month')
    .toISODate()
  const end = DateTime.value.fromObject({ year }).endOf('year').toISODate()

  return [start, end]
}

export const getFirstQuarterOfYear = year => {
  const start = DateTime.value.fromObject({ year }).startOf('year').toISODate()
  const end = DateTime.value
    .fromObject({ year, month: 3 })
    .endOf('month')
    .toISODate()

  return [start, end]
}

export const getSecondQuarterOfYear = year => {
  const start = DateTime.value
    .fromObject({ year, month: 4 })
    .startOf('month')
    .toISODate()
  const end = DateTime.value
    .fromObject({ year, month: 6 })
    .endOf('month')
    .toISODate()

  return [start, end]
}

export const getThirdQuarterOfYear = year => {
  const start = DateTime.value
    .fromObject({ year, month: 7 })
    .startOf('month')
    .toISODate()
  const end = DateTime.value
    .fromObject({ year, month: 9 })
    .endOf('month')
    .toISODate()

  return [start, end]
}

export const getFourthQuarterOfYear = year => {
  const start = DateTime.value
    .fromObject({ year, month: 10 })
    .startOf('month')
    .toISODate()
  const end = DateTime.value.fromObject({ year }).endOf('year').toISODate()

  return [start, end]
}

export const toLocalTimezone = (dateString, withTime = true) => {
  const date = DateTime.value.fromISO(dateString)
  return withTime ? date.toISO() : date.toISODate()
}
export const toUTCTimezone = (dateString, withTime = true) => {
  const date = DateTime.value.fromISO(dateString).toUTC()
  return withTime ? date.toISO() : date.toISODate()
}

export const isDateInMonth = (dateString, luxonIndexedMonth) => {
  const date = DateTime.value.fromISO(dateString)
  return date.month === luxonIndexedMonth
}

export const isBefore = (date, dateToCompare) => {
  date = tryParseToDateTime(date)
  dateToCompare = tryParseToDateTime(dateToCompare)
  if (date == null || dateToCompare == null) return false
  return date?.ts < dateToCompare?.ts
}

export const isDateRangeOverlappingInterval = (
  fromISOStringOverlapping,
  toISOStringOverlapping,
  fromInterval,
  toInterval
) => {
  // fromInterval and toInterval form an interval that we check if it overlaps with an interval for a given interval.
  const firstOfInterval = tryParseToDateTime(fromInterval).startOf('minute')
  const lastOfInterval = tryParseToDateTime(toInterval).endOf('minute')
  // Disregard time part of the isoStrings, as it is meant to be used with ISO8601 with date, time and year
  // Start and End of date is implemented to cover case where interval starts and ends at the same time.
  const firstOfDateRange = DateTime.value
    .fromISO(fromISOStringOverlapping)
    .startOf('minute')
  const lastOfDateRange = DateTime.value
    .fromISO(toISOStringOverlapping)
    .endOf('minute')
  if (isBefore(lastOfInterval, firstOfInterval))
    throw new Error('End of interval is before start of interval')
  if (isBefore(lastOfDateRange, firstOfDateRange))
    throw new Error('End of date range is before start of date range')
  else {
    const interval = Interval.fromDateTimes(firstOfInterval, lastOfInterval)
    const overlappingInterval = Interval.fromDateTimes(
      firstOfDateRange,
      lastOfDateRange
    )
    return overlappingInterval.overlaps(interval)
  }
}
