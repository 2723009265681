import { authService } from '@/services'
import { useLogger } from '@/shared/logger'

export const useUserService = () => {
  const logger = useLogger('user-service')

  const getCurrentUser = () => {
    const { name, username, localAccountId } = authService.getLoggedInAccount()
    return {
      name,
      username,
      userAccountId: localAccountId
    }
  }
  return {
    getCurrentUser
  }
}
