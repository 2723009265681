import avonovaAPI from '@/config/axios'
import store from '@/store'

export const accountSettingsAPI = () => {
  function toggleDashboard(setting) {
    return avonovaAPI
      .post('/api/accounts/toggledashboard/', { data: setting })
      .then(response => {
        if (response.status == 200) {
          store.commit('dashboardTooltip')
        }
      })
      .catch(error => error)
  }

  const getRoles = async () => {
    return await avonovaAPI
      .get('/hse/api/measures/getallalertroletypesformeasurepush')
      .catch(e => e)
  }

  const assignMeasureToSubAccount = async measure => {
    return await avonovaAPI
      .post('/hse/api/measures/pushmeasurstosubeaccounts', measure)
      .catch(e => e)
  }

  return {
    toggleDashboard,
    assignMeasureToSubAccount,
    getRoles
  }
}
