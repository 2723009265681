import { reactive, toRefs } from 'vue'

import { requestFinished, requestStarted } from '@/utilities/httpUtilities'

import { httpMethods, requestHandlerWithState } from './requestHandler'

const state = reactive({
  hours: {},
  hour: {},
  hourTypes: [],
  hourRegistrationByYears: [],
  employeeReport: {},
  projects: [],
  mostRecentlyChangedItemId: null,
  yearsWithRegistrations: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  },
  accountYears: [],
  hourAccount: {}
})

export const useTimeRegisterService = () => {
  const getTimeRegisterByWeekAndYear = async (week, year) => {
    const url = `/hr/api/hours/getMyWeekTimeEnteriesByWeekYear`

    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      week,
      year
    })

    if (result.success) {
      state.hours = result.data
    }
  }

  const getProjectsForDate = async date => {
    const url = `/common/api/project/availableprojectsfordate`
    state.request = requestStarted()
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      date
    })
    if (result.success) state.projects = result.data
    state.request = requestFinished()
  }

  const registerTimeEntry = async data => {
    // id is empty
    const url = `/hr/api/hours/savetimeentry`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )
    if (result.success) {
      state.mostRecentlyChangedItemId = result.data.id
    }
    return result
  }

  const deleteTimeEntry = async id => {
    const url = `/hr/api/hours/removeTimeEntry/${id}`
    const result = await requestHandlerWithState(state, httpMethods.DELETE, url)
    return result
  }

  const getEmployeeReport = async (fromdate, todate, employee_id) => {
    const url = '/hr/api/hoursreport/getemployeereport'
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      fromdate,
      todate,
      employee_ids: [employee_id]
    })
    if (result.success) state.employeeReport = result.data
  }

  const hourRegistrationYears = async employeeid => {
    const url = `/hr/api/hours/hourRegistrationYears/${employeeid}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.yearsWithRegistrations = result.data
  }

  const timeAccountYears = async employeeid => {
    const url = `/hr/api/hours/hoursAccountYears/${employeeid}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.accountYears = result.data
  }

  const hoursAccountByYear = async (id, year) => {
    const url = `/hr/api/hours/getHourAccountTransactionsByYear`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      employee_id: id,
      year
    })
    if (result.success) state.hourAccount = result.data
  }

  return {
    state: toRefs(state),
    getProjectsForDate,
    getTimeRegisterByWeekAndYear,
    registerTimeEntry,
    deleteTimeEntry,
    getEmployeeReport,
    timeAccountYears,
    hoursAccountByYear,
    hourRegistrationYears
  }
}
