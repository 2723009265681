var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"small-menu"},[_c('ul',{staticClass:"user-menu"},[_c('li',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-item-link",attrs:{"rel":"noopener","target":"_blank","href":_vm.$sanitize(_vm.$t('menu__item__about_avonova_assist__url'))},on:{"click":function($event){_vm.handleMenuClick({
            title: _vm.$t('menu__item__about_avonova_assist'),
            value: _vm.$t('menu__item__about_avonova_assist__url')
          })}}},[_c('img',{attrs:{"src":'/app/img/icons/main-navigation-bar/list' + _vm.isWhitebg + '.svg',"role":"presentation","alt":_vm.$t('menu__item__about_avonova_assist')}}),_c('span',{staticClass:"menu-item-text"},[_vm._v(_vm._s(_vm.$t('menu__item__about_avonova_assist')))])])]),_c('li',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-item-link",attrs:{"rel":"noopener","target":"_blank","href":_vm.$sanitize(_vm.$t('menu__item__news__url'))},on:{"click":function($event){_vm.handleMenuClick({
            title: _vm.$t('menu__item__news'),
            value: _vm.$t('menu__item__news__url')
          })}}},[_c('img',{attrs:{"src":'/app/img/icons/main-navigation-bar/news' + _vm.isWhitebg + '.svg',"role":"presentation","alt":_vm.$t('menu__item__news')}}),_c('span',{staticClass:"menu-item-text"},[_vm._v(_vm._s(_vm.$t('menu__item__news')))])])]),_c('li',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-item-link",attrs:{"rel":"noopener","target":"_blank","href":_vm.$sanitize(_vm.$t('menu__item__help_contact__url'))},on:{"click":function($event){_vm.handleMenuClick({
            title: _vm.$t('menu__item__help_contact'),
            value: _vm.$t('menu__item__help_contact__url')
          })}}},[_c('img',{attrs:{"src":'/app/img/icons/main-navigation-bar/question' + _vm.isWhitebg + '.svg',"role":"presentation","alt":_vm.$t('menu__item__help_contact')}}),_c('span',{staticClass:"menu-item-text"},[_vm._v(_vm._s(_vm.$t('menu__item__help_contact')))])])]),(!_vm.isServicesPage)?_c('li',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-item-link",attrs:{"href":"/control-panel/avonova-access"},on:{"click":function($event){_vm.handleMenuClick({
            title: _vm.$t('menu__item__company_access'),
            value: '/control-panel/avonova-access'
          })}}},[_c('img',{attrs:{"src":"/app/img/icons/main-navigation-bar/avonova-access.svg","role":"presentation","alt":_vm.$t('menu__item__company_access')}}),_c('span',{staticClass:"menu-item-text"},[_vm._v(_vm._s(_vm.$t('menu__item__company_access')))])])]):_vm._e(),_c('li',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-item-link",attrs:{"href":_vm.$sanitize(_vm.$t('menu__item__terms_conditions_url'))}},[_c('img',{attrs:{"src":"/app/img/icons/main-navigation-bar/verified.svg","role":"presentation","alt":_vm.$t('menu__item__terms_conditions'),"height":"18"}}),_c('span',{staticClass:"menu-item-text"},[_vm._v(_vm._s(_vm.$t('menu__item__terms_conditions')))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }