export const INCIDENTS_MODE = {
  NEW: 'new',
  EDIT: 'edit'
}

export const INCIDENTS_TYPES = {
  NON_CONFORMANCE: 'AVVIK',
  ADVERSE_INCIDENTS: 'UONSKETHENDELSE',
  IMPROVEMENT: 'FORBEDRING',
  OBSERVATION: 'OBSERVASJON',
  OTHER: 'ANNET'
}

export const SNACKBAR_CASES = {
  REGISTERED: 'REGISTERED',
  SAVED: 'SAVED',
  CHANGES_DISCARDED: 'CHANGES_DISCARDED',
  CLOSED: 'CLOSED',
  FAILED: 'FAILED',
  DELETE: 'DELETE',
  NO_DELETE_ACCESS: 'NO_DELETE_ACCESS'
}
