<template>
  <div class="timerecorder-legacy" style="z-index: 99999999;">
    <v-navigation-drawer v-model="visible" app temporary right :width="isMobile ? '100%' : 382" color="#f0f4f3">
      <div class="px-8">
        <div class="d-flex flex-row align-center timer-header">
          <h2>{{ $t('timerecorder__title') }}</h2>
          <v-spacer />
          <button id="timerecorder-closeButton" v-on:click="close" :aria-label="$t('common__close')">
            <v-icon>mdi-close-thick mdi-18px</v-icon>
          </button>
        </div>

        <v-divider class="mt-2" />
        <div class="d-flex flex-column mt-4 timer-content">
          <span class="timer-about">{{ $t('timerecorder__description') }}</span>
          <div class="d-flex flex-row mt-4 legacy-button-group">
            <button v-if="inSession === false" v-on:click="start" class="legacy-button primary">{{ $t('timerecorder__start') }}</button>
            <button v-if="inSession === true" v-on:click="stop" class="legacy-button primary">{{ $t('timerecorder__stop') }}</button>
            <button v-if="inSession === true" v-on:click="cancel" class="legacy-button alternative">{{ $t('timerecorder__cancel') }}</button>
          </div>
        </div>

        <v-divider class="mt-2" />

        <div class="timer-form">
          <!-- Type -->
          <div class="d-flex flex-column mt-6 input-wrapper">
            <label for="type_selection">{{ $t('timerecorder__type') }}</label>
            <v-select
              id="timerecorder-typeSelection"
              v-model="type"
              :items="state.types || []"
              item-text="title"
              return-object
              append-icon="mdi-chevron-down mdi-24px"
              menu-props="offset-y"
              hide-details
              solo
              flat
              :aria-label="$t('timerecorder__type')"
              style="z-index: 99999999;"
            />
          </div>
          <!-- Project -->
          <div v-if="getProjects.length > 0">
            <div class="d-flex flex-column mt-6 input-wrapper">
              <label for="project_selection">{{ $t('timerecorder__project') }}</label>
              <v-select
                id="timerecorder-projectSelection"
                v-model="project"
                :items="getProjects"
                item-text="title"
                return-object
                append-icon="mdi-chevron-down mdi-24px"
                menu-props="offset-y"
                hide-details
                solo
                flat
                :aria-label="$t('timerecorder__project')"
                style="z-index: 99999999;"
              />
            </div>
          </div>
          <!-- Activity -->
          <div v-if="getActivities.length > 0">
            <div class="d-flex flex-column mt-6 input-wrapper">
              <label for="activity_selection">{{ $t('timerecorder__activity') }}</label>
              <v-select
                id="timerecorder-activitySelection"
                v-model="activity"
                :items="getActivities"
                item-text="title"
                return-object
                append-icon="mdi-chevron-down mdi-24px"
                menu-props="offset-y"
                hide-details
                solo
                flat
                style="z-index: 99999999;"
              />
            </div>
          </div>
          <!-- Starting Time -->
          <div>
            <LegacyDateTimePicker
              id="startingTime"
              :label="$t('timerecorder__start')"
              :isoString="state.session.fromtime"
              @updated="updateStartingTime"
            />
          </div>
          <!-- Ending Time -->
          <div v-show="inSession">
            <LegacyDateTimePicker
              id="endingTime"
              :label="$t('timerecorder__stop')"
              :isoString="state.session.totime"
              @updated="updateEndingTime"
              ref="pickerStop"
            />
          </div>

          <!-- Subtract lunch -->
          <div class="d-flex flex-column mt-6 input-wrapper">
            <label for="timerecorder-subtractLunchCheckbox">{{ $t('timerecorder__subtract_lunch') }}</label>
            <v-select
                id="timerecorder-activitySelection"
                v-model="lunchType"
                :items="state.lunchTypes || []"
                item-text="title"
                return-object
                append-icon="mdi-chevron-down mdi-24px"
                menu-props="offset-y"
                hide-details
                solo
                flat
                style="z-index: 99999999;"
              />
          </div>

          <!-- Duration -->
          <div class="d-flex flex-column mt-6 input-wrapper">
            <label>{{ $t('timerecorder__duration') }}</label>
            <div class="d-flex align-center duration">{{ state.duration }}</div>
          </div>

          <!-- Note -->
          <div class="d-flex flex-column mt-6 mb-1 input-wrapper">
            <label for="note">{{ $t('timerecorder__note') }}</label>
            <textarea id="timerecorder-noteTextfield" v-model="state.session.note" maxlength="255"></textarea>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import LegacyDateTimePicker from '@/components/LegacyDateTimePicker.vue'
import { useTimeRecorderApi } from '@/composable/menu/timeRecorderApi'
import { isSomething } from '@/utilities/conditionalUtilities'
export default {
  name: 'LegacyTimeRecorder',
  components: { LegacyDateTimePicker },
  setup() {
    const {
      state,
      getTimeRecorderSession,
      finishTimeRecorderSession,
      startTimeRecorderSession,
      cancelTimeRecorderSession,
      getTimeRecorderTypes,
      getAvailableProjectsForDate,
      getDuration,
      getTimeRecorderLunchTypes
    } = useTimeRecorderApi()

    return {
      state,
      getTimeRecorderSession,
      finishTimeRecorderSession,
      startTimeRecorderSession,
      cancelTimeRecorderSession,
      getTimeRecorderTypes,
      getAvailableProjectsForDate,
      getDuration,
      getTimeRecorderLunchTypes
    }
  },
  data() {
    return {
      visible: false,
      type: null,
      project: null,
      activity: null,
      lunchType: undefined
    }
  },
  methods: {
    open: function() {
      this.visible = true
    },
    close: function() {
      this.visible = false
    },
    start: function() {
      this.writeState()
      this.startTimeRecorderSession().then(() => this.readState())
      this.visible = false
    },
    stop: function() {
      this.writeState()
      this.finishTimeRecorderSession().then(() => this.readState())
      this.visible = false
    },
    cancel: function() {
      this.writeState()
      this.cancelTimeRecorderSession().then(() => this.readState())
      this.visible = false
    },
    updateStartingTime(newValue) {
      if (this.state.session.fromtime === newValue) return
      this.state.session.fromtime = newValue
      if (this.state.session.totime) this.getDuration()
      this.updateProjectList()
    },
    updateEndingTime(newValue) {
      if (this.state.session.totime === newValue) return
      this.state.session.totime = newValue
      if (this.state.session.fromtime) this.getDuration()
    },
    updateProjectList: function() {
      this.getAvailableProjectsForDate().then(() => {
        // If the currently selected project is not in the updated project list, reset selected project
        const hasFoundSelectedProjectInProjects = this.project?.id
          ? this.state.projects.find(p => p.id == this.project?.id)
          : false
        if (!hasFoundSelectedProjectInProjects) {
          this.project =
            this.getProjects.length > 0 ? this.getProjects[0] : { id: 0 }
        }
      })
    },
    readState: function() {
      // Type
      if (this.state.session?.type) this.type = { ...this.state.session.type }
      else if (this.state.types?.length > 0) this.type = this.state.types[0]
      else this.type = { id: null }

      // Project
      if (isSomething(this.state.session?.project))
        this.project = { ...this.state.session.project }
      else
        this.project = { id: 0 }
        
      // Activity
      if (this.state.session?.activity) this.activity = { ...this.state.session.activity }
      else this.activity = { id: null }
      
      // Lunch
      if (this.state.session?.lunch_minutes > 0)
        this.lunchType = this.state.lunchTypes.find(lt => lt.duration === this.state.session.lunch_minutes)
      else if (this.state.lunchTypes?.length > 0)
        this.lunchType = this.state.lunchTypes[0]
      else
        this.lunchType = 0

      this.updateProjectList()
    },
    writeState: function() {
      // Set API-state from component-variables
      this.state.session.type = this.type
      this.state.session.project = this.project
      this.state.session.activity = this.activity
      this.state.session.lunch_minutes = this.lunchType.duration
    }
  },
  computed: {
    inSession() {
      if (!this.state.session.id && this.state.session.id !== 0) return false
      return true
    },
    getProjects() {
      if (this.state.projects.length === 0) return []
      // Add a 'Select project'-option at index 0 to imitate the legacy design
      return [{ id: null, title: this.$t('timerecorder__select_project') }, ...this.state.projects]
    },
    getActivities() {
      if (this.project?.availableactivities === undefined) return []
      if (this.project.availableactivities.length === 0) return []
      // Add a 'Select activity'-option at index 0 to imitate the legacy design
      return [{ id: null, title: this.$t('timerecorder__select_activity') }, ...this.project.availableactivities]
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  created() {
    Promise.all([
      this.getTimeRecorderSession(),
      this.getTimeRecorderTypes(),
      this.getTimeRecorderLunchTypes()]
    ).then(() => this.readState())
  },
  watch: {
    lunchType(newValue) {
      this.state.session.lunch_minutes = newValue.duration;
      if (this.state.session.totime)
        this.getDuration()
    },
    project() {
      // Whenever a project is selected, we need to reset the activity
      if (!this.getActivities.find(a => a.id == this.activity.id)) {
        this.activity = { id: null }
      }
      // If activity has not been selected and there are activities available, use option 'Select activity' (index 0)
      if (this.activity.id === null && this.getActivities.length > 0) {
        this.activity = this.getActivities[0]
      }
    },
    visible(newValue) {
      // When closing, clear input from stop-picker
      if (newValue === false) {
        if (this.inSession && this.state.session.totime !== null) {
          this.state.session.totime = null
          const picker = this.$refs.pickerStop
          if (picker) picker.reset()
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.timer-header {
  height: 62px;
  position: relative;
  h2 {
    font-family: "GT-Walsheim", Helvetica, sans-serif !important;
    font-size: 28px !important;
    font-weight: 500 !important;
  }
  #timerecorder-closeButton {
    display: flex;
    margin-right: 2px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: #b4e0d1;
    justify-content: center;
    transition: background-color 0.2s;
    i {
      margin: auto;
      font-size: 16px;
    }
    &:hover {
      background: #a2d9c6;
    }
    &:focus {
      background: #b4e0d1;
    }
  }
}

.timer-content {
  line-height: 150%;
  .legacy-button {
    height: 48px;
    padding: 0 17px;
    margin-right: 8px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 16px;
    &.primary {
      color: #ffffff;
      background-color: #028655;
      &:hover {
        background-color: #026d45;
      }
    }
    &.alternative {
      color: #000000;
      background-color: #ffffff;
      &:hover {
        background-color: #ffffff;
      }
      &:focus {
        position: relative;
        background-color: #ffffff;
        &:after {
          content: ' ';
          position: absolute;
          top: -4px;
          left: -4px;
          height: calc(100% + 8px);
          width: calc(100% + 8px);
          border: 3px solid #028655;
        }
      }
    }
    &:hover {
      height: 46px;
      padding: 0 16px;
      margin: 1px 9px 1px 1px;
    }
    &:focus {
      position: relative;
      background-color: #02935d;
      &:after {
        content: ' ';
        position: absolute;
        top: -4px;
        left: -4px;
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        border: 3px solid #028655;
      }
    }
    transition: background-color 0.2s;
  }
}

.input-wrapper {
  font-weight: 400;
  font-size: 14px;
  label {
    margin-bottom: -2px;
  }

  textarea,
  input[type='text'] {
    border-radius: 0;
    border: none;
    outline: none;
    border-bottom: 1px solid #b4e0d1;
    transition: border-color 0.2s;
    font-size: 16px;
    background-color: white;
    padding-left: 10px;
    &:focus {
      border-color: #028655;
    }
    &.readonly {
      border-color: #d1e9e1;
      background-color: #f7f9f9;
      cursor: default;
      color: #777978;
    }
  }
  textarea {
    height: 180px;
    padding: 12px;
    font-family: Inter, Inter-Regular, sans-serif;
    font-size: 16px;
    line-height: normal;
    resize: none;
  }
  input[type='text'] {
    height: 48px;
  }

  .checkbox-wrapper-legacy {
    background-color: white;
    width: 20px;
    height: 20px;
    border: 1px solid #b4e0d1;
    &.checked {
      background-color: #028655;
      border: none;
      position: relative;
      &:after {
        content: ' ';
        width: 9px;
        height: 6px;
        position: absolute;
        top: 7px;
        left: 6px;
        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        transform: rotate(-45deg);
      }
      transition: background-color 0.2s;
      &:hover {
        background-color: #015435;
      }
    }
    input[type='checkbox'] {
      appearance: none;
      border: none;
      outline: none;
    }
  }

  .duration {
    padding-left: 10px;
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #d1e9e1;
    background-color: #f7f9f9;
    color: #858786;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
