import { ACCESS_RIGHTS } from '@/constants/menuAccess'
import { EMPLOYEE_PATHS } from '@/constants/navigation'

import {
  useEmployeeBreadcrumbs,
  useEmployeeHoursBreadcrumbs
} from './employeeBreadcrumbs'

const { myVacationBreadcrumbs } = useEmployeeBreadcrumbs()

const { myHoursBreadcrumbs } = useEmployeeHoursBreadcrumbs()

const employeeRoutes = [
  {
    path: EMPLOYEE_PATHS.ABSENCE,
    name: 'Absence',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "AbsenceHero" */
          `@/views/employee/absence/components/MyAbsenceHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Absence" */
          `@/views/employee/absence/MyAbsence.vue`
        )
    },
    meta: {
      title: 'meta__title__absence',
      menuAccess: ACCESS_RIGHTS.MY_ABSENCE,
      contentful: {
        category: '44BbVlI0YyrMnyrWkoHLDO',
        pageHelp: '1I5NjvLXybAtzTwfzDO13h'
      },
      breadcrumbs: [
        {
          text: 'My absence',
          string: 'meta__title__absence',
          disabled: false
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: EMPLOYEE_PATHS.COMPETENCE,
    name: 'Competence',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "MyCompetenceHero" */
          `@/views/employee/competence/components/MyCompetenceHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "MyCompetence" */
          `@/views/employee/competence/MyCompetence.vue`
        )
    },
    meta: {
      title: 'meta__title__competence',
      menuAccess: ACCESS_RIGHTS.MY_COMPETENCE,
      contentful: {
        category: '7jkkMeK2ySHmulOplgbZ5C',
        pageHelp: 'wAqMvdep1U9s4IGV9xPNz'
      },
      breadcrumbs: [
        {
          text: 'My competence',
          string: 'meta__title__competence',
          disabled: false
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${EMPLOYEE_PATHS.COMPETENCE}'/edit/:id?/:confirmed?`,
    name: 'EditMyCompetence',
    components: {
      hero: () =>
        import(
          /* webpackChunkName:"EditMyCompetenceHero" */ `@/views/employee/competence/components/EditMyCompetenceHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName:"EditMyCompetence" */ `@/views/employee/competence/EditMyCompetence.vue`
        )
    },
    meta: {
      title: 'meta__title__edit_competence',
      menuAccess: ACCESS_RIGHTS.MY_COMPETENCE,
      contentful: {
        category: '4hGmJO6Z9nDLKiXoYSUUVs',
        pageHelp: '4tQtqWFbwJkM9OklTPUDxh'
      },
      breadcrumbs: [
        {
          text: 'My competence',
          string: 'meta__title__competence',
          disabled: false,
          href: '/employee/competence'
        },
        {
          text: 'Edit competence',
          string: 'meta__title__edit_competence',
          disabled: true
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: EMPLOYEE_PATHS.VACATION,
    name: 'MyVacation',
    components: {
      default: () =>
        import(
          /* webpackChunkName: "MyVacation" */
          `@/views/employee/vacation/MyVacation.vue`
        )
    },
    meta: {
      title: 'my-vacation__title',
      contentful: {
        category: '3ZicCLREecuuZc5qg1GNKw',
        pageHelp: '6jFiaLhp6z89N5CWmnLuuo'
      },
      breadcrumbs: myVacationBreadcrumbs.parent
    },
    children: [
      {
        path: '',
        redirect: 'overview'
      },
      {
        path: 'overview',
        name: 'MyVacationOverview',
        components: {
          hero: () =>
            import(
              /* webpackChunkName: "MyVacationHero" */
              `@/views/employee/vacation/components/MyVacationHero.vue`
            ),
          default: () =>
            import(
              /* webpackChunkName: "MyVacation" */
              `@/views/employee/vacation/components/my-overview/MyVacationOverview.vue`
            )
        },
        meta: {
          title: 'my-vacation__overview_title',
          menuAccess: ACCESS_RIGHTS.MY_VACATION,
          contentful: {
            category: '3ZicCLREecuuZc5qg1GNKw',
            pageHelp: '6jFiaLhp6z89N5CWmnLuuo'
          },
          breadcrumbs: [
            ...myVacationBreadcrumbs.parent,
            myVacationBreadcrumbs.myOverview
          ]
        }
      },
      {
        path: 'my-colleagues',
        name: 'MyColleaguesOverview',
        components: {
          hero: () =>
            import(
              /* webpackChunkName: "MyVacationHero" */
              `@/views/employee/vacation/components/MyVacationHero.vue`
            ),
          default: () =>
            import(
              /* webpackChunkName: "MyVacation" */
              `@/views/employee/vacation/components/my-colleagues/MyColleaguesOverview.vue`
            )
        },
        meta: {
          title: 'my-vacation__colleagues_title',
          menuAccess: ACCESS_RIGHTS.MY_VACATION,
          contentful: {
            category: '3ZicCLREecuuZc5qg1GNKw',
            pageHelp: '6jFiaLhp6z89N5CWmnLuuo'
          },
          breadcrumbs: [
            ...myVacationBreadcrumbs.parent,
            myVacationBreadcrumbs.myColleagues
          ]
        }
      }
    ],
    productionAvailable: true
  },
  {
    path: EMPLOYEE_PATHS.HOURS,
    alias: ['/employee/my-hours'],
    name: 'MyHours',
    components: {
      default: () => import(`@/views/employee/hours/Hours.vue`)
    },
    meta: {
      title: 'meta__title__hours',
      contentful: {
        category: '4mV1BKdYfEUzaSGheUCtya',
        pageHelp: '4mVqKu6M8ImR4Qebv8X8fz'
      },
      breadcrumbs: myHoursBreadcrumbs.parent
    },
    children: [
      {
        path: '',
        redirect: 'registration'
      },
      {
        path: 'registration',
        name: 'HoursRegistrationList',
        alias: [],
        components: {
          hero: () => import('@/views/employee/hours/components/HoursHero.vue'),
          default: () =>
            import(
              '@/views/employee/hours/components/overviewItems/HoursRegistrationList.vue'
            )
        },
        meta: {
          title: 'meta__title__hours',
          menuAccess: ACCESS_RIGHTS.MY_HOURS,
          contentful: {
            category: '4mV1BKdYfEUzaSGheUCtya',
            pageHelp: '4mVqKu6M8ImR4Qebv8X8fz'
          },
          breadcrumbs: [
            ...myHoursBreadcrumbs.parent,
            myHoursBreadcrumbs.registration
          ]
        }
      },
      {
        path: 'hour-account',
        name: 'HoursTimeReport',
        alias: [],
        components: {
          hero: () => import('@/views/employee/hours/components/HoursHero.vue'),
          default: () =>
            import(
              '@/views/employee/hours/components/overviewItems/HoursTimeReport.vue'
            )
        },
        meta: {
          title: 'meta__title__hours',
          menuAccess: ACCESS_RIGHTS.MY_HOURS,
          contentful: {
            category: '4mV1BKdYfEUzaSGheUCtya',
            pageHelp: '4mVqKu6M8ImR4Qebv8X8fz'
          },
          breadcrumbs: [
            ...myHoursBreadcrumbs.parent,
            myHoursBreadcrumbs.hourAccount
          ]
        }
      },
      {
        path: 'history',
        name: 'HoursHistory',
        alias: [],
        components: {
          hero: () => import('@/views/employee/hours/components/HoursHero.vue'),
          default: () =>
            import(
              '@/views/employee/hours/components/overviewItems/HoursHistory.vue'
            )
        },
        meta: {
          title: 'meta__title__hours',
          menuAccess: ACCESS_RIGHTS.MY_HOURS,
          contentful: {
            category: '4mV1BKdYfEUzaSGheUCtya',
            pageHelp: '4mVqKu6M8ImR4Qebv8X8fz'
          },
          breadcrumbs: [
            ...myHoursBreadcrumbs.parent,
            myHoursBreadcrumbs.history
          ]
        }
      }
    ],
    productionAvailable: true
  },
  {
    path: EMPLOYEE_PATHS.INCIDENTS,
    alias: ['/employee/my-incidents'],
    name: 'MyIncidents',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "MyIncidentsHero" */
          `@/views/employee/incidents/components/MyIncidentsHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "MyIncidents" */
          `@/views/employee/incidents/MyIncidentsList.vue`
        )
    },
    meta: {
      title: 'menu__item__my_incidents',
      menuAccess: ACCESS_RIGHTS.MY_INCIDENTS,
      contentful: {
        category: '2jtxNCXPK5Esy64kHRy9d4',
        pageHelp: '2uznem4oztaRhprovknqlL'
      },
      breadcrumbs: [
        {
          text: 'My Incidents',
          string: 'menu__item__my_incidents',
          disabled: false
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${EMPLOYEE_PATHS.INCIDENTS}/:id`,
    alias: ['/employee/my-incidents/:id'],
    name: 'EditMyIncidents',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "MyIncidentsHero" */
          `@/views/employee/incidents/components/MyIncidentsEditHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "MyIncidents" */
          `@/views/employee/incidents/MyIncidents.vue`
        )
    },
    meta: {
      title: 'menu__item__my_incidents',
      menuAccess: ACCESS_RIGHTS.MY_INCIDENTS,
      contentful: {
        category: '2jtxNCXPK5Esy64kHRy9d4',
        pageHelp: '2uznem4oztaRhprovknqlL'
      },
      breadcrumbs: [
        {
          text: 'My Incidents',
          string: 'menu__item__my_incidents',
          disabled: false,
          href: '/employee/my-incidents/'
        },
        {
          text: 'Edit incident',
          string: 'incidents__edit_incident',
          disabled: true
        }
      ]
    },
    productionAvailable: true
  }
]

export default employeeRoutes
