<template>
  <div>
    <slot v-if="isFeatureEnabled"></slot>
    <slot v-else name="fallback"></slot>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'

import { useStore } from '@/store'

import { POLLING_TIME_IN_MINUTE } from '@/constants/featureFlags.js'

export default {
  name: 'FeatureFlag',
  props: {
    flag: { type: String, required: true }
  },
  setup(props) {
    const store = useStore()
    const isFeatureEnabled = computed(() => {
      return store.getters['featureFlags/isFeatureEnabled'](props.flag)
    })

    onMounted(async () => {
      await store.dispatch('featureFlags/fetchFeatureFlags')
      startPolling()
    })

    const startPolling = () => {
      setInterval(() => {
        store.dispatch('featureFlags/fetchFeatureFlags')
      }, POLLING_TIME_IN_MINUTE)
    }

    return {
      isFeatureEnabled
    }
  }
}
</script>
