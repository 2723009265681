const maintenanceRoutes = [
  {
    path: '/maintenance/:method?',
    name: 'Maintenance',
    component: () =>
      import(/* webpackChunkName: "Maintenance" */ `@/views/Maintenance.vue`),
    meta: { title: 'Maintenance' },
    productionAvailable: true
  }
]
export default maintenanceRoutes
