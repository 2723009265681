import { reactive, toRefs } from 'vue'

import env from '@/environment'

import i18n from '@/config/i18n'

import { useContentfulFunctions } from '@/composable/common/contentful'
import { useUserService } from '@/services'
import { authService } from '@/services/'
import { useLogger } from '@/shared/logger'
import { orderBy } from '@/utilities/arrayUtilities'
import {
  hasItems,
  isEmpty,
  isNone,
  isSomething
} from '@/utilities/conditionalUtilities'
import { getReportYear } from '@/utilities/feature-specifics/reportUtilities'

import { httpMethods, requestHandler } from '../requestHandler'
import { companyDataDto } from './dto/companyDataDto'

export const REPORT_STATUS = Object.freeze({
  'NEW': 'NEW',
  'IN_PROGRESS': 'IN_PROGRESS',
  'FINISHED': 'FINISHED'
})

const ActivityType = Object.freeze({
  GENERAL: 1,
  LONG_EXAMPLE: 2,
  SHORT_EXAMPLE: 3,
  BULLET_POINTS: 4,
  EXTRA_ACTIVITIES: 5
})

const RoleDefinition = Object.freeze({
  KAM: 'Key account manager',
  CEO: 'Manager',
  SAFETY_REPRESENTATIVE: 'Safety representative',
  CONTACT: 'Main contact'
})

const state = reactive({
  showAllReports: false,
  currentReport: undefined,
  selectedReportYear: getReportYear(),
  companies: [],
  searchedCompanies: [],
  reports: [],
  organisationRoles: {},
  searchingForCompanies: false
})

const getAdpHeaders = () => ({
  'Adp-Annual-Report-Api-Subscription-Key':
    env.VUE_APP_ADP_ANNUAL_REPORT_API_KEY,
  'Authorization': `Bearer ${authService.getToken()}`
})

export const useAnnualReportService = () => {
  const logger = useLogger('annualReportService')

  const basePath = env.VUE_APP_ADP_ANNUAL_REPORT_BASEPATH

  const defaultLanguage = 'nb-NO'

  const { getContentByType, getContentById } = useContentfulFunctions()

  const mapContentToActivity = (content, language) => ({
    id: content.sys.id,
    title: content.fields.title[language],
    descriptionTemplates: content.fields.descriptionTemplates[language],
    description: content.fields.description
      ? content.fields.description[language]
      : '',
    link: content.fields.link ? content.fields.link[language] : '',
    linkText: content.fields.link ? content.fields.linkText[language] : '',
    sortIndex: content.fields.sortIndex[defaultLanguage]
  })

  const getActivitiesByLanguageAndType = (language, activityType) => {
    const activities = getContentByType('annualReportActivity')

    const transformedActivities = activities
      .filter(
        activity =>
          activity.fields.activityType[defaultLanguage] === activityType
      )
      .map(activity => mapContentToActivity(activity, language))

    return orderBy(transformedActivities, activity => activity.sortIndex)
  }

  const getLongExamplesByLanguage = language => {
    const activityGroups = getContentByType('annualReportActivityGroup')

    const items = activityGroups.map(group => ({
      title: group.fields.title[language],
      activities: orderBy(
        group.fields.annualReportActivities[defaultLanguage].map(activity =>
          mapContentToActivity(getContentById(activity.sys.id), language)
        ),
        activity => activity.sortIndex
      )
    }))

    return items
  }

  const getReportTemplate = (language = defaultLanguage) => {
    const model = JSON.parse(JSON.stringify({ ...companyDataDto }))

    // need to add contentful keys here, in companyDataDto.js keys dont work.
    const introductionpageIntroductionText = i18n.t(
      'annualReport_bht__introductionPage_introductionText'
    )
    /*    const activitesSummaryText = i18n.t(
          'annual_report_activitiesIntroduction_summaryText'
        )*/
    const recommendedActivitesIntroductionText = i18n.t(
      'annual_report_activitiesIntroduction_recommendedActivities_introductionText'
    )
    const conclusionSummaryText = i18n.t('annual_report_conclusion_summaryText')
    const conclusionClosingText = i18n.t('annual_report_conclusion_closingText')

    model.document.introduction = introductionpageIntroductionText
    /*model.activitiesIntroduction.summaryText = activitesSummaryText*/
    model.document.recommendedActivities.introduction =
      recommendedActivitesIntroductionText
    model.document.conclusion.conclusionSummaryText = conclusionSummaryText
    model.document.conclusion.closingText = conclusionClosingText

    model.document.activitiesTable.activities = getActivitiesByLanguageAndType(
      language,
      ActivityType.GENERAL
    )
    model.document.activitiesTable.extraActivities =
      getActivitiesByLanguageAndType(language, ActivityType.EXTRA_ACTIVITIES)

    model.document.recommendedActivities.longExamples =
      getLongExamplesByLanguage(language)

    model.document.recommendedActivities.shortExamples =
      getActivitiesByLanguageAndType(language, ActivityType.SHORT_EXAMPLE)

    model.document.recommendedActivities.bulletPoints =
      getActivitiesByLanguageAndType(language, ActivityType.BULLET_POINTS)

    logger.debug('getReportTemplate', model)

    const { getCurrentUser } = useUserService()
    const userData = getCurrentUser()

    // default values when serving new entity
    model.document.createdBy = userData
    model.document.modifiedBy = userData
    model.document.keyAccountManager.email = userData.username
    model.document.status = REPORT_STATUS.IN_PROGRESS

    model.document.companyName = '' // factory function for generating new entity
    model.document.organisationalNumber = ''

    return model
  }

  const getReportById = async reportId => {
    const report = state.reports.find(report => report.id === reportId)
    logger.debug('getReportById', report)
    return report
  }

  const getReportByOrgNoAndYearAsync = async (orgNo, year) => {
    const path = `${basePath}/reportbyorgyear?year=${year}&orgnr=${orgNo}`
    return await requestHandler(httpMethods.GET, path, undefined, {
      headers: getAdpHeaders()
    })
  }

  const getReportByIdAsync = async reportId => {
    const parts = reportId.split('-')
    const orgNo = parts[0]
    const year = parts[1]

    const response = await getReportByOrgNoAndYearAsync(orgNo, year)

    if (response.success) state.reports.push(response.data)

    assignCurrentReport(response.data)
  }

  const assignCurrentReport = report => {
    logger.debug('assignCurrentReport', report)
    state.currentReport = report
  }

  const getCompaniesByKAM = async email => {
    const path = `${basePath}/companiesbykam`
    const kamHeaders = {
      ...getAdpHeaders(),
      'key-account-manager-email': email
    }

    const response = await requestHandler(httpMethods.GET, path, undefined, {
      headers: kamHeaders
    })

    if (response.success && hasItems(response.data))
      state.companies = response.data
    else state.companies = []

    return state.companies.sort((a, b) => a.name.localeCompare(b.name))
  }

  const getCompaniesBySearch = async (orgNo, companyName) => {
    state.searchingForCompanies = true

    const path = `${basePath}/companiesbyorgnrname`

    let queryPath
    if (isSomething(orgNo) && isNone(companyName))
      queryPath = `${path}/?organization-number=${orgNo}`
    else queryPath = `${path}/?company-name=${companyName}`

    const response = await requestHandler(
      httpMethods.GET,
      queryPath,
      undefined,
      {
        headers: getAdpHeaders()
      }
    )

    logger.debug('getCompaniesBySearch - DONE', response)

    if (response.success) state.searchedCompanies = response.data
    else state.searchedCompanies = []

    state.searchingForCompanies = false

    return state.searchedCompanies.sort((a, b) => a.name.localeCompare(b.name))
  }

  const getCompaniesByOrgNo = async orgNo =>
    getCompaniesBySearch(orgNo, undefined)

  const getCompaniesByCompanyName = async companyName =>
    getCompaniesBySearch(undefined, companyName)

  const fetchRolesByMetodikaId = async metodikaId => {
    const url = `${basePath}/contactpersonsforcompany?metodikaid=${metodikaId}`

    const result = await requestHandler(httpMethods.GET, url, undefined, {
      headers: getAdpHeaders()
    })

    const roles = {
      keyAccountManagers: [],
      leaders: [],
      contacts: [],
      safetyRepresentatives: []
    }

    if (result.success) {
      result.data.forEach(person => {
        const item = {
          name: `${person.firstName} ${person.lastName}`,
          email: person.email,
          metodikaId
        }

        person.roles.forEach(role => {
          switch (role) {
            case RoleDefinition.CEO:
              roles.leaders.push({ ...item })
              break
            case RoleDefinition.KAM:
              roles.keyAccountManagers.push({ ...item })
              break
            case RoleDefinition.SAFETY_REPRESENTATIVE:
              roles.safetyRepresentatives.push({ ...item })
              break
            case RoleDefinition.CONTACT:
              roles.contacts.push({ ...item })
              break
          }
        })
      })
    }
    roles.keyAccountManagers = returnArrayOrDefault(roles.keyAccountManagers)
    state.organisationRoles = roles
  }

  const returnArrayOrDefault = array => {
    if (hasItems(array)) return array
    return [{ name: '', email: '' }]
  }

  const getReportsByKamAndYear = async (email, year = undefined) => {
    const path = isSomething(year)
      ? `${basePath}/overviewbykam/?year=${year}`
      : `${basePath}/overviewbykam`

    const kamHeaders = {
      ...getAdpHeaders(),
      'key-account-manager-email': email ?? ''
    }

    const result = await requestHandler(httpMethods.GET, path, undefined, {
      headers: kamHeaders
    })

    logger.debug(
      `getReportsByKamAndYear, email: ${email}, year: ${year}`,
      result
    )

    state.reports = result.data.map(report => {
      if (isNone(report.document.uiState)) {
        report.document.uiState = {
          hasAssistedCustomer: true
        }
      }

      return report
    })

    return result
  }

  const saveReport = async report => {
    logger.debug('saveReport', report)
    logger.debug('savereport headers', getAdpHeaders())

    const path = `${basePath}/report`

    // overwrite head values
    report.kamEmail = isEmpty(report.document.keyAccountManager[0]?.email)
      ? report.document.createdBy.username
      : report.document.keyAccountManager[0]?.email
    report.document.createdBy.username

    report.organizationNumber = parseInt(
      report.document.organisationalNumber,
      10
    )
    report.status = report.document.status
    report.year = parseInt(report.document.year, 10)

    const action = isSomething(report.id) ? httpMethods.PUT : httpMethods.POST

    await requestHandler(action, path, report, {
      headers: getAdpHeaders()
    })

    if (isNone(report.id)) {
      const response = await getReportByOrgNoAndYearAsync(
        report.organizationNumber,
        report.year
      )
      logger.debug('refreshed report', response.data)
      report.id = response.data.id
    }
  }

  const deleteReport = async reportId => {
    const response = await requestHandler(
      httpMethods.DELETE,
      `${basePath}/report?id=${reportId}`,
      undefined,
      { headers: getAdpHeaders() }
    )

    if (response.success) {
      const index = state.reports.findIndex(report => report.id === reportId)
      state.reports.splice(index, 1)
    }
  }

  const setReportFinished = () => {
    state.currentReport.status = REPORT_STATUS.FINISHED
    state.currentReport.document.status = REPORT_STATUS.FINISHED
  }

  const setReportInProgress = () => {
    state.currentReport.status = REPORT_STATUS.IN_PROGRESS
    state.currentReport.document.status = REPORT_STATUS.IN_PROGRESS
  }

  const toggleShowAllReports = () => {
    state.showAllReports = !state.showAllReports
  }

  const setSelectedReportYear = year => (state.selectedReportYear = year)

  return {
    getCompaniesByKAM,
    saveReport,
    getReportTemplate,
    reportState: toRefs(state),
    getReportById,
    assignCurrentReport,
    setReportFinished,
    setReportInProgress,
    getReportsByKamAndYear,
    deleteReport,
    fetchRolesByMetodikaId,
    getCompaniesByOrgNo,
    getCompaniesByCompanyName,
    getReportByIdAsync,
    toggleShowAllReports,
    setSelectedReportYear
  }
}
