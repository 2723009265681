import { useAccountService } from '@/services/account/account-service.js'

import { POLLING_TIME_IN_MINUTE } from '@/constants/featureFlags.js'

// Only fetch feature flags once per 1 minute to avoid unnecessary calls to the backend
const shouldFetchFeatureFlags = (state, dateNow) =>
  state.lastUpdated === null ||
  (!state.isFetchingFlags &&
    dateNow - state.lastUpdated >= POLLING_TIME_IN_MINUTE)

export default {
  name: 'featureFlags',
  namespaced: true,
  state: {
    featureFlags: [],
    lastUpdated: null,
    isFetchingFlags: false
  },
  mutations: {
    SET_FEATURE_FLAGS(state, featureFlags) {
      state.featureFlags = featureFlags
    },
    SET_LAST_UPDATED(state, lastUpdated) {
      state.lastUpdated = lastUpdated
    },
    SET_FETCHING_FLAGS(state, isFetchingFlags) {
      state.isFetchingFlags = isFetchingFlags
    }
  },
  actions: {
    async fetchFeatureFlags({ commit, state }) {
      const dateNow = Date.now()
      if (shouldFetchFeatureFlags(state, dateNow)) {
        commit('SET_FETCHING_FLAGS', true)
        try {
          const response = await useAccountService().getFeatureFlags()
          commit('SET_FEATURE_FLAGS', response)
          commit('SET_LAST_UPDATED', dateNow)
        } finally {
          commit('SET_FETCHING_FLAGS', false)
        }
      }
    }
  },
  getters: {
    isFeatureEnabled: state => featureName => {
      return state.featureFlags.includes(featureName)
    },
    isAnyFeatureEnabled: state => featureNames => {
      return featureNames.some(featureName =>
        state.featureFlags.includes(featureName)
      )
    }
  }
}
