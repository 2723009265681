<template>
  <v-menu
    transition="none"
    offset-y
    left
    :close-on-click="false"
    :close-on-content-click="false"
    nudge-top="5"
    :value="$store.state.initData.showWelcomeDashboard"
    content-class="elevation-0 dash"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :href="toModernUrl('/startside/aggregateddashboardwelcomepage.xhtml')"
        :title="$t('menu__item__startpage')"
        color="secondary"
        id="aggregated_dashboard_welcome_menu"
        fab
        width="47"
        height="47"
        v-bind="attrs"
        v-on="on"
        class="elevation-0 ml-4"
      >
        <v-icon color="black" small> fas fa-tachometer-alt </v-icon>
      </v-btn>
    </template>
    <div class="dashboardwelcomecallout-wrapper" v-if="show">
      <div class="dashboardwelcomecallout">
        <div class="calloutmsg v-html" v-html="$t('menu__leader__text')"></div>
        <div>
          <button
            id="leader-btn-cancel"
            class="leader-btn-cancel"
            :name="$t('menu__leader__not_now')"
            :title="$t('menu__leader__not_now')"
            type="submit"
            role="button"
            aria-disabled="false"
            @click="hideDashboardTooltip"
          >
            <span>{{ $t('menu__leader__not_now') }}</span></button
          ><button
            @click="goToDashboardWelcomePage()"
            id="leader-btn-go"
            class="leader-btn-go"
            :name="$t('menu__leader__lets_start')"
            :title="$t('menu__leader__lets_start')"
            type="submit"
            role="button"
            aria-disabled="false"
          >
            <span>{{ $t('menu__leader__lets_start') }}</span>
          </button>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { accountSettingsAPI } from '@/composable/account-settings.js'
// WORKAROUND FOR FEATURE: USE_IFRAME_MONOLITH
import { toModernUrl as _toModernUrl } from '@/composable/iframe.js'

export default {
  name: 'LeaderWindow',
  setup() {
    const { toggleDashboard } = accountSettingsAPI()
    return {
      toggleDashboard
    }
  },
  data() {
    return {
      dialog: false,
      show: true
    }
  },
  created() {
    // Keep tooltip hidden on refresh
    if (sessionStorage.getItem('hideDashboardTooltip')) {
      this.show = false
      return
    }
  },
  methods: {
    hideDashboardTooltip() {
      sessionStorage.setItem('hideDashboardTooltip', true)
      this.show = false
    },
    goToDashboardWelcomePage() {
      window.location.href = this.toModernUrl(
        '/startside/aggregateddashboardwelcomepage.xhtml'
      )
    },
    toModernUrl(url) {
      return _toModernUrl(url)
    }
  },
  computed: {
    isDashboardTooltip() {
      return this.$store.state.opened.hideDashboardTooltip
    }
  },
  watch: {
    show(newName) {
      if (newName) this.show = false
    }
  }
}
</script>

<style lang="scss">
.dash {
  z-index: 111;
  position: absolute;
}
.dashboardwelcomecallout-wrapper {
  padding-top: 15px;
}
.dashboardwelcomecallout {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  z-index: 3333333;
  position: relative;

  button {
    margin-top: 1rem;
    outline: none;
  }

  button span {
    font-size: 14px;
    padding: 0 1rem;
    display: block;
    line-height: normal;
    outline: 0;
    text-decoration: none;
  }

  .leader-btn-cancel {
    border-radius: 2px;
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    color: #546e7a;
    margin-right: 30px;
    display: inline-flex;
    align-items: center;
    height: 48px;
    width: auto;
  }
  .leader-btn-go {
    border-radius: 2px;
    border: 1px solid #006f4a;
    background-color: #006f4a;
    margin-right: 30px;
    display: inline-flex;
    align-items: center;
    height: 48px;
    width: auto;
    color: white;
  }
}
.dashboardwelcomecallout:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  right: 15px;
  top: -10px;
  background-color: white;
  border-color: #e0e0e0 transparent transparent #e0e0e0;
  border-style: solid;
  border-width: 2px;
  z-index: 44444444444;
  border-radius: 5px 0 5px 0;
  transform: rotate(45deg);
  @media (max-width: 570px) {
    right: 15px !important;
    background: red;
  }
}
.dashboardwelcomecallout .calloutmsg {
  color: #333;
  font-size: 14px !important;
  width: 250px;
}
.raggsokk.v-dialog {
  position: fixed;
  top: 80px;
  right: calc((100vw - 1158px - 32px) / 2);
  overflow-y: visible;
  @media (max-width: 1158px) {
    right: 0;
  }
  p {
    line-height: 130%;
  }

  ul {
    padding: 0px 24px 24px 24px;
  }
  .v-tabs-bar__content,
  .v-tabs-items {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .youtube-container {
    width: 100%;
    height: var(--iframe-height);
    position: relative;
    overflow: hidden;
    iframe {
      height: 100%;
      width: calc(100% + 10px);
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: 0;
      right: -5px;
    }
  }
}
</style>
