import { INCIDENT_REGISTRY_PATHS } from '@/constants/navigation'

import safetyInspections from './safetyInspections'

const incidentRegistryRoutes = [
  {
    path: INCIDENT_REGISTRY_PATHS.ACTION_PLAN,
    alias: [
      '/incidentregistry/actionplan',
      '/incidentregistry/actionplan/new',
      '/incidentregistry/actionplan/filter'
    ],
    name: 'ActionPlan',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ActionPlan" */ `@/views/incidentRegistry/actionPlan/components/ActionPlanHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "ActionPlan" */ `@/views/incidentRegistry/actionPlan/ActionPlanList.vue`
        )
    },
    meta: {
      title: 'menu__item__action_plan',
      contentful: {
        pageHelp: '5uSrojz69RAuOtaIuFpYTA'
      },
      breadcrumbs: [
        {
          text: 'Handlingsplan',
          string: 'menu__item__action_plan',
          disabled: true,
          href: INCIDENT_REGISTRY_PATHS.ACTION_PLAN
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${INCIDENT_REGISTRY_PATHS.ACTION_PLAN}/:id`,
    alias: [
      '/incidentregistry/actionplan/:id',
      '/incidentRegistry/actionplan/:id'
    ],
    name: 'ActionPlanEdit',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ActionPlan" */ `@/views/incidentRegistry/actionPlan/components/ActionPlanEditHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "ActionPlan" */ `@/views/incidentRegistry/actionPlan/ActionPlan.vue`
        )
    },
    meta: {
      tracking: {
        name: 'Actionplan'
      },
      title: 'menu__item__action_plan',
      breadcrumbs: [
        {
          text: 'Handlingsplan',
          string: 'menu__item__action_plan',
          disabled: false,
          href: INCIDENT_REGISTRY_PATHS.ACTION_PLAN
        },
        {
          text: 'Rediger tiltak',
          string: 'measures__edit_measure',
          disabled: true
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: INCIDENT_REGISTRY_PATHS.INCIDENTS,
    alias: [
      '/incidentregistry/incidents',
      '/incidentRegistry/Incidents',
      '/incidentregistry/Incidents/new',
      '/incidentregistry/Incidents/filter'
    ],
    name: 'Incidents',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Incidents" */ `@/views/incidentRegistry/Incidents/components/IncidentsHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Incidents" */ `@/views/incidentRegistry/Incidents/IncidentsList.vue`
        )
    },
    component: () =>
      import(/* webpackChunkName: "Project" */ `@/NewIdentity.vue`),
    meta: {
      title: 'menu__item__incidents',
      contentful: {
        pageHelp: '2is8RtQbCPkvcdI3qBh3OU'
      },
      breadcrumbs: [
        {
          text: 'Hendelser',
          string: 'menu__item__incidents',
          disabled: true,
          href: INCIDENT_REGISTRY_PATHS.INCIDENTS
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: `${INCIDENT_REGISTRY_PATHS.INCIDENTS}/:id`,
    alias: [
      '/incidentregistry/incidents/:id',
      '/incidentRegistry/Incidents/:id'
    ],
    name: 'EditIncidents',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Incidents" */ `@/views/incidentRegistry/Incidents/IncidentsEditHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Incidents" */ `@/views/incidentRegistry/Incidents/Incidents.vue`
        )
    },
    meta: {
      title: 'menu__item__incidents',

      breadcrumbs: [
        {
          text: 'Hendelser',
          string: 'menu__item__incidents',
          disabled: false,
          href: INCIDENT_REGISTRY_PATHS.INCIDENTS
        },
        {
          text: 'Rediger hendelse',
          string: 'incidents__edit_incident',
          disabled: true
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: INCIDENT_REGISTRY_PATHS.INSPECTIONS,
    alias: ['/incidentregistry/inspections'],
    name: 'Inspections',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Inspection" */ `@/views/incidentRegistry/inspectionReport/components/InspectionListHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Inspection" */ `@/views/incidentRegistry/inspectionReport/InspectionList.vue`
        )
    },
    component: () =>
      import(/* webpackChunkName: "Project" */ `@/NewIdentity.vue`),
    meta: {
      title: 'menu__item__inspection_report',

      breadcrumbs: [
        {
          text: 'Tilsyn',
          string: 'menu__item__inspection_report',
          disabled: true,
          href: INCIDENT_REGISTRY_PATHS.INSPECTIONS
        }
      ]
    },

    productionAvailable: true
  },
  {
    path: `${INCIDENT_REGISTRY_PATHS.INSPECTIONS}/:id`,
    alias: ['/incidentregistry/inspections/:id'],
    name: 'InspectionWithId',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "Inspections" */ `@/views/incidentRegistry/inspectionReport/components/InspectionHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Inspections" */ `@/views/incidentRegistry/inspectionReport/Inspection.vue`
        )
    },
    component: () =>
      import(/* webpackChunkName: "Project" */ `@/NewIdentity.vue`),
    meta: {
      title: 'menu__item__inspection_report',

      breadcrumbs: [
        {
          text: 'Tilsyn',
          string: 'menu__item__inspection_report',
          disabled: false,
          href: INCIDENT_REGISTRY_PATHS.INSPECTIONS
        },
        {
          text: 'Rediger tilsyn',
          string: 'inspectionreport__edit',
          disabled: true,
          href: `${INCIDENT_REGISTRY_PATHS.INSPECTIONS}/:id`
        }
      ]
    },
    children: [
      {
        path: '',
        name: 'Inspection',
        component: () =>
          import(
            /* webpackChunkName: "Inspections" */ `@/views/incidentRegistry/inspectionReport/Inspection.vue`
          ),
        meta: {
          title: 'menu__item__inspection_report',
          contentful: {
            category: 'PTRYWviqqOePp2mXVa86B',
            pageHelp: 'R7oV0MFgjt6o7oew65ua9'
          }
        }
      }
    ],
    productionAvailable: true
  },
  safetyInspections
]

export default incidentRegistryRoutes
