var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"mainIconMenu d-flex flex-wrap"},_vm._l((_vm.iconMenuData),function(icon,index){return _c('li',{key:index},[_c('div',{staticClass:"a-outer",on:{"click":function($event){return _vm.closeMenu()}}},[(!icon.isLegacy)?_c('router-link',{staticClass:"text-decoration-none d-flex flex-column",attrs:{"to":{ path: icon.url },"id":icon.permissionName.toLowerCase(),"title":_vm.$t(icon.menuItemText)}},[_c('v-icon',[_vm._v(_vm._s(icon.icon))]),_c('div',{staticClass:"menu-icon-text text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(icon.menuItemText))+" ")])],1):_c('a',_vm._g({staticClass:"text-decoration-none d-flex flex-column",attrs:{"href":icon.url === 'my_handbooks' ? null : _vm.toModernUrl(icon.url),"title":_vm.$t(icon.menuItemText),"id":icon.permissionName.toLowerCase()}},
            icon.url === 'my_handbooks'
              ? {
                  click: e => {
                    e.stopPropagation()
                    _vm.openHandbooks(icon.url)
                  }
                }
              : {}
          ),[_c('v-icon',[_vm._v(_vm._s(icon.icon))]),_c('div',{staticClass:"menu-icon-text text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(icon.menuItemText))+" ")])],1)],1)])}),0),_c('div',[_c('v-menu',{attrs:{"id":"handbookMenu","content-class":"handbook-menu-wrapper","attach":"#my_handbooks"},model:{value:(_vm.showHandbookMenu),callback:function ($$v) {_vm.showHandbookMenu=$$v},expression:"showHandbookMenu"}},[_c('div',{staticClass:"handbooks-menu"},_vm._l((_vm.handbooks),function(handbook,index){return _c('div',{key:index,staticClass:"handbook",attrs:{"role":"menuitem"}},[(handbook.handbookType === 'Handbok')?_c('a',{attrs:{"tabindex":"-1","href":handbook.url,"target":"_blank","rel":"noopener"}},[_c('span',{staticClass:"menu-item-icon"},[_c('i',{staticClass:"fa fa-book"})]),_c('span',{staticClass:"menu-item-text"},[_vm._v(_vm._s(handbook.handbookTitle))])]):_vm._e(),(handbook.handbookType === 'HandbookEntity')?_c('a',{attrs:{"tabindex":"-1","href":handbook.url}},[_c('span',{staticClass:"menu-item-icon"},[_c('i',{staticClass:"fa fa-book"})]),_c('span',{staticClass:"menu-item-text"},[_vm._v(_vm._s(handbook.handbookTitle))])]):_vm._e()])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }