import { setCookie } from '@/utilities/cookieUtilities'

import {
  ACCOUNT_COUNTRY,
  DEFAULT_LOCALE,
  SUPPORTED_LEGACY_LOCALE
} from '@/constants/i18n'

import i18n from './index'

// https://stamina.atlassian.net/wiki/spaces/DIG1/pages/2634317828/Internationalization

export function mapToISO(language) {
  // Map to ISO language codes because we want to expose them accross the app
  const languageMap = {
    [SUPPORTED_LEGACY_LOCALE.NO.toUpperCase()]: 'nb-NO',
    [SUPPORTED_LEGACY_LOCALE.SV.toUpperCase()]: 'sv-SE',
    [SUPPORTED_LEGACY_LOCALE.EN.toUpperCase()]: 'en-GB',
    [SUPPORTED_LEGACY_LOCALE.PL.toUpperCase()]: 'pl-PL',
    [SUPPORTED_LEGACY_LOCALE.FI.toUpperCase()]: 'fi-FI',
    'NB-NO': 'nb-NO',
    'SV-SE': 'sv-SE',
    'EN-GB': 'en-GB',
    'PL-PL': 'pl-PL',
    'FI-FI': 'fi-FI'
  }
  return languageMap[language.toUpperCase()] || DEFAULT_LOCALE
}

export function mapISOToLegacyLanguage(isoLocale) {
  // Map to legacy language codes
  const languageMap = {
    'nb-NO': SUPPORTED_LEGACY_LOCALE.NO,
    'en-GB': SUPPORTED_LEGACY_LOCALE.EN,
    'sv-SE': SUPPORTED_LEGACY_LOCALE.SV,
    'pl-PL': SUPPORTED_LEGACY_LOCALE.PL,
    'fi-FI': SUPPORTED_LEGACY_LOCALE.FI
  }
  return languageMap[isoLocale] || DEFAULT_LOCALE
}

export function mapISOToi18nLegacyLanguage(isoLocale, countryCode) {
  // Map to legacy language codes because contentful uses them
  const languageMap = {
    [ACCOUNT_COUNTRY.NORWAY]: {
      'nb-NO': 'nb-NO',
      'en-GB': 'nb-EN',
      'pl-PL': 'nb-PL'
    },
    [ACCOUNT_COUNTRY.SWEDEN]: {
      'en-GB': 'sv-EN',
      'sv-SE': 'sv-SE'
    },
    [ACCOUNT_COUNTRY.FINLAND]: {
      'fi-FI': 'fi-FI',
      'sv-SE': 'fi-SE'
    }
  }
  return languageMap[countryCode.toUpperCase()][isoLocale] || DEFAULT_LOCALE
}

export function mapi18nToISO(language) {
  const languageMap = {
    // Norway
    'nb-NO': 'nb-NO',
    'nb-EN': 'en-GB',
    'nb-PL': 'pl-PL',
    // Sweden
    'sv-EN': 'en-GB',
    'sv-SE': 'sv-SE',
    // Finland
    'fi-FI': 'fi-FI',
    'fi-SE': 'sv-SE'
  }
  return languageMap[language] || DEFAULT_LOCALE
}

export function seti18nLanguage(locale, countryCode) {
  const languageCode = mapISOToi18nLegacyLanguage(
    locale,
    countryCode.toUpperCase()
  )
  i18n.locale = languageCode
}

function setMarkupLanguageAttribute(language) {
  document.documentElement.setAttribute('lang', language)
}

function setLanguageToCookie(locale) {
  const languageCode = mapISOToLegacyLanguage(locale)
  setCookie('lang', languageCode)
}

function setLanguageLocale(locale, countryCode, language) {
  seti18nLanguage(locale, countryCode)
  setMarkupLanguageAttribute(language)
  setLanguageToCookie(locale)
}

export function localize(locale, countryCode, language) {
  // Locale is in ISO format, e.g. nb-NO
  setLanguageLocale(locale, countryCode, language)
}
