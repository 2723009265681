import { reactive, toRefs } from 'vue'

import avonovaAPI from '@/config/axios'

import { replaceItem } from '@/utilities/arrayUtilities'
import { hasItems } from '@/utilities/conditionalUtilities'
import { addNewItem, replaceNewItem } from '@/utilities/table'

import {
  httpMethods,
  requestHandler,
  requestHandlerWithState
} from './requestHandler'

const state = reactive({
  selectedYear: new Date().getFullYear(), // initialize with current year.
  absences: [],
  absencesOverview: [],
  absenceCalendarOverview: {},
  absenceTypes: [],
  absenceReport: [],
  absenceSettings: {},
  allEmployeesAbsenceTypes: {
    absenceTypes: [],
    otherTypes: []
  },
  yearsWithAbsences: [],
  statistics: { days: 0, hours: 0 },
  currentEmployeeId: undefined, // Should be removed in favor of employee-service implementation.
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

export const useAbsenceService = () => {
  const getAbsenceTypesByEmployeeId = async employeeId => {
    const fromMyPage = true
    const url = `/hr/api/absence/getabsencetypes/v2/${employeeId}/${fromMyPage}`

    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.absenceTypes = result.data
  }

  const approveAbsence = async payload => {
    const url = `/hr/api/absence/approveAbsenceToConfirmation`

    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      payload
    )

    if (result.success) {
      replaceItem(
        state.absencesOverview.find(
          employeeAbsences =>
            employeeAbsences.employee.employeeId ===
            result.data.absenceOverviewDto.employeeId
        ).absences,
        absence => absence.id === payload.id,
        { ...result.data.absenceOverviewDto, newItem: true }
      )
    }
    return result
  }

  const getAbsenceStatistics = async (employeeId, year) => {
    const url = `/hr/api/absence/getDaysHoursCount/${employeeId}/${year}`

    const result = await requestHandler(httpMethods.GET, url)
    state.statistics = result.data
  }

  const removeAbsenceById = async (
    id,
    message,
    employeeId = state.currentEmployeeId,
    from_my_page = true
  ) => {
    const url = `/hr/api/absence/removeAbsenceToConfirmation/${from_my_page}`
    const result = await requestHandler(httpMethods.POST, url, {
      id,
      employee_id: employeeId,
      message
    })

    if (result.success && from_my_page) {
      const index = state.absences.findIndex(item => item.id === id)
      if (index > -1) {
        state.absences.splice(index, 1)
      }
    } else if (result.success && !from_my_page) {
      const employeeAbsences = state.absencesOverview.find(
        employeeAbsences => employeeAbsences.employee.employeeId === employeeId
      )
      const index = employeeAbsences.absences.findIndex(item => item.id === id)
      if (index > -1) {
        employeeAbsences.absences.splice(index, 1)
      }
    }
    return result
  }

  const getMyAbsencesByEmployeeIdAndYear = async (
    employeeId,
    year,
    refresh = false
  ) => {
    const url = `/hr/api/absence/getMyAbsences/${employeeId}/${year}`

    const result = refresh
      ? await requestHandler(httpMethods.GET, url)
      : await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.absences = result.data

    await getAbsenceStatistics(employeeId, year)
  }

  const selectYear = year => (state.selectedYear = year)

  const createAbsence = async data => {
    const url = '/hr/api/absence/saveAbsenceToConfirmation'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      data
    )
    if (result.success && result.hasContent)
      addNewItem(result.data.absenceOverviewDto, state.absences)

    return result
  }

  const getAbsenceType = async id => {
    const url = `/hr/api/absence/getabsencetype/${id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    return result
  }
  const getAllAbsenceTypes = async () => {
    const url = '/hr/api/absence/getAbsenceTypesList/v2'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.allEmployeesAbsenceTypes = result.data
  }
  const getAllAbsenceTypesById = async (employeeId, fromMyPage = true) => {
    const url = `hr/api/absence/getabsencetypes/v2/${employeeId}/${fromMyPage}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.allEmployeesAbsenceTypes = result.data
    return result.data
  }
  const getAbsenceYearsByEmployeeId = async employeeId => {
    const url = `hr/api/absence/findAbsenceYearsByEmployeeId/${employeeId}`
    const result = await requestHandler(httpMethods.GET, url)

    return result.success && hasItems(result.data)
      ? result.data.sort().reverse()
      : [new Date().getFullYear()]
  }

  const getYearsWithAbsences = async () => {
    const url = '/hr/api/absence/findAbsenceYears'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.yearsWithAbsences = result.data.reverse()
  }

  const getAbsencesOverview = async (from, to) => {
    const url = `/hr/api/absence/absenceOverview/${from}/${to}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) {
      state.absencesOverview = result.data
      state.absencesOverview.forEach(employeeAbsences =>
        employeeAbsences.absences.sort((a, b) =>
          a.confirmed === b.confirmed
            ? a.from - b.from
            : a.confirmed - b.confirmed
        )
      )
    }
  }

  const getAbsenceCalendarOverview = async (
    from,
    to,
    showVacations,
    accessContext = 'ABSENCE',
    showAllEmployees = true
  ) => {
    let elementTypes = [
      'LEAVE',
      'HOLIDAYS',
      'LEAVE_TO_BE_CONFIRMED',
      'HOLIDAYS'
    ]
    if (showVacations) elementTypes.push('VACATION')
    const url = `/hr/api/planningoverview/getOverviewData/v2`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      from,
      to,
      elementTypes,
      accessContext,
      showAllEmployees
    })
    if (result.success) state.absenceCalendarOverview = result.data
  }

  const getAbsenceById = async absenceId => {
    const url = `/hr/api/absence/getabsence/${absenceId}`
    const result = await requestHandler(httpMethods.GET, url)
    if (result.success) return result.data
  }

  const deleteAbsence = async absenceToDelete => {
    const url = `/hr/api/absence/removeabsence/${absenceToDelete.id}`
    const result = await requestHandler(httpMethods.POST, url)
    if (result.success) {
      const employeeAbsences = state.absencesOverview.find(
        employeeAbsence =>
          employeeAbsence.employee.employeeId === absenceToDelete.employeeId
      )
      const index = employeeAbsences.absences.findIndex(
        item => item.id === absenceToDelete.id
      )
      if (index > -1) {
        employeeAbsences.absences.splice(index, 1)
      }
      return result
    }
  }

  const getAbsenceReport = async (
    range,
    absenceTypes,
    departmentIds,
    employeePositions,
    deductVacationHours
  ) => {
    const url = `/hr/api/absenceReport/report`
    const result = await requestHandlerWithState(state, httpMethods.POST, url, {
      fromDate: range[0],
      toDate: range[1],
      absenceTypes,
      departmentIds,
      employeePositions,
      deductVacationHours
    })

    if (result.success) state.absenceReport = result.data

    return result
  }

  const getExcelReport = async (
    range,
    absenceTypes,
    departmentIds,
    employeePositions,
    deductVacationHours,
    reportType
  ) => {
    const url = `/hr/api/absenceReport/exportReport`
    const config = {
      method: 'POST',
      data: {
        fromDate: range[0],
        toDate: range[1],
        absenceTypes,
        departmentIds,
        employeePositions,
        deductVacationHours,
        reportType
      },
      url: url,
      responseType: 'arraybuffer'
    }
    const result = await avonovaAPI(config)
    return result
  }

  const saveAbsence = async Dto => {
    const url = `/hr/api/absence/saveAbsence`
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      Dto
    )

    if (result.success) {
      const employeeId = Dto.employeeId
      const employeeAbsenceArray = state.absencesOverview.find(
        employeeAbsence => employeeAbsence.employee.employeeId === employeeId
      ).absences
      if (employeeAbsenceArray) {
        if (Dto.id) {
          const indexInArray = employeeAbsenceArray.findIndex(
            absence => absence.id === Dto.id
          )
          replaceNewItem(
            result.data.absenceOverviewDto,
            employeeAbsenceArray,
            indexInArray
          )
        } else addNewItem(result.data.absenceOverviewDto, employeeAbsenceArray)
      }
    }

    return result
  }

  const getAbsenceSettings = async () => {
    const url = `/hr/api/absence/settings`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    if (result.success) state.absenceSettings = result.data
    return result
  }

  return {
    state: toRefs(state),
    getMyAbsencesByEmployeeIdAndYear,
    selectYear,
    createAbsence,
    getAbsenceType,
    getAbsenceTypesByEmployeeId,
    removeAbsenceById,
    getAbsenceYearsByEmployeeId,
    getAllAbsenceTypes,
    getAllAbsenceTypesById,
    getYearsWithAbsences,
    getAbsencesOverview,
    getAbsenceCalendarOverview,
    getAbsenceById,
    approveAbsence,
    deleteAbsence,
    getAbsenceReport,
    getExcelReport,
    saveAbsence,
    getAbsenceSettings
  }
}
