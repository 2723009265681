<template>
  <ul class="menu-item-list-languages">
    <li
      v-for="lang in supportedLanguages"
      :key="lang.isoCode"
      class="menu-item"
      @click.prevent="$emit('click', lang.lang)"
    >
      <a href="#" class="menu-item-link">
        <span class="menu-item-image-icon">
          <img :src="lang.iconSrc" :alt="$t(lang.title)" />
        </span>
        <span class="menu-item-text">
          {{ $t(lang.title) }}
        </span>
      </a>
    </li>
  </ul>
</template>
<script>
import { computed } from 'vue'

import store from '@/store'

export default {
  name: 'MenuItemListLanguages',
  setup() {
    const languageKeys = {
      'sv-SE': 'menu__item__swedish',
      'fi-FI': 'menu__item__finnish',
      'pl-PL': 'menu__item__polish',
      'en-GB': 'menu__item__english',
      'nb-NO': 'menu__item__norwegian'
    }

    const supportedLanguages = computed(() =>
      store.getters['authUser/accountLocales'].map(lang => ({
        lang,
        title: languageKeys[lang],
        iconSrc: `/app/img/icons/locale-flags/${lang}.png`
      }))
    )

    return { supportedLanguages }
  }
}
</script>

<style lang="scss" scoped>
.menu-item-list-languages {
  padding: 0;
  margin: 0;
  background: white;
  list-style: none !important;

  li {
    width: 100%;
    padding: 0 1rem;
  }

  li:hover {
    background: #f0f4f2;
  }

  a {
    padding: 0.375rem 0.75rem 0.375rem 0.3125rem;
    color: #333;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:focus {
      padding: 0.375rem 0.75rem 0.375rem 0.3125rem !important;
      margin: 0 !important;
    }
  }

  .menu-item-icon {
    font-size: 1.2em;
    text-align: center;
    display: block;
    width: 1.875rem;
    margin-right: 0.1875rem;
    margin-top: 0.125rem;
  }
  .menu-item-image-icon img {
    height: 1.25rem;
    margin: 0 0.429em auto auto;
    display: block;
  }
}
</style>
