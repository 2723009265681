import { createIntlLocale } from '@/config/i18n'
import { localize, mapISOToLegacyLanguage } from '@/config/i18n/localization'

import { useUserService } from '@/services/user-service'

import { ACCOUNT_COUNTRY } from '@/constants/i18n'

export default {
  name: 'authUser',
  namespaced: true,
  state: {
    userId: null,
    employeeId: null,
    userType: null,
    locale: null,
    accountCountry: null,
    username: null,
    accountLocales: null,
    intlLocale: null,
    productPackage: null
  },
  mutations: {
    SET_USER_ID(state, userId) {
      state.userId = userId
    },
    SET_EMPLOYEE_ID(state, employeeId) {
      state.employeeId = employeeId
    },
    SET_USER_TYPE(state, userType) {
      state.userType = userType
    },
    SET_USER_LOCALE(state, locale) {
      state.locale = locale
    },
    SET_ACCOUNT_COUNTRY(state, accountCountry) {
      state.accountCountry = accountCountry
    },
    SET_USERNAME(state, username) {
      state.username = username
    },
    SET_SUPPORTED_LOCALES(state, accountLocales) {
      state.accountLocales = accountLocales
    },
    SET_INTL_LOCALE(state, intlLocale) {
      state.intlLocale = intlLocale
    },
    SET_PRODUCT_PACKAGE(state, productPackage) {
      state.productPackage = productPackage
    }
  },
  actions: {
    updateAuthUser({ commit, getters }, authUser) {
      const {
        user_id,
        employee_id,
        userType,
        userLocale,
        accountCountry,
        loggedinusername,
        accountLocales
      } = authUser
      commit('SET_USER_ID', user_id)
      commit('SET_EMPLOYEE_ID', employee_id)
      commit('SET_USER_TYPE', userType)
      commit('SET_ACCOUNT_COUNTRY', accountCountry)
      commit('SET_USER_LOCALE', userLocale)
      commit('SET_USERNAME', loggedinusername)
      commit('SET_SUPPORTED_LOCALES', accountLocales)

      // Getting correct region based on locale, since similar data is deprecated from API
      commit('SET_INTL_LOCALE', createIntlLocale(userLocale))

      // Localize the app
      // userlocale: nb-NO, accountCountry: NO, language: en, sv, nb
      localize(userLocale, accountCountry, getters.language)
    },
    updateLanguage({ getters, commit }, locale) {
      const userService = useUserService()
      userService.updateUserLocale(locale)
      commit('SET_USER_LOCALE', locale)
      commit('SET_INTL_LOCALE', createIntlLocale(locale))
      localize(locale, getters.accountCountry, getters.language)
    },
    updateProductPackage({ getters, commit }, productPackage) {
      // NB: This is a temporary solution for Finland, as the API does not return the correct product package for Finland
      if (getters.accountCountry === ACCOUNT_COUNTRY.FINLAND)
        productPackage.products = ['HR-HSE', 'CHEMICAL-INVENTORY']

      commit('SET_PRODUCT_PACKAGE', productPackage)
    }
  },
  getters: {
    userId: state => state.userId,
    employeeId: state => state.employeeId,
    userType: state => state.userType,
    locale: state => state.locale,
    accountCountry: state => state.accountCountry,
    username: state => state.username,
    accountLocales: state => state.accountLocales,
    language: state => state.intlLocale.language,
    productPackage: state => state.productPackage || [],
    //DO NOT USE THIS GETTER UNLESS YOU ARE SURE YOU REALLY NEED IT. USE "locale" INSTEAD
    localeLegacy: state => mapISOToLegacyLanguage(state.locale)
    /////////////////////////////////////////////////////////////////////////////////////
  }
}
